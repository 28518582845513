/* eslint-disable react/jsx-key */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable no-alert */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
/* eslint-disable no-use-before-define */
// @mui material components
import React from "react";
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import * as XLSX from "xlsx";
// Material Dashboard 2 React components

// @mui material components
import { ToastContainer, toast } from "react-toastify";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import { DropzoneArea } from "react-mui-dropzone";
import "@silevis/reactgrid/styles.css";
import axios from "axios";

// import { AttachFile, AudioTrack, Description, PictureAsPdf, Theaters } from '@material-ui/icons';

import { DataGrid } from "@mui/x-data-grid";

// Authentication layout components

// I18N
import { useTranslation } from "react-i18next";
import moment from "moment";

import authHeader from "../../helpers/auth-headers";
import authService from "../../helpers/auth.service";
import config from "../../config/config.json";
import SysDashLayout from "../../components_reefer/Layouts/SysDashLayout";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";

import MDTypography from "../../components/MDTypography";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <MDBox sx={{ p: 3 }}>
          <MDTypography>{children}</MDTypography>
        </MDBox>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SystemMovimentation() {
  const { t } = useTranslation("common");
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { API_URL } = config;

  const columns = [
    { field: "ctnr", headerName: t("table_mov.col_ctnr"), width: 180, editable: true },
    {
      field: "temperature",
      headerName: t("table_mov.col_setpoint"),
      type: "number",
      editable: true,
    },
    {
      field: "client",
      headerName: t("table_mov.col_client"),
      width: 180,
      editable: true,
    },
    {
      field: "carr",
      headerName: t("table_mov.col_carrier"),
      width: 180,
      editable: true,
    },
    {
      field: "pos",
      headerName: t("table_mov.col_pos"),
      width: 180,
      editable: true,
    },
  ];

  // console.log(authService.getCurrentUser())
  // const user = authService.getCurrentUser();
  const [patio, setPatio] = React.useState(-1);
  const [porto, setPorto] = React.useState(-1);
  const [ship, setShip] = React.useState(-1);
  const [patioList, setPatioList] = React.useState([]);
  const [portoList, setPortoList] = React.useState([]);
  const [shipList, setShipList] = React.useState([]);
  const [travel, setTravel] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [selHeader, setSelHeader] = React.useState([]);
  const [selHeaderIndex, setSelHeaderIndex] = React.useState(-1);
  const [fileHeader, setFileHeader] = React.useState({
    data: [],
    dataIndex: -1,
    ctnr: -1,
    setpoint: -1,
    lc: -1,
    cb: -1,
    dry: -1,
    consig: -1,
    pos: -1,
  });
  const [file, setFile] = React.useState("");
  // eslint-disable-next-line new-cap
  const [travelDate, setTravelDate] = React.useState(new moment(Date.now()));
  const [state, setState] = React.useState({
    cb: false,
    lc: false,
    dry: false,
  });
  const [preview, setPreview] = React.useState(false);
  const { cb, lc, dry } = state;
  const error = [cb, lc, dry].filter((v) => v).length === 0;
  const user = authService.getCurrentUser();

  const handleFile = (f) => {
    console.log(f);
    if (f.length > 0) {
      setPreview(true);
      let workBook = null;
      let jsonData = null;
      const reader = new FileReader();
      reader.onload = () => {
        const data = reader.result;
        workBook = XLSX.read(data, { type: "binary" });
        jsonData = workBook.SheetNames.reduce((initial, name) => {
          const sheet = workBook.Sheets[name];
          initial[name] = XLSX.utils.sheet_to_json(sheet);
          return XLSX.utils.sheet_to_json(sheet, { header: 1 });
        }, {});
        let foundIndex = 0;
        while (
          jsonData.length > foundIndex &&
          (!jsonData[foundIndex] || !jsonData[foundIndex].some((el) => el === "SET POINT"))
        ) {
          foundIndex += 1;
        }
        // foundIndex = jsonData.length+10
        if (foundIndex < jsonData.length) {
          setFileHeader({
            data: jsonData[foundIndex],
            dataIndex: foundIndex,
            ctnr:
              jsonData[foundIndex].indexOf("CONT.\nNRO") >= 0
                ? jsonData[foundIndex].indexOf("CONT.\nNRO")
                : jsonData[foundIndex].indexOf("CONT.NRO"),
            setpoint: jsonData[foundIndex].indexOf("SET POINT"),
            lc:
              jsonData[foundIndex].indexOf("LC/\nCB") >= 0
                ? jsonData[foundIndex].indexOf("LC/\nCB")
                : jsonData[foundIndex].indexOf("LC/CB"),
            cb:
              jsonData[foundIndex].indexOf("LC/\nCB") >= 0
                ? jsonData[foundIndex].indexOf("LC/\nCB")
                : jsonData[foundIndex].indexOf("LC/CB"),
            dry: jsonData[foundIndex].indexOf("OBS"),
            consig: jsonData[foundIndex].indexOf("CONSIG"),
            carr: jsonData[foundIndex].indexOf("CARRIER"),
            pos: jsonData[foundIndex].indexOf("POS"),
          });
          setSelHeaderIndex(foundIndex);
          setFile(jsonData.slice(foundIndex + 1));
        } else {
          setFileHeader({
            data: [],
            dataIndex: -1,
            ctnr: -1,
            setpoint: -1,
            lc: -1,
            cb: -1,
            dry: -1,
            consig: -1,
            carr: -1,
            pos: -1,
          });
          setFile(jsonData);
        }
        const tmpHeader = [];
        for (let index = 0; index < 20; index += 1) {
          tmpHeader.push({
            header: jsonData[index].join(" - "),
            index,
            data: jsonData[index],
          });
        }
        // console.log(tmpHeader);
        setSelHeader(tmpHeader);
      };
      reader.readAsBinaryString(f[0]);
    } else {
      setFileHeader({
        data: [],
        dataIndex: -1,
        ctnr: -1,
        setpoint: -1,
        lc: -1,
        cb: -1,
        dry: -1,
        consig: -1,
        carr: -1,
        pos: -1,
      });
      setFile([]);
    }
  };

  const handleCustomHeader = (ind, val) => {
    const tmpHeader = JSON.parse(JSON.stringify(fileHeader));
    tmpHeader[ind] = val;
    setFileHeader(tmpHeader);
  };

  const handleSave = async () => {
    console.log("lenghts", rows.length, travel.length);
    if (rows.length > 0 && travel.length > 1 && ship > 0 && patio > 0) {
      const containers = [];
      rows.forEach((row) => {
        if (row.ctnr.length > 0 && row.temperature !== "") {
          containers.push({
            containersLabel: row.ctnr,
            temperature: row.temperature,
            consig: row.client,
            carrier: row.carr,
            position: row.pos,
          });
        }
      });
      if (containers.length > 0) {
        console.log("entrou no containers.lenght");
        const q = {
          naviosIdnavios: ship,
          patiosIdpatios: patio,
          travel,
          isArrive: true,
          date: travelDate.format("YYYY-MM-DD"), // .getDate() + "/" + (travelDate.getMonth() + 1) + "/" + travelDate.getFullYear(),
          data: containers,
        };
        // console.log(q);
        const r = await axios.post(`${API_URL}movimentacoes`, q, { headers: authHeader() });
        console.log(r);
        if (r.data === "ok") {
          setPreview(false);

          setTravel("");
          setShip("");
          setPatio("");
          setSelHeader([]);
          setSelHeaderIndex(-1);
          setFileHeader({
            data: [],
            dataIndex: -1,
            ctnr: -1,
            setpoint: -1,
            lc: -1,
            cb: -1,
            dry: -1,
            consig: -1,
            pos: -1,
          });
          setFile("");
          setTravelDate(new moment(Date.now()));
          setState({
            cb: false,
            lc: false,
            dry: false,
          });
          setRows([{ id: -1, ctnr: "", temperature: "", client: "", pos: "", carr: "" }]);
          toast.success("Salvo com sucesso!");
        } else {
          alert(JSON.stringify(r.data.message, null, 2));
        }
      } else {
        alert(t("alerts.none_container"));
      }
    } else {
      toast.error(t("alerts.fill_fields"));
    }
  };

  const handleManualHeader = (value) => {
    const tmpHeader = file;
    setFileHeader({
      data: tmpHeader[value.target.value],
      dataIndex: value.target.value,
      ctnr:
        tmpHeader[value.target.value].indexOf("CONT.\nNRO") >= 0
          ? tmpHeader[value.target.value].indexOf("CONT.\nNRO")
          : tmpHeader[value.target.value].indexOf("CONT.NRO"),
      setpoint: tmpHeader[value.target.value].indexOf("SET POINT"),
      lc:
        tmpHeader[value.target.value].indexOf("LC//nCB") >= 0
          ? tmpHeader[value.target.value].indexOf("LC//nCB")
          : tmpHeader[value.target.value].indexOf("LC/nCB"),
      cb:
        tmpHeader[value.target.value].indexOf("LC//nCB") >= 0
          ? tmpHeader[value.target.value].indexOf("LC//nCB")
          : tmpHeader[value.target.value].indexOf("LC/nCB"),
      dry: tmpHeader[value.target.value].indexOf("OBS"),
      consig: tmpHeader[value.target.value].indexOf("CONSIG"),
      carr: tmpHeader[value.target.value].indexOf("CARRIER"),
      pos: tmpHeader[value.target.value].indexOf("POSICAO"),
    });
    setSelHeaderIndex(value.target.value);
    setFile(tmpHeader.slice(value.target.value + 1));
  };

  React.useEffect(() => {
    setRows([{ id: -1, ctnr: "", temperature: "", client: "", pos: "" }]);
    if (file.length > 0 && fileHeader.data?.length > 0 && (cb || lc || dry)) {
      file.forEach((el, index) => {
        if (
          el.length > 10 &&
          ((cb === true &&
            fileHeader.cb >= 0 &&
            el[fileHeader.cb].toUpperCase().includes("CB") &&
            !el[fileHeader.dry]?.toUpperCase().includes("DRY")) ||
            (lc === true &&
              fileHeader.lc >= 0 &&
              el[fileHeader.lc].toUpperCase().includes("LC") &&
              !el[fileHeader.dry]?.toUpperCase().includes("DRY")) ||
            (dry === true &&
              fileHeader.dry >= 0 &&
              el[fileHeader.dry]?.toUpperCase().includes("DRY")))
        ) {
          const row = {
            id: index,
            ctnr: el[fileHeader.ctnr],
            temperature:
              el[fileHeader.setpoint] != null ? el[fileHeader.setpoint].split(":")[0] : "",
            client: el[fileHeader.consig],
            pos: fileHeader.pos >= 0 ? el[fileHeader.pos] : "",
            carr: fileHeader.carr >= 0 ? el[fileHeader.carr] : "",
          };
          setRows((prevState) => [...prevState, row]);
        }
      });
    }
  }, [file, fileHeader, cb, lc, dry]);

  const handleCheckChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      { id: rows.length, ctnr: "", temperature: "", client: "", pos: "", carr: "" },
    ]);
  };

  const handleUpdateRow = (e, index, valIndex) => {
    const tmpRows = rows;
    tmpRows[index][valIndex] = e.target.value;
    setRows([...tmpRows]);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      if (portoList.length === 0) {
        axios
          .get(`${API_URL}portos/${user.accountsIdaccounts}`, {}, { headers: authHeader() })
          .then(
            (response) => {
              // console.log(response.data);
              setPortoList(response.data);
              if (response.data.length === 1) {
                setPorto(response.data[0]);
                // console.log(porto);
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }
      if (shipList.length === 0) {
        axios.get(`${API_URL}navios/${user.accountsIdaccounts}`, { headers: authHeader() }).then(
          (response) => {
            setShipList(response.data.filter((item) => item.isActive === 1));
          },
          (error) => {
            console.log(error);
          }
        );
      }
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      if (porto?.idportos) {
        // console.log(porto);
        axios.get(`${API_URL}patios/${porto.idportos}`, { headers: authHeader() }).then(
          (response) => {
            setPatioList(response.data);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    };
    fetchData();
  }, [porto]);

  React.useEffect(() => {
    console.log(rows);
  }, [rows]);

  const handleChangePorto = (event) => {
    // console.log(event.target)
    setPorto({ idportos: event.target.value, name: event.target.name });
  };

  const handleChangePatio = (event) => {
    setPatio(event.target.value);
  };

  const handleChangeTravel = (event) => {
    console.log("TRAVEL", event.target.value);
    setTravel((prevTravel) => {
      console.log("Prev travel:", prevTravel);
      return event.target.value;
    });
  };

  const handleChangeShip = (event) => {
    setShip(event.target.value);
  };

  const handleChangeTravelDate = (newValue) => {
    // console.log(newValue)
    setTravelDate(newValue);
  };

  return (
    <SysDashLayout>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {t("menu.trv")}
          </MDTypography>
        </MDBox>
        <ToastContainer />
        <MDBox pt={4} pb={3} px={2}>
          <Grid container spacing={1} alignItems="flex-end">
            {portoList.length > 1 ? (
              <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="label-porto">{t("operational.harbor_select")}</InputLabel>
                  <Select
                    labelId="label-porto"
                    id="select-porto"
                    // value={porto.idportos}
                    onChange={handleChangePorto}
                    style={{ height: "3rem" }}
                  >
                    {portoList.map((p) => (
                      <MenuItem key={p.idportos} value={p.idportos}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}
            <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="label-patio">{t("operational.dock_select")}</InputLabel>
                <Select
                  labelId="label-patio"
                  id="select-patio"
                  value={patio}
                  onChange={handleChangePatio}
                  style={{ height: "3rem" }}
                  fullWidth
                >
                  {patioList.map((p) => (
                    <MenuItem key={p.idpatios} value={p.idpatios}>
                      {p.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="label-ship">{t("operational.ship_select")}</InputLabel>
                <Select
                  labelId="label-ship"
                  id="select-ship"
                  value={ship}
                  onChange={handleChangeShip}
                  style={{ height: "3rem" }}
                  fullWidth
                >
                  {shipList.map((p) => (
                    <MenuItem key={p.idnavios} value={p.idnavios}>
                      {p.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
              <TextField
                id="outlined-basic"
                onChange={handleChangeTravel}
                value={travel}
                style={{ height: "3rem" }}
                label={t("adjusts.tab_travel")}
                variant="standard"
                fullWidth
              />
            </Grid>
            <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
              <DesktopDatePicker
                label={t("operational.retro_date")}
                inputFormat="DD/MM/yyyy"
                variant="standard"
                style={{ height: "3rem" }}
                value={travelDate}
                onChange={handleChangeTravelDate}
                renderInput={(params) => <TextField variant="standard" {...params} />}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3} sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label={t("movments.tab_file")} {...a11yProps(0)} />
            <Tab label={t("movments.tab_manual")} {...a11yProps(1)} />
            {/* <Tab label={t('movments.tab_return')} {...a11yProps(2)} /> */}
          </Tabs>
        </MDBox>
        <TabPanel value={value} index={0}>
          <DropzoneArea
            maxFileSize={50000000}
            filesLimit={1}
            showPreviews={preview ? true : false}
            dropzoneText={t("movments.dropzone_text")}
            previewText={t("movments.preview_text")}
            showPreviewsInDropzone={false}
            showFileNamesInPreview
            useChipsForPreview
            onChange={handleFile}
          />
          <MDBox pt={4} pb={3} px={3} sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                <FormControl
                  required
                  error={error}
                  component="fieldset"
                  sx={{ m: 3 }}
                  variant="standard"
                >
                  <FormLabel component="legend">{t("table_mov.sel_one")}</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={cb} onChange={handleCheckChange} name="cb" />}
                      label="CB"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={lc} onChange={handleCheckChange} name="lc" />}
                      label="LC"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={dry} onChange={handleCheckChange} name="dry" />}
                      label="DRY"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={11} sm={11} md={8} lg={8} xl={8}>
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item xs={11} sm={11} md={11} lg={11} xl={11}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="label-header">{t("movments.select_header")}</InputLabel>
                      <Select
                        labelId="label-header"
                        id="select-header"
                        value={parseInt(selHeaderIndex, 10)}
                        onChange={handleManualHeader}
                        style={{ height: "3rem" }}
                      >
                        {selHeader.map((p) => (
                          <MenuItem key={p.index} value={p.index}>
                            {p.header}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="label-ctnr">{t("table_mov.col_ctnr")}</InputLabel>
                      <Select
                        labelId="label-ctnr"
                        id="select-ctnr"
                        value={parseInt(fileHeader.ctnr, 10)}
                        onChange={(value) => {
                          handleCustomHeader("ctnr", value.target.value);
                        }}
                        style={{ height: "3rem" }}
                        fullWidth
                      >
                        <MenuItem value={-1} key={-1} />
                        {fileHeader.data?.map((p, i) => (
                          <MenuItem key={i} value={i}>
                            {p}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="label-setpoint">{t("table_mov.col_setpoint")}</InputLabel>
                      <Select
                        labelId="label-setpoint"
                        id="select-setpoint"
                        // onChange={handleChangePatio}
                        onChange={(value) => {
                          handleCustomHeader("setpoint", value.target.value);
                        }}
                        value={parseInt(fileHeader.setpoint, 10)}
                        style={{ height: "3rem" }}
                        fullWidth
                      >
                        <MenuItem value={-1} key={-1} />
                        {fileHeader.data?.map((p, i) => (
                          <MenuItem key={i} value={i}>
                            {p}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="label-lc">LC</InputLabel>
                      <Select
                        labelId="label-lc"
                        id="select-lc"
                        // onChange={handleChangePatio}
                        onChange={(value) => {
                          handleCustomHeader("lc", value.target.value);
                        }}
                        value={parseInt(fileHeader.lc, 10)}
                        style={{ height: "3rem" }}
                        fullWidth
                      >
                        <MenuItem value={-1} key={-1} />
                        {fileHeader.data?.map((p, i) => (
                          <MenuItem key={i} value={i}>
                            {p}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="label-cb">CB</InputLabel>
                      <Select
                        labelId="label-cb"
                        id="select-cb"
                        onChange={(value) => {
                          handleCustomHeader("cb", value.target.value);
                        }}
                        value={parseInt(fileHeader.cb, 10)}
                        // onChange={handleChangePatio}
                        style={{ height: "3rem" }}
                        fullWidth
                      >
                        <MenuItem value={-1} key={-1} />
                        {fileHeader.data?.map((p, i) => (
                          <MenuItem key={i} value={i}>
                            {p}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="label-dry">DRY</InputLabel>
                      <Select
                        labelId="label-dry"
                        id="select-dry"
                        value={parseInt(fileHeader.dry, 10)}
                        // onChange={handleChangePatio}
                        onChange={(value) => {
                          handleCustomHeader("dry", value.target.value);
                        }}
                        style={{ height: "3rem" }}
                        fullWidth
                      >
                        <MenuItem value={-1} key={-1} />
                        {fileHeader.data?.map((p, i) => (
                          <MenuItem key={i} value={i}>
                            {p}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="label-client">{t("table_mov.col_client")}</InputLabel>
                      <Select
                        labelId="label-client"
                        id="select-client"
                        value={parseInt(fileHeader.consig, 10)}
                        onChange={(value) => {
                          handleCustomHeader("consig", value.target.value);
                        }}
                        // onChange={handleChangePatio}
                        style={{ height: "3rem" }}
                        fullWidth
                      >
                        <MenuItem value={-1} key={-1} />
                        {fileHeader.data?.map((p, i) => (
                          <MenuItem key={i} value={i}>
                            {p}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="label-pos">{t("table_mov.col_carrier")}</InputLabel>
                      <Select
                        labelId="label-pos"
                        id="select-pos"
                        value={parseInt(fileHeader.carr, 10)}
                        onChange={(value) => {
                          handleCustomHeader("carr", value.target.value);
                        }}
                        // onChange={handleChangePatio}
                        style={{ height: "3rem" }}
                        fullWidth
                      >
                        <MenuItem value={-1} key={-1} />
                        {fileHeader.data?.map((p, i) => (
                          <MenuItem key={i} value={i}>
                            {p}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel id="label-pos">{t("table_mov.col_pos")}</InputLabel>
                      <Select
                        labelId="label-pos"
                        id="select-pos"
                        value={parseInt(fileHeader.pos, 10)}
                        onChange={(value) => {
                          handleCustomHeader("pos", value.target.value);
                        }}
                        // onChange={handleChangePatio}
                        style={{ height: "3rem" }}
                        fullWidth
                      >
                        <MenuItem value={-1} key={-1} />
                        {fileHeader.data?.map((p, i) => (
                          <MenuItem key={i} value={i}>
                            {p}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </MDBox>
          {preview && (
            <MDBox
              pt={4}
              pb={3}
              px={3}
              sx={{ borderBottom: 1, borderColor: "divider", height: 400, width: "100%" }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={100}
                rowsPerPageOptions={[15]}
                disableSelectionOnClick
              />
            </MDBox>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={3} rowSpacing={4} width="100%">
            {rows.map((row, i) => (
              <Grid item xs={11}>
                <Grid container spacing={3} rowSpacing={4}>
                  <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                    <TextField
                      value={row.ctnr}
                      style={{ height: "3rem" }}
                      label={t("table_mov.col_ctnr")}
                      variant="standard"
                      fullWidth
                      onChange={(e) => handleUpdateRow(e, i, "ctnr")}
                    />
                  </Grid>
                  <Grid item xs={11} sm={11} md={1} lg={1} xl={1}>
                    <TextField
                      value={row.temperature}
                      style={{ height: "3rem" }}
                      label={t("table_mov.col_setpoint")}
                      variant="standard"
                      fullWidth
                      onChange={(e) => handleUpdateRow(e, i, "temperature")}
                    />
                  </Grid>
                  <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                    <TextField
                      value={row.client}
                      style={{ height: "3rem" }}
                      label={t("table_mov.col_client")}
                      variant="standard"
                      fullWidth
                      onChange={(e) => handleUpdateRow(e, i, "client")}
                    />
                  </Grid>
                  <Grid item xs={11} sm={11} md={2} lg={2} xl={2}>
                    <TextField
                      value={row.pos}
                      style={{ height: "3rem" }}
                      label={t("table_mov.col_pos")}
                      variant="standard"
                      fullWidth
                      onChange={(e) => handleUpdateRow(e, i, "pos")}
                    />
                  </Grid>
                  <Grid item xs={11} sm={11} md={1} lg={1} xl={1}>
                    <TextField
                      value={row.carr}
                      style={{ height: "3rem" }}
                      label={t("table_mov.col_carrier")}
                      variant="standard"
                      fullWidth
                      onChange={(e) => handleUpdateRow(e, i, "carr")}
                    />
                  </Grid>
                  <Grid item xs={11} sm={11} md={1} lg={1} xl={1}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      m={4}
                      onClick={() => {
                        // console.log(rows, id);
                        if (rows.length === 1) return;
                        setRows(rows.filter((item) => item.id !== row.id));
                      }}
                    >
                      remove
                    </MDButton>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <MDButton variant="gradient" color="info" mt={4} onClick={handleAddRow}>
            Add
          </MDButton>
          {/* <ReactGrid rows={Rows} columns={Columns} /> */}
          {/* <HotTable data={Rows} colHeaders={true} rowHeaders={true} width="100%" height="300" licenseKey='non-commercial-and-evaluation'/> */}
          {/* <HotTable data={rows} colHeaders={columns} rowHeaders={true} width="600" height="300" /> */}
        </TabPanel>
        <MDBox
          pt={4}
          pb={3}
          px={3}
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <MDButton variant="gradient" color="info" m={1} onClick={handleSave}>
            {t("common.save")}
          </MDButton>
        </MDBox>
        {/* <TabPanel value={value} index={2}>
          Item Three
        </TabPanel> */}
      </Card>
    </SysDashLayout>
  );
}

export default SystemMovimentation;
