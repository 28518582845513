import Card from "@mui/material/Card";
import { useState } from "react";
import { Link, createSearchParams, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";
import bgImage from "../../../../assets/images/fundo001.jpg";
import BasicLayout from "../../../../components_reefer/Layouts/BasicLayout";
import AuthService from "../../../../helpers/auth.service";

function Cover() {
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const onAuth = () => {
    navigate({
      pathname: "/authentication/new-password",
      search: createSearchParams({ username }).toString(),
    });
  };
  const onClickReset = () => {
    AuthService.resetPassword(username, onAuth);
  };
  const { t } = useTranslation("common");

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            {t("auth.title_reset")}
            {/* Reset Password */}
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            {t("auth.msg_reset")}
            {/* You will receive an e-mail in maximum 60 seconds */}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onClickReset();
                  }
                }}
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={onClickReset}>
                {t("auth.button_reset")}
              </MDButton>
            </MDBox>
            <MDBox mt={1} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                component={Link}
                to="/authentication/sign-in"
              >
                {t("auth.button_cancel")}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
