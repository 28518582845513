import * as React from "react";
// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components

// I18N
import { useTranslation } from "react-i18next";

import axios from "axios";
import MDTypography from "../../../components/MDTypography";
import authHeader from "../../../helpers/auth-headers";
import config from "../../../config/config.json";
import MDButton from "../../../components/MDButton";

export default function data(openModalCallBack) {
  const { API_URL } = config;
  // const response = await axios.get(API_URL+"users", { headers: authHeader() })
  // console.log(response.data)
  const { t } = useTranslation("common");
  const [usrList, setUsrList] = React.useState([]);

  const fetchData = async (force) => {
    if (usrList.length > 0 && !force) return;
    const response = await axios.get(`${API_URL}accounts`, { headers: authHeader() });
    // console.log(response.data);
    setUsrList(
      response.data.map((p) => ({
        name: (
          <MDTypography fontWeight="medium" fontSize="small">
            {p.name}
          </MDTypography>
        ),
        active: (
          <MDTypography>
            <Icon>{p.isActive ? "check" : "clear"}</Icon>
          </MDTypography>
        ),
        alert: (
          <MDTypography>
            <Icon>{p.isAlert ? "check" : "clear"}</Icon>
          </MDTypography>
        ),
        maint: (
          <MDTypography>
            <Icon>{p.isMaintenance ? "check" : "clear"}</Icon>
          </MDTypography>
        ),
        admin: (
          <MDTypography>
            <Icon>{p.isSysAdmin ? "check" : "clear"}</Icon>
          </MDTypography>
        ),
        report: (
          <MDTypography>
            <Icon>{p.isReport ? "check" : "clear"}</Icon>
          </MDTypography>
        ),
        harbor: (
          <MDTypography>
            <Icon>{p.isHarbor ? "check" : "clear"}</Icon>
          </MDTypography>
        ),
        nick: (
          <MDTypography fontWeight="medium" fontSize="small">
            {p.nickname}
          </MDTypography>
        ),
        cnpj: (
          <MDTypography fontWeight="medium" fontSize="small">
            {p.cnpj}
          </MDTypography>
        ),
        address: (
          <MDTypography fontWeight="medium" fontSize="small">
            {p.address}
          </MDTypography>
        ),
        monitorsAM: (
          <MDTypography fontWeight="medium" fontSize="small">
            {p.monitoringByAM}
          </MDTypography>
        ),
        monitorsPM: (
          <MDTypography fontWeight="medium" fontSize="small">
            {p.monitoringByPM}
          </MDTypography>
        ),
        value: (
          <MDTypography fontWeight="medium" fontSize="small">
            {p.monitoringValue}
          </MDTypography>
        ),
        maintenance: (
          <MDTypography fontWeight="medium" fontSize="small">
            {p.maintenanceValue}
          </MDTypography>
        ),
        actions: (
          <MDButton fontWeight="medium" fontSize="large" onClick={() => openModalCallBack(p)}>
            <Icon>edit</Icon>
          </MDButton>
        ),
      }))
    );
  };
  fetchData();

  return {
    columns: [
      { Header: t("common.actions"), accessor: "actions", align: "center" },
      { Header: t("table_users.col_name"), accessor: "name", align: "left" },
      { Header: t("table_accounts.col_nick"), accessor: "nick", align: "left" },
      { Header: t("table_accounts.col_cnpj"), accessor: "cnpj", align: "center" },
      { Header: t("table_accounts.col_address"), accessor: "address", align: "center" },
      { Header: t("common.active"), accessor: "active", align: "left" },
      { Header: t("common.harbor"), accessor: "harbor", align: "left" },
      { Header: t("table_users.col_sys_admin"), accessor: "admin", align: "left" },
      { Header: t("table_users.col_full_report"), accessor: "report", align: "center" },
      { Header: t("table_users.col_is_alert"), accessor: "alert", align: "center" },
      { Header: t("table_accounts.col_numByAM"), accessor: "monitorsAM", align: "left" },
      { Header: t("table_accounts.col_numByPM"), accessor: "monitorsPM", align: "left" },
      { Header: t("table_accounts.col_value"), accessor: "value", align: "left" },
      { Header: t("table_accounts.col_Maintenance"), accessor: "maintenance", align: "left" },
    ],
    rows: usrList,
    update: fetchData,
  };
}
