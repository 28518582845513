/* eslint-disable react/jsx-props-no-spreading */
// @mui material components
import * as React from "react";
import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
// react-table components

import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

// Data

// @mui material components
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// I18N
import { useTranslation } from "react-i18next";
import axios from "axios";

// Images
import bgImage from "../../assets/images/fundo001.jpg";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";

import SysDashLayout from "../../components_reefer/Layouts/SysDashLayout";
import authService from "../../helpers/auth.service";
import authHeader from "../../helpers/auth-headers";
import config from "../../config/config.json";
import ModalUsers from "../../components_reefer/Modals/ModalUsers";
import ModalDock from "../../components_reefer/Modals/ModalDock";
import ModalAccounts from "../../components_reefer/Modals/ModalAccounts";
import ModalContacts from "../../components_reefer/Modals/ModalContacts";
import ModalHarbor from "../../components_reefer/Modals/ModalHarbor";
import DataTable from "../../components_reefer/Tables/DataTable";
import accountsTableData from "./data/accountsTableData";
import usersTableData from "./data/usersTableData";
import contactsTableData from "./data/contactsTableData";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <MDBox sx={{ p: 3 }}>{children}</MDBox>}
    </div>
  );
}

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SystemAccounts() {
  const { API_URL } = config;
  const user = authService.getCurrentUser();
  const [porto, setPorto] = React.useState("");
  const [patioList, setPatioList] = React.useState([]);
  const [portoList, setPortoList] = React.useState([]);
  const [isModalAddPorto, setIsModalAddPorto] = React.useState(false);
  const [isModalEditPorto, setIsModalEditPorto] = React.useState(false);

  const [currentPage, setCurrentPage] = React.useState(0);

  // =================================================================================================
  // BEGINING OF PATIOS
  // =================================================================================================
  const [isModalEditPatio, setIsModalEditPatio] = React.useState(false);
  const [editPatio, setEditPatio] = React.useState(null);

  const navigate = useNavigate();

  const handleCurrentPage = (newPageIndex) => {
    setCurrentPage(newPageIndex); // Certifique-se de que setCurrentPage é uma função válida
  };

  React.useEffect(() => {
    if (!user.isSysAdmin && !user.isAccountAdmin) {
      navigate("/sysdash");
    }
  }, []);

  const handleOpenPatioModal = (u) => {
    setIsModalEditPatio(true);
    setEditPatio(u);
  };

  const updatePatioList = async () => {
    if (porto?.idportos > 0) {
      axios.get(`${API_URL}patios/${porto.idportos}`, { headers: authHeader() }).then(
        (response) => {
          setPatioList(response.data);
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      setPatioList([]);
    }
  };

  const handleClosePatioModal = async (u) => {
    if (u === null) {
      setIsModalEditPatio(false);
      setEditPatio(null);
      return;
    }
    if (u.id !== null) {
      await axios.put(`${API_URL}patios/${u.id}`, u.data, { headers: authHeader() });
    } else {
      const data = {
        name: u.data.name,
        isActive: u.data.isActive,
        portosIdportos: porto.idportos,
      };
      await axios.post(`${API_URL}patios`, data, { headers: authHeader() });
    }
    updatePatioList();
    setIsModalEditPatio(false);
    setEditPatio(null);
  };
  // =================================================================================================
  // END OF PATIOS
  // =================================================================================================

  const handleCLosePortoModal = async (u) => {
    console.log(u);
    if (u === null) {
      setIsModalAddPorto(false);
      setIsModalEditPorto(false);
      return;
    }
    const data = {
      name: u.name,
      address: u.address,
      isActive: u.isActive,
      legalname: u.legalname,
      accountsIdaccounts: u.accountsIdaccounts,
    };
    if (u.idportos === undefined) {
      await axios.post(`${API_URL}portos`, data, { headers: authHeader() });
    } else {
      await axios.put(`${API_URL}portos/${u.idportos}`, data, { headers: authHeader() });
    }
    setPorto({});
    axios.get(`${API_URL}portos`, { headers: authHeader() }).then(
      (response) => {
        //  console.log(response.data);
        setPortoList(response.data);
        if (response.data.length === 1) {
          setPorto(response.data[0]);
        }
      },
      (error) => {
        console.log(error);
      }
    );
    setIsModalAddPorto(false);
    setIsModalEditPorto(false);
  };
  // =================================================================================================
  // BEGINING OF USERS
  // =================================================================================================

  const [isModalUser, setIsModalUser] = React.useState(false);
  const [editUser, setEditUser] = React.useState(null);

  const handleOpenUserModal = (u) => {
    setIsModalUser(true);
    setEditUser(u);
  };

  const {
    columns,
    rows,
    update: uUpdate,
  } = usersTableData(
    handleOpenUserModal,
    user.isSysAdmin,
    user.isSysAdmin ? null : user.accountsIdaccounts
  );

  const handleCloseUserModal = async (u) => {
    if (u === null) {
      setIsModalUser(false);
      setEditUser(null);
      return;
    }
    if (u.id !== null) {
      await axios.put(`${API_URL}users/${u.id}`, u.data, { headers: authHeader() });
    } else {
      await axios.post(`${API_URL}users`, u.data, { headers: authHeader() });
    }
    uUpdate(true);
    setIsModalUser(false);
    setEditUser(null);
  };

  // =================================================================================================
  // END OF USERS
  // =================================================================================================

  // =================================================================================================
  // BEGINING OF CONTACTS
  // =================================================================================================

  const [isModalContacts, setIsModalContacts] = React.useState(false);
  const [editContacts, setEditContacts] = React.useState(null);

  const handOpenContactsModal = (u) => {
    setIsModalContacts(true);
    setEditContacts(u);
  };

  const {
    columns: pColumns,
    rows: pRows,
    update: cUpdate,
  } = contactsTableData(handOpenContactsModal);

  const handCloseContactsModal = async (u) => {
    console.log("fechamento do modal contatos", u);
    if (u === null) {
      setIsModalContacts(false);
      setEditContacts(null);
      return;
    }
    if (u.idcontatos !== null) {
      await axios.put(`${API_URL}contatos/${u.idcontatos}`, u.data, { headers: authHeader() });
    } else {
      await axios.post(`${API_URL}contatos`, u.data, { headers: authHeader() });
    }
    cUpdate(true);
    setIsModalContacts(false);
    setEditContacts(null);
  };

  // =================================================================================================
  // END OF CONTACTS
  // =================================================================================================

  // =================================================================================================
  // BEGINING OF ACCOUNTS
  // =================================================================================================

  const [isModalAccounts, setIsModalAccounts] = React.useState(false);
  const [editAccounts, setEditAccounts] = React.useState(null);

  const handleOpenAccountsModal = (u) => {
    setIsModalAccounts(true);
    setEditAccounts(u);
  };

  const {
    columns: aColumns,
    rows: aRows,
    update: aUpdate,
  } = accountsTableData(handleOpenAccountsModal);

  const handleCloseAccountsModal = async (u) => {
    if (u === null) {
      setIsModalAccounts(false);
      setEditAccounts(null);
      return;
    }
    if (u.id !== null) {
      await axios.put(`${API_URL}accounts/${u.id}`, u.data, { headers: authHeader() });
    } else {
      await axios.post(`${API_URL}accounts`, u.data, { headers: authHeader() });
    }
    aUpdate(true);
    setIsModalAccounts(false);
    setEditAccounts(null);
  };

  // =================================================================================================
  // END OF ACCOUNTS
  // =================================================================================================

  const { t } = useTranslation("common");
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      if (portoList.length === 0) {
        axios.get(`${API_URL}portos`, { headers: authHeader() }).then(
          (response) => {
            //  console.log(response.data);
            setPortoList(response.data);
            if (response.data.length === 1) {
              setPorto(response.data[0]);
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    updatePatioList();
  }, [porto]);

  const handleChangePorto = (event) => {
    // console.log(event)
    setPorto(portoList.find((p) => p.idportos === event.target.value));
  };

  return (
    <SysDashLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {t("menu.admin")}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3} sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label={t("accounts.tab_users")} {...a11yProps(0)} />
            <Tab label={t("accounts.tab_contacts")} {...a11yProps(1)} />
            <Tab label={t("accounts.tab_harbors_and_docks")} {...a11yProps(2)} />
            {user.isSysAdmin ? <Tab label={t("accounts.tab_accounts")} {...a11yProps(3)} /> : null}
          </Tabs>
        </MDBox>
        <TabPanel value={value} index={0}>
          <DataTable
            currentPage={handleCurrentPage}
            numberPage={currentPage}
            table={{ columns, rows }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          />
          {isModalUser ? (
            <ModalUsers
              title={t("modal.mod_edit_user")}
              user={editUser}
              onClose={handleCloseUserModal}
              isSysAdm={user.isSysAdmin}
            />
          ) : null}
          <Grid container style={{ justifyContent: "end", alignItems: "center" }}>
            <Grid item mr={5}>
              <Stack direction="row">
                <FormControl
                  sx={{ p: 1, width: "100%" }}
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <MDButton
                    onClick={() => handleOpenUserModal(null)}
                    sx={{ width: "100%" }}
                    variant="gradient"
                    color="info"
                    style={{ height: "3rem" }}
                  >
                    {t("modal.mod_new")}
                  </MDButton>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DataTable
            currentPage={handleCurrentPage}
            numberPage={currentPage}
            table={{ columns: pColumns, rows: pRows }}
            isSorted={false}
            entriesPerPage={false}
            showTotalEntries={false}
            noEndBorder
          />
          {isModalContacts === true ? (
            <ModalContacts
              title={t("modal.mod_edit_contacts")}
              contact={editContacts}
              onClose={handCloseContactsModal}
            />
          ) : null}
          <Grid container style={{ justifyContent: "end", alignItems: "center" }}>
            <Grid item mr={5}>
              <Stack direction="row">
                <FormControl
                  sx={{ p: 1, width: "100%" }}
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <MDButton
                    onClick={() => handOpenContactsModal(null)}
                    // onClick={props.onClose}
                    sx={{ width: "100%" }}
                    variant="gradient"
                    color="info"
                    style={{ height: "3rem" }}
                  >
                    {t("modal.mod_new")}
                  </MDButton>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          {/* {console.log(portoList)} */}

          {portoList.length === 0 ? (
            <Grid item xs={11} sm={11} md={11} lg={11} xl={11} my={1} px={3}>
              <MDTypography fullWidth variant="h4" mt={3} xs={11}>
                {" Nenhum porto encontrado para a conta"}
              </MDTypography>
              <Stack direction={{ xs: "column" }} spacing={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  mt={4}
                  onClick={() => setIsModalAddPorto(true)}
                >
                  {t("modal.mod_new_harbor")}
                </MDButton>

                {isModalAddPorto ? (
                  <ModalHarbor title={t("modal.mod_new_harbor")} onClose={handleCLosePortoModal} />
                ) : null}
              </Stack>
            </Grid>
          ) : (
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={11} sm={11} md={3} lg={3} xl={3} my={1}>
                <MDBox py={2}>
                  <FormControl variant="standard" fullWidth mt={2} py={3}>
                    <InputLabel id="label-porto">{t("operational.harbor_select")}</InputLabel>
                    <Select
                      labelId="label-porto"
                      id="select-porto"
                      value={porto.idportos}
                      onChange={handleChangePorto}
                      // style={{height: "2rem"}}
                      my={3}
                    >
                      {portoList.map((p) => (
                        <MenuItem key={p.idportos} value={p.idportos} name={p.name}>
                          {p.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MDBox>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                    position: "relative",
                    overflow: "auto",
                    maxHeight: 300,
                    "& ul": { padding: 0 },
                  }}
                  subheader={<li />}
                >
                  <li key="section-patios">
                    <ul>
                      {patioList?.map((item) => (
                        <ListItem key={`item-${porto.idportos}-${item.idpatios}`}>
                          <Icon>{item.isActive ? "check" : "clear"}</Icon>
                          <ListItemText primary={`${item.name}`} />
                          <MDButton variant="gradient" onClick={() => handleOpenPatioModal(item)}>
                            <Icon>edit</Icon>
                          </MDButton>
                        </ListItem>
                      ))}
                    </ul>
                  </li>
                </List>
                {isModalEditPatio ? (
                  <ModalDock
                    title={t("modal.mod_edit_dock")}
                    onClose={handleClosePatioModal}
                    patio={editPatio}
                  />
                ) : null}
              </Grid>
              <Grid item xs={11} sm={11} md={3} lg={3} xl={3} my={1} px={3}>
                <Stack direction={{ xs: "column" }} spacing={1}>
                  <MDButton
                    variant="gradient"
                    fullWidth
                    color="info"
                    mt={4}
                    onClick={() => handleOpenPatioModal(null)}
                  >
                    {t("modal.mod_new_dock")}
                  </MDButton>
                  <MDButton
                    variant="gradient"
                    fullWidth
                    color="info"
                    mt={4}
                    onClick={() => setIsModalAddPorto(true)}
                  >
                    {t("modal.mod_new_harbor")}
                  </MDButton>
                  {isModalAddPorto ? (
                    <ModalHarbor
                      title={t("modal.mod_new_harbor")}
                      onClose={handleCLosePortoModal}
                    />
                  ) : null}
                </Stack>
              </Grid>
              <Grid item xs={11} sm={11} md={3} lg={3} xl={3} my={2}>
                <MDTypography variant="h6" mt={3}>
                  {t("modal.mod_input_name")}
                </MDTypography>
                <MDTypography variant="h5">{porto.name}</MDTypography>
                <MDTypography variant="h6" mt={3}>
                  {t("modal.mod_legal_name")}
                </MDTypography>
                <MDTypography variant="h5">{porto.legalname}</MDTypography>
                <MDTypography variant="h6" mt={3}>
                  {t("modal.mod_input_address")}
                </MDTypography>
                <MDTypography variant="h5">{porto.address}</MDTypography>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => (porto?.idportos > 0 ? setIsModalEditPorto(true) : null)}
                >
                  {t("modal.mod_edit_harbor")}
                </MDButton>
                {isModalEditPorto ? (
                  <ModalHarbor
                    title={t("modal.mod_edit_harbor")}
                    porto={porto}
                    onClose={handleCLosePortoModal}
                  />
                ) : null}
              </Grid>
            </Grid>
          )}
        </TabPanel>
        {user.isSysAdmin ? (
          <TabPanel value={value} index={3}>
            <DataTable
              currentPage={handleCurrentPage}
              numberPage={currentPage}
              table={{ columns: aColumns, rows: aRows }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
            {isModalAccounts ? (
              <ModalAccounts
                title={t("modal.mod_edit_wallet")}
                account={editAccounts}
                onClose={handleCloseAccountsModal}
              />
            ) : null}
            <Grid container style={{ justifyContent: "end", alignItems: "center" }}>
              <Grid item mr={5}>
                <Stack direction="row">
                  <FormControl
                    sx={{ p: 1, width: "100%" }}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <MDButton
                      onClick={() => handleOpenAccountsModal(null)}
                      sx={{ width: "100%" }}
                      variant="gradient"
                      color="info"
                      style={{ height: "3rem" }}
                    >
                      {t("modal.mod_new")}
                    </MDButton>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </TabPanel>
        ) : null}
      </Card>
    </SysDashLayout>
  );
}

export default SystemAccounts;
