import React, { useState } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Lottie from "lottie-react";
import { useTranslation } from "react-i18next";
import animationPrivacy from "../../../assets/animations/privacy.json";
import animationTerms from "../../../assets/animations/terms.json";
import MDTypography from "../../../components/MDTypography";
import MDBox from "../../../components/MDBox";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import logo from "assets/images/logos/Marca3.png";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <MDBox sx={{ p: 3 }}>
          <MDTypography>{children}</MDTypography>
        </MDBox>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function PrivacyPage() {
  return (
    <Card p={1}>
      <Grid container justifyContent="center" alignItems="center" style={{ height: "100%" }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={8}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid container justifyContent="center" alignItems="center">
              <Lottie
                animationData={animationPrivacy}
                loop={false}
                autoplay
                style={{ width: 200, height: 200 }}
              />
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <MDTypography variant="h5" fontWeight="medium" color="gray" mt={1}>
                <h2>POLÍTICA DE PRIVACIDADE DO REEFERSYS</h2>
              </MDTypography>
            </Grid>
            <Grid container justifyContent="left" alignItems="center">
              <MDTypography variant="subtitle1" color="gray" mt={10} mb={10}>
                <p>Em Vigor a partir de 07 de fevereiro de 2024</p>
              </MDTypography>
            </Grid>
            <MDTypography variant="body2" fontWeight="8" color="gray" mt={6}>
              <h2>Essa política de privacidade se refere ao aplicativo:</h2>
              <br></br>
              <ul>
                <li>Aplicativo de Monitoramento de containers - Reefersys</li>
              </ul>
              <br></br>
              <p>Criado por Mecatrônica Reefer / Francisco Luca</p>
              <br></br>
              <h5>Informações fornecidas pelo usuário</h5>
              <p>
                As informações fornecidas dentro dos recursos dos aplicativos são armazenadas apenas
                para funcionamento do aplicativo.
              </p>
              <br></br>
              <h5>Coleta automática de informações</h5>
              <p>
                Informações como estatísticas de instalação e utilização são coletadas para
                relatórios estratégicos, sem identificação pessoal.
              </p>
              <br></br>
              <h5>
                Coleta de informações não precisas de localização em tempo real do dispositivo
              </h5>
              <p>Este aplicativo não faz a coleta de informações de localização em tempo real.</p>
              <br></br>
              <h5>Acesso de informações por terceiros</h5>
              <p>
                As informações coletadas estatisticamente podem ser coletadas com terceiros em
                algumas situações, são elas:
              </p>
              <br></br>
              <p>Exigido por lei</p>
              <br></br>
              <p>
                Quando acreditamos de boa fé que a divulgação é necessária para proteger nossos
                direitos, proteger sua segurança ou a segurança de outras pessoas, investigar
                fraudes ou responder a uma solicitação do governo.
              </p>
              <br></br>
              <p></p>Com nossos prestadores de serviços confiáveis que trabalham em nosso nome, não
              têm um uso independente das informações que divulgamos a eles e concordamos em aderir
              às regras estabelecidas nesta declaração de privacidade.<p></p>
              <br></br>
              <p>
                Se eu (Mecatrônica Reefer / Francisco Luca) estiver envolvido em uma fusão,
                aquisição ou venda de todos ou de uma parte de seus ativos, você será notificado por
                e-mail e / ou um aviso destacado em nosso site sobre qualquer alteração na
                propriedade ou uso dessas informações, bem como quaisquer escolhas que você possa
                ter em relação a essas informações.
              </p>
              <br></br>
              <h5>Dos direitos de exclusão</h5>
              <p>
                Desative todas as coletas de informações desinstalando o Aplicativo: Você pode parar
                toda a coleta de informações pelo Aplicativo facilmente, desinstalando o Aplicativo.
                Você pode usar os processos de desinstalação padrão que podem estar disponíveis como
                parte de seu dispositivo móvel ou via mercado ou rede de aplicativos móveis.{" "}
              </p>
              <br></br>
              <h5>Segurança</h5>
              <p>
                Estamos preocupados em proteger a confidencialidade de suas informações. Fornecemos
                proteções físicas, eletrônicas e processuais para proteger as informações que
                processamos e mantemos. Por favor, esteja ciente de que, embora nos empenhemos em
                fornecer segurança razoável para as informações que processamos e mantemos, nenhum
                sistema de segurança pode evitar todas as possíveis violações de segurança.
              </p>
              <br></br>
              <h5>Alterações e Consentimento</h5>
              <p>
                Esta Política de Privacidade pode ser atualizada de tempos em tempos por qualquer
                motivo. Toda e qualquer alteração será publicada neste mesmo local. Recomenda-se que
                você consulte esta Política de Privacidade regularmente para quaisquer alterações,
                pois a instalação do aplicativo é considerado como aprovação de toda aceitação e as
                alterações das condições.
              </p>
              <br></br>
              <h5>Sobre</h5>
              <p>
                Qualquer dúvida ou necessidade de alguma explicação mais detalhada entre em contato
                diretamente através deste email: mecatronica.reefer@gmail.com
              </p>
              <br></br>
              <h5>MECATRÔNICA REEFER SYSTEM & SERVICE</h5>
              <br></br>
              <br></br>
            </MDTypography>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

export default PrivacyPage;
