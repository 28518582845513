/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import * as React from "react";
// @mui material components

import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import Box from "@mui/material/Box";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
// import AlertIcon from "@mui/icons-material/AddAlert";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
// import authService from "helpers/auth.service";
import axios from "axios";
import Icon from "@mui/material/Icon";

import * as XLSX from "xlsx";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { CircularProgress } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// @mui material components
import TextField from "@mui/material/TextField";

// @mui material components
import Grid from "@mui/material/Grid";
import moment from "moment";

import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

// Authentication layout components

// I18N
import { useTranslation } from "react-i18next";

import FileSaver from "file-saver";
import formatDate from "../../helpers/formatDate";
import authService from "../../helpers/auth.service";
import config from "../../config/config.json";
import authHeader from "../../helpers/auth-headers";
import ContainerMonitoringCard from "../../components_reefer/Cards/ContainerMonitoring";
import MDTypography from "../../components/MDTypography";
import SysDashLayout from "../../components_reefer/Layouts/SysDashLayout";
import MDButton from "../../components/MDButton";
import MDBox from "../../components/MDBox";
import ModalAlerts from "../../components_reefer/Modals/ModalSendAlert";

// eslint-disable-next-line global-require
global.Buffer = global.Buffer || require("buffer").Buffer;

function SystemMonitoring() {
  const { API_URL } = config;

  const currentTime = moment();

  // console.log(authService.getCurrentUser())
  // const user = authService.getCurrentUser()
  const { t } = useTranslation("common");
  const [patio, setPatio] = React.useState("");
  const [porto, setPorto] = React.useState({});
  const [patioList, setPatioList] = React.useState([]);
  const [portoList, setPortoList] = React.useState([]);
  const [ctnrList, setCtnrList] = React.useState([]);
  const [errAll, setErrAll] = React.useState(0);
  const [isRetro, setIsRetro] = React.useState(false);
  const [retroType, setRetroType] = React.useState("am");
  const [retroDateErr, setRetroDateErr] = React.useState();
  const [retroMsg, setRetroMsg] = React.useState(false);
  const [retro, setRetro] = React.useState(currentTime.toDate());
  const [generalErr, setGenErr] = React.useState("");
  const [minDate, setMinDate] = React.useState(moment().clone().startOf("month"));
  const [loading, setLoading] = React.useState(false);
  const user = authService.getCurrentUser();
  const [users, setUsers] = React.useState([]);
  const [contacts, setContacts] = React.useState([]);
  const [uniqueCarrier, setUniqueCarrier] = React.useState([]);
  const [retroDate, setRetroDate] = React.useState(false);
  const [count, setCount] = React.useState(0);

  // search field
  const [isScrolled, setIsScrolled] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  // const [list, setList] = React.useState([]);
  const inputRef = React.useRef(null);

  //

  React.useEffect(() => {
    console.log("controle", isScrolled);
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsScrolled(scrollTop > 600);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScrolled]);

  const list = ctnrList.filter((item) => item.containers_label.includes(searchText.toUpperCase()));

  React.useEffect(() => {
    const fetchData = async () => {
      if (portoList.length === 0) {
        axios.get(`${API_URL}portos/${user.accountsIdaccounts}`, { headers: authHeader() }).then(
          (response) => {
            setPortoList(response.data);
            if (response.data.length === 1) {
              setPorto(response.data[0]);
              //  console.log(porto)
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    };
    fetchData();
  }, []);
  React.useEffect(() => {
    //  console.log(porto)
    const fetchData = async () => {
      if (patioList.length === 0 && porto.idportos) {
        axios.get(`${API_URL}patios/${porto.idportos}`, { headers: authHeader() }).then(
          (response) => {
            setPatioList(response.data);
          },
          (error) => {
            console.log(error);
          }
        );
      }
      if (porto.idportos) {
        const u = await axios.get(`${API_URL}users/account/${porto.accountsIdaccounts}`, {
          headers: authHeader(),
        });
        // console.log(u.data);
        setUsers(u.data);
        const c = await axios.get(`${API_URL}contatos`);
        setContacts(c.data);
      }
    };
    fetchData();
  }, [porto]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (patio > 0) {
        setLoading(true);
        axios.get(`${API_URL}agenda/${patio}`, { headers: authHeader() }).then(
          (response) => {
            const currList = [];
            let minD = minDate;
            response.data.forEach((ctnr) => {
              if (moment(ctnr.date_move) > minD) {
                minD = moment(ctnr.date_move);
              }
              currList.push({
                id: ctnr.id,
                isCheck: false,
                containers_label: ctnr.carrier
                  ? `${ctnr.containers_label} - ${ctnr.carrier}`
                  : ctnr.containers_label,
                label: ctnr.containers_label,
                setpoint: ctnr.temperature,
                current: ctnr.temperature,
                carrier: ctnr.carrier,
                idmovimentacoes: ctnr.movimentacoes_idmovimentacoes_in,
                isErr: 0,
                photos: [],
                notes: "",
              });
            });
            if (currList.length === 0) {
              setLoading(false);
            }
            setMinDate(minD);
            setCtnrList(currList);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    };
    fetchData();
  }, [patio]);

  React.useEffect(() => {
    if (ctnrList.length > 0) {
      setLoading(false);
    }
  }, [ctnrList]);

  React.useEffect(() => {
    console.log("Lista-------", ctnrList);
  }, [ctnrList]);

  React.useEffect(() => {}, [minDate]);

  const handleChangeRetro = (newValue) => {
    setRetro(newValue);
  };

  const handleChangePorto = (event) => {
    // console.log(event.target)
    setPorto({ idportos: event.target.value, name: event.target.name });
  };

  const handleChangePatio = (event) => {
    setPatio(event.target.value);
  };

  const handleChangeErrAll = (val) => {
    let tmperr = val;
    if (tmperr === errAll) {
      tmperr = 0;
    }
    const tmpList = ctnrList;
    tmpList.forEach((ctnr) => {
      // eslint-disable-next-line no-param-reassign
      ctnr.isErr = ctnr.isErr === errAll ? tmperr : ctnr.isErr;
    });
    setErrAll(tmperr);
    setCtnrList([...tmpList]);
  };

  const theme = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "3rem",
            color: "#fff",
          },
        },
      },
    },
  });

  // const themeRed = createTheme({
  //   components: {
  //     MuiIcon: {
  //       styleOverrides: {
  //         root: {
  //           fontSize: "3rem",
  //           color: "red",
  //         },
  //       },
  //     },
  //   },
  // });

  // const themeYellow = createTheme({
  //   components: {
  //     MuiIcon: {
  //       styleOverrides: {
  //         root: {
  //           fontSize: "3rem",
  //           color: "yellow",
  //         },
  //       },
  //     },
  //   },
  // });

  const themeOcean = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "3rem",
            color: "#a6dced",
          },
        },
      },
    },
  });

  const printAgenda = () => {
    if (ctnrList.length === 0) {
      return;
    }
    // let pat = patioList.find((p) => p.idpatios === patio);
    const exportData = [];
    ctnrList.forEach((container) => {
      exportData.push({ container: container.containers_label, temp: container.setpoint });
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      `agenda_${new Date().toISOString().split("T")[0]}_${porto.name}_${patio.name}${fileExtension}`
    );
  };

  const addHours = (date, hours) => {
    date.hours(hours);
    return date;
  };

  const saveAgenda = async () => {
    if (ctnrList.length === 0) {
      toast.error(t("alerts.cont_none"));
      return;
    }
    if (retroDateErr !== null && retroDateErr !== undefined && isRetro) {
      alert(t("alerts.date_retro"));
      return;
    }
    const tmpList = [];
    let errCount = 0;
    ctnrList.forEach((ctnr) => {
      console.log("CTNR", ctnr);
      if (ctnr.isCheck === true) {
        if (ctnr.isErr > 0) {
          errCount += 1;
        }
        const errNote = retroMsg.length > 0 && isRetro ? `${retroMsg} - ${ctnr.notes}` : ctnr.notes;
        tmpList.push({
          readTimestamp: isRetro
            ? addHours(retro, retroType === "am" ? 6 : 18)
                .toDate()
                .toISOString()
            : new Date().toISOString(),
          idMovimentacoesHasContainer: ctnr.id,
          containers_label: ctnr.containers_label,
          temperature: ctnr.current,
          movimentacoesHasContainersMovimentacoesIdmovimentacoesIn: ctnr.idmovimentacoes,
          errType: ctnr.isErr,
          photos: ctnr.photos,
          notes: generalErr.length >= 1 ? `${generalErr} - ${errNote}` : errNote,
          preventive: ctnr.isPreventive ? 1 : 0,
        });
      }
    });
    if (tmpList.length > 0) {
      // console.log("TMPLIST:", tmpList);
      const data = {
        containers: tmpList,
      };
      console.log(
        "data----------------------------------------------------------------------------------------------",
        data
      );
      console.log(
        "sizeData----------------------------------------------------------------------------------------------",
        data.length
      );
      const r = await axios.post(`${API_URL}monitoring`, data, { headers: authHeader() });
      if (r.data === "OK" && errCount === 0) {
        toast.success(t("alerts.save_success"));
      } else if (r.data === "OK" && errCount > 0) {
        toast.success(t("alerts.save_success_error"));
      } else {
        toast.warning(t("alerts.error_save"));
      }
    }
  };

  const handleUpdate = (ind, newItem) => {
    ctnrList[ind] = newItem;
  };

  const handleUpdateCount = (ind) => {
    setCount((cont) => (ind.isCheck ? cont + 1 : cont - 1));
  };

  const handleFileInput = async (e, ctnr, pos) => {
    e.preventDefault();

    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    const filename = `${ctnr.label}_${new Date(Date.now())
      .toISOString()
      .split(".")[0]
      .replace(/:/g, "_")
      .replace(/-/g, "_")}.${e.target.files[0].name.split(".")[1]}`;
    const file = new File([e.target.files[0]], filename);
    formData.append("file", file);
    // formData.append("filename", filename);

    await axios.post(`${API_URL}upload`, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    const tmpList = ctnrList;
    tmpList[pos].photos.push(filename);
    setCtnrList([...tmpList]);
  };

  const handleDeleteImage = async (indexImage, pos) => {
    console.log(pos, indexImage);

    setCtnrList((prevList) => {
      const newList = [...prevList];
      newList[pos].photos = newList[pos].photos.filter((_, index) => index !== indexImage);
      return newList;
    });
  };

  const handleDelete = async (ctnr) => {
    // const tmpList = ctnrList;
    // e.preventDefault();
    // setLoading(true);
    const r = await axios.post(
      `${API_URL}agenda`,
      {
        id: ctnr.id,
      },
      { headers: authHeader() }
    );
    console.log("ID------", ctnr.id);
    console.log("STATUS------", r.status);
    if (r.status === 201) {
      // setCtnrList([]);
      // const tmpList = [];
      // ctnrList.forEach((item) => {
      //   if (item.id !== ctnr.id) {
      //     tmpList.push(item);
      //   }
      // });
      const updateData = ctnrList.filter((item) => item.id !== ctnr.id);
      setCtnrList(updateData);
    }
  };

  const loadLastMonitoring = async () => {
    if (patio > 0) {
      const r = await axios.get(`${API_URL}monitoring/latest/${patio}`, {
        headers: authHeader(),
      });
      if (r.data.length > 0) {
        setRetroDate(true);
        const tmpList = ctnrList;
        r.data.forEach((ctnr) => {
          const ind = tmpList.findIndex((item) => item.label === ctnr.containers_label);
          if (ind >= 0) {
            tmpList[ind].isCheck = true;
            tmpList[ind].notes = ctnr.notes;
            ctnr.shift && (tmpList[ind].photos = ctnr.photos);
            tmpList[ind].current = ctnr.temperature;
            tmpList[ind].isErr = ctnr.errType;
          }
        });
        setCtnrList([...tmpList]);
      }
    }
  };

  const sendAlert = async (c) => {
    console.log("sendalert", c);
    const tmpList = ctnrList.filter(
      (ctnr) =>
        ctnr.isCheck === true && ((ctnr.isErr > 0 && ctnr.isErr < 6) || ctnr.isPreventive === true)
    );
    console.log("contacts enter alert", c);
    console.log("TMPLIST", tmpList);
    const unique = [...new Set(tmpList.map((item) => item.carrier))];
    const generalUsers = c.filter((item) => item.isUser === true);
    const contactsByCarrier = [];

    unique.forEach((carrier) => {
      // const mapCarrier = carrier ? c.map((item) => item.carrier.map((carr) => carr.name)) : [];
      console.log("contactsByCarrier1", contactsByCarrier);
      // console.log("mapCarrier", mapCarrier);
      // const testeflter =
      // console.log(
      //   "carrier",
      //   // mapCarrier.filter((item) => item === carrier)
      //   // mapCarrier.map((item) => item.includes(carrier))
      //   // mapCarrier.filter((item) => item)
      //   testeflter
      // );
      console.log("carrier", carrier);

      contactsByCarrier.push({
        carrier,
        contacts: c.filter(
          (contact) => contact.carrier && contact.carrier.some((item) => item.name === carrier)
        ),
        containers: tmpList.filter((item) => item.carrier === carrier),
      });
    });
    const alerts = {
      alerts: [],
    };
    contactsByCarrier.forEach((item) => {
      const emails = item.contacts.map((contact) => contact.mail);
      const phones = item.contacts.map((contact) => contact.phone);
      alerts.alerts.push({
        containers: item.containers,
        email: emails,
        phone: phones,
        type: "alert",
      });
    });
    alerts.alerts.push({
      containers: tmpList,
      email: generalUsers.map((item) => item.mail),
      phone: generalUsers.map((item) => item.phone),
      type: "alert",
    });
    const res = await axios.post(`${API_URL}alert`, alerts, { headers: authHeader() });
    if (res.status === 201) {
      toast.success(t("alerts.send_success"));
    } else {
      toast.error(t("alerts.send_fail"));
    }
  };

  const sendReport = async (c) => {
    const tmpList = ctnrList.filter((ctnr) => ctnr.isCheck === true);
    if (tmpList.length === 0) {
      toast.error(t("alerts.cont_none"));
      return;
    }
    // // mover para depois do try quando tiver finalizar os testes com o backend
    // // fim do codigo a ser movido
    const u = c.filter((item) => item.isUser === true);
    const emails = u.map((item) => item.mail);
    const phones = u.map((item) => item.phone);
    const res = await axios.post(
      `${API_URL}alert`,
      {
        alerts: [
          {
            containers: tmpList,
            email: emails,
            phone: phones,
            type: "report",
          },
        ],
      },
      { headers: authHeader() }
    );
    if (res.status === 201) {
      toast.success(t("alerts.send_success"));
    } else {
      toast.error(t("alerts.send_fail"));
    }
  };

  const [isModalSave, setIsModalSave] = React.useState(false);

  const handleOpenSaveModal = () => {
    if (ctnrList.filter((ctnr) => ctnr.isCheck === true).length === 0) {
      toast.error(t("alerts.cont_none"));
      console.log("Depurando carrier 2", ctnrList);
      return;
    }
    setUniqueCarrier([
      ...new Set(
        ctnrList
          .filter((ctnr) => ctnr.isCheck === true && ctnr.isErr > 0 && ctnr.carrier)
          .map((item) => item.carrier)
      ),
    ]);
    setIsModalSave(true);
  };
  const handleCloseSaveModal = async (u, save) => {
    if (save) {
      saveAgenda();
    }
    if (u != null && u.length > 0) {
      console.log("onclose", u);
      sendAlert(u);
      sendReport(u);
    }
    setIsModalSave(false);
  };

  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "info.main",
    marginRight: 20,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  }));

  React.useEffect(() => {
    if (inputRef.current) {
      // Foca no campo de entrada sempre que o searchText mudar
      inputRef.current.focus();
    }
  }, [searchText]);

  return (
    <SysDashLayout>
      <ToastContainer />
      <Card p={1} sx={12}>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {t("menu.ops")}
          </MDTypography>
        </MDBox>
        <MDBox p={1} m={1}>
          <Grid container style={{ justifyContent: "center", alignItems: "center" }}>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3} p={3} m={1}>
              {" "}
              {/* Selecione o Pátio */}
              <Card
                style={{
                  minHeight: "24rem",
                  maxWidth: "20rem",
                  mimWidth: "19rem",
                  alignItems: "center",
                }}
              >
                <MDBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  width="90%"
                  mx={2}
                  mt={-2}
                  p={2}
                  mb={1}
                  textAlign="center"
                >
                  <MDTypography color="white" mt={1}>
                    {t("operational.dock_select")}
                  </MDTypography>
                </MDBox>
                <MDBox
                  width="85%"
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: "10rem" }}
                >
                  {portoList.length > 1 ? (
                    <FormControl variant="standard" fullWidth sx={{ m: 1 }}>
                      <InputLabel id="label-porto">{t("operational.harbor_select")}</InputLabel>
                      <Select
                        labelId="label-porto"
                        id="select-porto"
                        onChange={handleChangePorto}
                        style={{ height: "3rem" }}
                      >
                        {portoList.map((p) => (
                          <MenuItem key={p.idportos} value={p.idportos}>
                            {p.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : null}
                  <FormControl variant="standard" fullWidth sx={{ m: 1 }}>
                    <InputLabel id="label-patio">{t("operational.dock_select")}</InputLabel>
                    <Select
                      labelId="label-patio"
                      id="select-patio"
                      onChange={handleChangePatio}
                      style={{ height: "3rem" }}
                      fullWidth
                    >
                      {patioList.map((p) => (
                        <MenuItem key={p.idpatios} value={p.idpatios}>
                          {p.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MDBox>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  mt={-8}
                  mb={4}
                  sx={{ cursor: "pointer" }}
                >
                  <Box width="100%" display="flex" alignItems="left" justifyContent="left">
                    <MDTypography variant="h6" fontWeight="normal" color="text" mt={1} ml={2}>
                      {t("operational.containers_dock")} {ctnrList.length}
                    </MDTypography>
                  </Box>
                  <Box width="100%" display="flex" alignItems="left" justifyContent="left">
                    <MDTypography variant="h6" fontWeight="normal" color="text" mt={1} ml={2}>
                      {t("operational.containers_monit")} {count}
                    </MDTypography>
                  </Box>
                  <Box width="100%" display="flex" alignItems="left" justifyContent="left">
                    <MDTypography variant="h6" fontWeight="normal" color="text" mt={1} ml={2}>
                      {t("operational.monitoring_retro")}{" "}
                      {retroDate
                        ? formatDate(minDate.toISOString())
                        : formatDate(retro.toISOString())}
                    </MDTypography>
                  </Box>
                </Grid>
                <MDBox width="85%" justifyContent="bottom" alignItems="center">
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    mb={1}
                    disabled={ctnrList.length === 0}
                    onClick={loadLastMonitoring}
                  >
                    {t("operational.load_prev")}
                  </MDButton>
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3} p={3}>
              {" "}
              {/* Reportar Problema Geral */}
              <Card
                style={{
                  minHeight: "24rem",
                  maxWidth: "20rem",
                  mimWidth: "19rem",
                  alignItems: "center",
                }}
              >
                <MDBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  width="90%"
                  mx={2}
                  mt={-2}
                  p={2}
                  mb={1}
                  textAlign="center"
                >
                  <MDTypography color="white" mt={1}>
                    {t("operational.report_general")}
                  </MDTypography>
                </MDBox>
                <Grid
                  container
                  spacing={1}
                  mt={3}
                  style={{ height: "9rem" }}
                  width="100%"
                  justifyContent="center"
                >
                  {/* <Grid
                    item
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      handleChangeErrAll(4);
                    }}
                  >
                    <Box
                      sx={{
                        border: "2px grey",
                        borderRadius: 3,
                        bgcolor: errAll === 4 ? "#030303" : "darkgrey",
                      }}
                      display="flex"
                      alignItems="center"
                      style={{ height: "4.5rem", width: "4.5rem" }}
                      justifyContent="center"
                    >
                      {errAll === 4 ? (
                        <ThemeProvider theme={themeRed}>
                          <Icon>notification_important_outlined</Icon>
                        </ThemeProvider>
                      ) : (
                        <ThemeProvider theme={theme}>
                          <Icon>notification_important_outlined</Icon>
                        </ThemeProvider>
                      )}
                    </Box>
                  </Grid> */}
                  {/* <Grid
                    item
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    mx={2}
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      handleChangeErrAll(6);
                    }}
                  >
                    <Box
                      sx={{
                        border: "2px grey",
                        borderRadius: 3,
                        bgcolor: errAll === 6 ? "#030303" : "darkgrey",
                      }}
                      display="flex"
                      alignItems="center"
                      style={{ height: "4.5rem", width: "4.5rem" }}
                      justifyContent="center"
                    >
                      {errAll === 6 ? (
                        <ThemeProvider theme={themeYellow}>
                          <Icon>power_off_outlined</Icon>
                        </ThemeProvider>
                      ) : (
                        <ThemeProvider theme={theme}>
                          <Icon>power_off_outlined</Icon>
                        </ThemeProvider>
                      )}
                    </Box>
                  </Grid> */}
                  <Grid
                    item
                    xs={3}
                    sm={3}
                    md={3}
                    lg={3}
                    xl={3}
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      handleChangeErrAll(8);
                    }}
                  >
                    <Box
                      sx={{
                        border: "2px grey",
                        borderRadius: 3,
                        bgcolor: errAll === 8 ? "#030303" : "darkgrey",
                      }}
                      display="flex"
                      alignItems="center"
                      style={{ height: "4.5rem", width: "4.5rem" }}
                      justifyContent="center"
                    >
                      {errAll === 8 ? (
                        <ThemeProvider theme={themeOcean}>
                          <Icon>outlet_outlined</Icon>
                        </ThemeProvider>
                      ) : (
                        <ThemeProvider theme={theme}>
                          <Icon>outlet_outlined</Icon>
                        </ThemeProvider>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ cursor: "pointer" }}>
                    <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                      <MDTypography variant="h6" fontWeight="medium" color="text" mt={1}>
                        {t(`operational.err${errAll}`)}
                      </MDTypography>
                    </Box>
                  </Grid>
                </Grid>
                <MDBox width="85%" style={{ height: "8rem" }}>
                  <TextField
                    id="outlined-basic"
                    label={t("common.description")}
                    variant="outlined"
                    onChange={(e) => {
                      setGenErr(e.target.value);
                    }}
                    multiline
                    rows={4}
                    fullWidth
                  />
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4} xl={3} p={3} m={1}>
              {" "}
              {/* Monitoramento Retroativo */}
              <Card
                style={{
                  minHeight: "24rem",
                  maxWidth: "20rem",
                  mimWidth: "19rem",
                  alignItems: "center",
                }}
              >
                <MDBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                  width="90%"
                  mx={2}
                  mt={-2}
                  p={2}
                  mb={1}
                  textAlign="center"
                >
                  <MDTypography color="white" mt={1}>
                    {t("operational.retro_monitoring")}
                  </MDTypography>
                </MDBox>
                <MDBox
                  mt={2}
                  px={4}
                  style={{ width: "100%", justifyContent: "center", alignItems: "center" }}
                >
                  <DesktopDatePicker
                    label={t("operational.retro_date")}
                    inputFormat="DD/MM/yyyy"
                    value={retro}
                    onChange={handleChangeRetro}
                    // minDate={minDate}
                    maxDate={currentTime}
                    onError={(e) => {
                      setRetroDateErr(e);
                    }}
                    renderInput={(params) => <TextField variant="standard" {...params} />}
                  />
                  <Box>
                    <Grid container>
                      <Grid item xs={6}>
                        <Box m={1}>
                          <FormControl>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue="female"
                              name="radio-buttons-group"
                              onChange={(e) => {
                                setRetroType(e.target.value);
                              }}
                              value={retroType}
                            >
                              <FormControlLabel value="am" control={<Radio />} label="AM" />
                              <FormControlLabel value="pm" control={<Radio />} label="PM" />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        onClick={() => {
                          setIsRetro(!isRetro);
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        <Box p={0.5} m={1} sx={{ alignItems: "center" }}>
                          {isRetro ? (
                            <Box
                              sx={{
                                border: "2px dashed grey",
                                borderRadius: 3,
                                bgcolor: "#088923",
                              }}
                              style={{ height: "4.5rem", width: "4.5rem" }}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <ThemeProvider theme={theme}>
                                <Icon>check_circle_outline</Icon>
                              </ThemeProvider>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                border: "2px dashed grey",
                                borderRadius: 3,
                                bgcolor: "#ffffff",
                              }}
                              style={{ height: "4.5rem", width: "4.5rem" }}
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <ThemeProvider theme={theme}>
                                <Icon>check_circle_outline</Icon>
                              </ThemeProvider>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </MDBox>
                <MDBox width="85%" style={{ height: "8rem" }}>
                  <TextField
                    id="outlined-basic"
                    label={t("common.description")}
                    variant="outlined"
                    onChange={(e) => {
                      setRetroMsg(e.target.value);
                    }}
                    multiline
                    rows={4}
                    fullWidth
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
      {("comportamento", isScrolled)}
      <Card
        p={1}
        sx={2}
        style={{
          marginTop: isScrolled ? 10 : 20,
          position: isScrolled ? "fixed" : "sticky",
          top: 0,
          left: 0,
          width: "100%",
          backgroundColor: "#fff" /* Cor de fundo da barra fixa */,
          zIndex: 1000,
          transition: "position 0.10s ease",
        }}
      >
        <MDBox
          mt={2}
          mb={2}
          width="100%"
          justifyContent="flex-start"
          alignItems="center"
          // style={{ position: "fixed" }}
          sx={{ display: "flex" }}
        >
          <MDButton
            style={{ marginLeft: 20 }}
            variant="gradient"
            color="info"
            size="large"
            mx={3}
            onClick={() => {
              printAgenda();
            }}
            endIcon={<LocalPrintshopIcon />}
          >
            {t("operational.but_print")}
          </MDButton>
          <MDBox width="2%" />
          <MDButton
            variant="gradient"
            color="info"
            size="large"
            mx={3}
            onClick={() => {
              handleCloseSaveModal(null, true);
            }}
            endIcon={<SaveIcon />}
          >
            {t("operational.but_save")}
          </MDButton>
          <MDBox width="2%" />
          <MDButton
            variant="gradient"
            color="info"
            size="large"
            mx={3}
            onClick={() => {
              handleOpenSaveModal(null);
            }}
            endIcon={<SendIcon />}
          >
            {t("common.but_save_send")}
          </MDButton>
          <MDBox width="2%" bgcolor="red" />
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              inputRef={inputRef}
              placeholder="Pesquise"
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        </MDBox>
      </Card>

      {ctnrList.length > 0 && loading === false ? (
        <MDBox p={1} m={1} width="100%">
          <Grid container spacing={2} sx={{ justifyContent: "center", alignItems: "center" }}>
            {list.map(
              (p, i) =>
                p.isDelete ? (
                  console.log(p.containers_label)
                ) : (
                  <ContainerMonitoringCard
                    key={p.containers_label}
                    index={i}
                    itemOrigin={p}
                    onChangeParent={handleUpdate}
                    onDeleteParent={handleDelete}
                    handleFileInputParent={handleFileInput}
                    handleDeleteInputParent={handleDeleteImage}
                    onUpdateParent={handleUpdateCount}
                  />
                )

              // <MDTypography key={p.containers_label} variant="h4" color="textPrimary">
              //   {p.containers_label} - {i}
              // </MDTypography>
            )}
          </Grid>
        </MDBox>
      ) : null}
      {isModalSave ? (
        <ModalAlerts
          title={t("operational.but_save")}
          users={users}
          contacts={contacts}
          carriers={uniqueCarrier}
          onClose={handleCloseSaveModal}
          patio={patioList.find((p) => p.idpatios === patio).idpatios}
        />
      ) : null}

      {loading ? (
        <Box sx={{ width: "100%" }} ml="48%" mt={5}>
          <MDTypography variant="h5" ml={-3} color="textPrimary">
            {t("alerts.loading")}
          </MDTypography>
          <CircularProgress color="info" />
        </Box>
      ) : null}
    </SysDashLayout>
  );
}

export default SystemMonitoring;
