/* eslint-disable no-unsafe-optional-chaining */
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Logo from "../../../../assets/images/logos/logo.png";
import assinatura from "../../../../assets/images/logos/assinatura.png";
import warningAmber from "../../../../assets/images/icons/err/warning_amber.png";
import highlightOff from "../../../../assets/images/icons/err/highlight_off.png";
import powerOff from "../../../../assets/images/icons/err/power_off_outlined.png";
import iconImportant from "../../../../assets/images/icons/err/notification_important_outlined.png";
import iconOutlet from "../../../../assets/images/icons/err/outlet_outlined.png";

const GeneratePdfMontClosing = (props) => {
  const doc = new jsPDF();

  // HEAD
  doc.setFont("*", "bold").setFontSize(8).setTextColor("#00000");
  doc.text("Espelho de Faturamento - Monitoramento", 10, 10);
  doc.text(`${props?.patio}`, 100, 10);
  doc.text(`${props?.InitDate} a ${props?.EndDate}`, 170, 10);

  doc.addImage(Logo, "PNG", 74, 14, 70, 32); // x, y, width, height, alias, compression, rotation

  doc.setFont("courier", "bold").setFontSize(8).setTextColor("#00008b");
  doc.text("CNPJ 09.380.074.0001/75", 88, 51);

  doc.setFont("courier", "bold").setFontSize(14).setTextColor("#00000");
  doc.text("RESUMO DE FECHAMENTO - MONITORAMENTO", 52, 56);

  doc.setFont("*", "bold").setFontSize(8).setTextColor("#00000");

  doc.addImage(warningAmber, "PNG", 40, 64, 16, 12);
  doc.text("Baixo Rendimento", 38, 79);
  doc.text("do Container", 41, 82);

  doc.addImage(highlightOff, "PNG", 70, 64, 16, 12);
  doc.text("Temperatura", 70, 79);
  doc.text("Crítica", 73, 82);

  doc.addImage(iconImportant, "PNG", 100, 64, 18, 12);
  doc.text("Container com", 100, 79);
  doc.text("Problema Crítico", 99, 82);

  doc.addImage(powerOff, "PNG", 130, 64, 16, 12);
  doc.text("Container Sem", 129, 79);
  doc.text("Alimentação", 131, 82);

  doc.addImage(iconOutlet, "PNG", 160, 64, 16, 12);
  doc.text("Falta de Energia", 158, 79);
  doc.text("no Pátio", 164, 82);

  const valueMonitoringUser = props.valuePerMonitoring;
  // TABLE
  const data = [];
  const keys = Object.keys(props?.reportData);
  keys.forEach((key) => {
    data.push([
      key,
      props?.reportData[key].travel,
      props?.reportData[key].totalContainers,
      props?.reportData[key].totalMonitoring,
      props?.reportData[key].totalMonitoring * valueMonitoringUser,
    ]);
  });
  console.log(props);
  autoTable(doc, {
    head: [["Navio", "Viagem de entrada", "Total de Containers", "Monitoring total", "Value"]],
    margin: { top: 88 },
    headStyles: { textColor: "#00000", fillColor: null },
    body: data,
  });
  // FOOTER
  let initialY = doc.lastAutoTable.finalY + 10;
  if (initialY > 190) {
    doc.addPage();
    doc.setPage(doc.getNumberOfPages());
    initialY = 10;
  } else {
    initialY = 196;
  }

  doc.setFontSize(8).setTextColor("#00000");
  doc.text(`Valor Total: R$ ${data.map((p) => p[4])}`, 74, initialY + 12);
  doc.addImage(assinatura, "PNG", 84, initialY + 22, 30, 30);

  doc.setFont("Courier", "bold").setFontSize(10).setTextColor("#00000");
  doc.text("Francisco de Paula Luca", 74, initialY + 58);

  doc.setFont("Courier", "bold").setFontSize(9).setTextColor("#00000");
  doc.text("CREA-AM 13.225D", 88, initialY + 64);

  doc.setFont("Courier", "bold").setFontSize(8).setTextColor("#00000");
  doc.text("Engenheiro Proprietário", 79, initialY + 70);

  const footerDesc =
    "Escritório: Rua Professor Guilherme Nery nº 480 - Parque 10 de novembro - CEP 69.055-350 - Manaus/AM";
  doc.setFont("Courier", "*").setFontSize(9).setTextColor("#00000");
  doc.text(footerDesc, 20, initialY + 80);
  doc.text("ReeferSys v.: 0.9.95/2022 acesso em 17/10/2022 21:08:54", 118, initialY + 84);

  doc.save(`Relatorio-Mensal-${props?.ship}.pdf`);
};

export default GeneratePdfMontClosing;
