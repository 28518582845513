/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";

import Icon from "@mui/material/Icon";

import { createTheme, ThemeProvider } from "@mui/material/styles";

export default function ReportErr(props) {
  const theme = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "2.0rem",
            color: "#fff",
          },
        },
      },
    },
  });
  const themeRed = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "2.0rem",
            color: "red",
          },
        },
      },
    },
  });

  const themeYellow = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "2.0rem",
            color: "yellow",
          },
        },
      },
    },
  });

  const themeOcean = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "2.0rem",
            color: "#a6dced",
          },
        },
      },
    },
  });

  return (
    <Container maxWidth="md">
      <Grid container sx={{ flexGrow: 1, p: 2, mt: 1 }}>
        <Grid item xs={12} sm={4} md={2.4} className="grid-icon">
          <FormControl>
            <Grid item xs={12} className="grid-icon">
              <Box className={props?.color}>
                <ThemeProvider
                  theme={
                    props?.errType === 4
                      ? themeRed
                      : props?.errType === 6
                      ? themeYellow
                      : props?.errType === 8
                      ? themeOcean
                      : theme
                  }
                >
                  <Icon>{props?.icon}</Icon>
                </ThemeProvider>
              </Box>
            </Grid>
          </FormControl>
        </Grid>
      </Grid>
    </Container>
  );
}
