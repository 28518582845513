// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React example components
import SystemNavbar from "components_reefer/Navbars/SystemNavbar";
import PageLayout from "components_reefer/Layouts/PageLayout";

function SysDashLayout({ children }) {
  return (
    <PageLayout>
      <SystemNavbar />
      <Grid
        container
        mt={13}
        pt={1}
        pb={1}
        justifyContent="center"
        alignItems="center"
        max-height="70%"
      >
        <Grid item xs={11} sm={11} md={11} lg={11} xl={11} p={5} style={{ overflow: "hidden" }}>
          {children}
        </Grid>
      </Grid>
    </PageLayout>
  );
}

// Typechecking props for the SysDashLayout
SysDashLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SysDashLayout;
