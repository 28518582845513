/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import * as React from "react";

// Material Dashboard 2 React components
// import Card from "@mui/material/Card";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// @mui material components
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";

export default function ModalAlerts(props) {
  const [contacts, setContacts] = React.useState([]);
  const [filteredList, setFilteredList] = React.useState([]);
  const [open] = React.useState(true);

  const { t } = useTranslation("common");
  console.log("Carries carriers", props?.carriers);
  // console.log("Patio:", props.patio);
  // console.log("usuario", props.users);
  console.log("contatos", props.contacts);
  // console.log("PROPS GERAL", props);

  React.useEffect(() => {
    const c = [];
    let i = 0;
    props?.users?.forEach((user) => {
      c.push({
        id: i,
        name: user.name,
        mail: user.mail,
        phone: user.phone,
        patios: user.isPatios,
        isSMS: 2,
        isSelected: true,
        isUser: true,
      });
      i += 1;
    });

    props?.contacts?.forEach((contact) => {
      const mapCarrier =
        contact.isCarriers?.length > 0 ? contact.isCarriers.map((carrier) => carrier.name) : [];

      console.log(
        "teste",
        mapCarrier.some((carrier) => props?.carriers?.includes(carrier))
      );
      c.push({
        id: i,
        name: contact.name,
        mail: contact.mail,
        phone: contact.phone,
        carrier: contact.isCarriers,
        patios: contact.isPatios,
        isSMS: 2,
        isSelected: mapCarrier.some((carrier) => props?.carriers?.includes(carrier)),
        isUser: false,
      });
      i += 1;
    });
    console.log("filtered contacts", c);
    setContacts(c);
  }, []);

  console.log("Lista tratada", contacts);

  React.useEffect(() => {
    const patiosToFind = props?.patio;
    console.log("patiosToFind", patiosToFind);

    const filteredContacts = contacts.filter(
      (user) =>
        user.patios &&
        Array.isArray(user.patios) &&
        user.patios.some((patio) => patio.idpatios === patiosToFind) &&
        user.isSelected === true
    );

    console.log("ARRRAY TRATADO", filteredContacts);
    setFilteredList(filteredContacts);
  }, [contacts]);

  const handleSaveAndSend = () => {
    if (filteredList.length > 0) {
      toast.success("Usuários receberão este alerta");
    } else {
      toast.error("Lista de recebimento vazia para este pátio");
    }
    console.log("handleSaveANDSEND", filteredList);
    props.onClose(filteredList, true);
    // console.log("ENVIADO:", filteredList);
  };

  return (
    <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("common.but_save_send")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("common.save_text")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.onClose(null, false);
            }}
          >
            {t("alerts.cancel")}
          </Button>
          <Button onClick={handleSaveAndSend} autoFocus>
            {t("alerts.agree")}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
