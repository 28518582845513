import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

// I18N
import Logo from "../../../assets/images/logos/logo.png";

export default function MaintenanceHeaderOS() {
  return (
    <>
      <Grid container>
        <Grid xs={12} item>
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <img className="report-logo" src={Logo} alt="Imagem" />
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={12} item>
          <Box style={{ display: "flex", justifyContent: "center" }} className="text-cnpj">
            CNPJ 09.380.074.0001/75
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
