/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import FormControl from "@mui/material/FormControl";
import formatDate from "../../helpers/formatDate";
import formatHour from "../../helpers/formatHour";
import ReportErr from "./Err/ReportErr";
import DataTable from "../Tables/DataTable";
import authService from "../../helpers/auth.service";

export default function ReportBodyMontSheet(props) {
  const { t } = useTranslation("common");
  const [rows, setRows] = React.useState([]);
  const usr = authService.getCurrentUser();
  console.log("RETORNO", props);
  console.log(usr);
  const errList = {
    1: { icon: "warning_amber", color: "box-icon-yellow" },
    2: { icon: "highlight_off", color: "box-icon-red" },
    3: { icon: "highlight_off", color: "box-icon-red" },
    4: { icon: "notification_important_outlined", color: "box-icon-black" },
    6: { icon: "power_off_outlined", color: "box-icon-black" },
    8: { icon: "outlet_outlined ", color: "box-icon-black" },
  };

  function MaintenanceIcon({ size }) {
    return (
      <img
        src={require("../../assets/images/icons/maintenance.png")} // Certifique-se de ajustar o caminho da imagem conforme necessário
        alt="Ícone"
        style={{
          width: size,
          height: size,
          filter: "brightness(0) invert(1)",
        }}
      />
    );
  }
  const optionList = {
    1: { icon: <MaintenanceIcon size={32} />, color: "box-icon-purple" },
  };

  const columns = [
    { Header: t("reports.head_container"), accessor: "container", align: "center" },
    { Header: t("table_mov.sheet_travel"), accessor: "travel", align: "left" },
    { Header: t("table_mov.sheet_total_containers_first"), accessor: "first", align: "left" },
    { Header: t("table_mov.sheet_total_containers_last"), accessor: "last", align: "center" },
    { Header: t("table_mov.sheet_setpoint"), accessor: "setPoint", align: "center" },
    { Header: t("table_mov.sheet_total_monit"), accessor: "total", align: "center" },
    { Header: t("table_mov.sheet_total_am"), accessor: "am", align: "center" },
    { Header: t("table_mov.sheet_total_pm"), accessor: "pm", align: "center" },
    { Header: t("table_mov.sheet_total_monit_fail"), accessor: "fail", align: "center" },
    { Header: t("table_mov.sheet_total_fail"), accessor: "totalFails", align: "center" },
    { Header: t("table_mov.sheet_value"), accessor: "value", align: "center" },
  ];

  React.useEffect(() => {
    if (rows.length > 0) {
      return;
    }
    const tmpRow = [];
    const keys = Object.keys(props.content.reportData.containers);
    keys.forEach((key) => {
      const e = [];
      props.content.reportData.containers[key].monitoring.forEach((m) => {
        if (m.errType > 0 || m.preventive === 1) {
          e.push(m);
        }
      });
      const hourIn = parseFloat(formatHour(props.content.reportData.containers[key].date_in));
      const hourOut = parseFloat(formatHour(props.content.reportData.containers[key].date_out));
      const dateIn = formatDate(props.content.reportData.containers[key].date_in);
      const dateOut = formatDate(props.content.reportData.containers[key].date_out);
      const valueMonitoringUser = props.content.valuePerMonitoring;

      tmpRow.push({
        container: key,
        travel: props.content.reportData.travel,
        first: hourIn <= 12 ? `${dateIn} - AM` : `${dateIn} - PM`,
        last: hourOut <= 12 ? `${dateOut} - AM` : `${dateOut} - PM`,
        setPoint: props.content.reportData.containers[key].temperature,
        total: props.content.reportData.containers[key].monitoring.length,
        am: props.content.reportData.containers[key].am,
        pm: props.content.reportData.containers[key].pm,
        fail: props.content.reportData.containers[key].err,
        value: props.content.reportData.containers[key].monitoring.length * valueMonitoringUser,
        totalFails: (
          <Grid container>
            {e.map((p) => (
              <>
                <Grid xs={3} className="grid-err-icon">
                  <FormControl>
                    <Grid container>
                      <Box>
                        <Grid container className="icon-err">
                          {p.errType > 0 && (
                            <ReportErr
                              icon={errList[p.errType].icon}
                              color={errList[p.errType].color}
                              errType={p.errType}
                            />
                          )}

                          {p.preventive === 1 && (
                            <ReportErr icon={optionList[1].icon} color={optionList[1].color} />
                          )}
                        </Grid>
                        {p.read_timestamp.length > 0 &&
                        parseFloat(formatHour(p.read_timestamp)) <= 12
                          ? `${formatDate(p.read_timestamp)} - AM`
                          : null}
                        {p.read_timestamp.length > 0 &&
                        parseFloat(formatHour(p.read_timestamp)) >= 12
                          ? `${formatDate(p.read_timestamp)} - PM`
                          : null}
                        <Box xs={6} className=".icon-date-text">
                          {p.notes}
                        </Box>
                      </Box>
                    </Grid>
                  </FormControl>
                </Grid>
                <Grid className="grid-gallery-icon">
                  <FormControl>
                    <Grid container>
                      <Box>
                        {p.photos.length > 0 ? (
                          <Icon
                            fontSize="large"
                            onClick={() => {
                              const url =
                                "https://s3.sa-east-1.amazonaws.com/report-assets.reefersys.com/";
                              window.open(url + p.photos, "_blank");
                            }}
                          >
                            image_outlined
                          </Icon>
                        ) : null}
                      </Box>
                    </Grid>
                  </FormControl>
                </Grid>
              </>
            ))}
          </Grid>
        ),
      });
    });
    setRows(tmpRow);
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={false}
          showTotalEntries={false}
          noEndBorder
        />
      </div>
      <Grid container>
        <Grid xs={12} mt={4} mb={4} item>
          <Box style={{ display: "flex", justifyContent: "center" }} className="text-title">
            {`${t("reports.title_total_containers")} ${props?.content.reportData.totalContainers}`}
          </Box>
          <Box style={{ display: "flex", justifyContent: "center" }} className="text-title">
            {`${t("reports.title_total_monitoring")} ${props?.content.reportData.totalMonitoring}`}
          </Box>
          {usr.isFinancial === 1 ? (
            <Box style={{ display: "flex", justifyContent: "center" }} className="text-title">
              {`${t("reports.title_total_valor")} ${
                props.content.reportData.totalMonitoring * props.content.valuePerMonitoring
              }`}
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}
