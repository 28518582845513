import Card from "@mui/material/Card";
import MDBox from "../../../components/MDBox";
import DataTable from "../../../components_reefer/Tables/DataTable/index";
// Data
import data from "./data/index";

function Projects() {
  const { columns, rows } = data();

  return (
    <Card>
      <MDBox>
        <DataTable
          table={{ columns, rows }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
      </MDBox>
    </Card>
  );
}

export default Projects;
