/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import * as React from "react";

// Material Dashboard 2 React components
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { Box, FormControlLabel } from "@mui/material";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";

import axios from "axios";
// I18N
import { useTranslation } from "react-i18next";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import MDBox from "../../components/MDBox";
import config from "../../config/config.json";
import authHeader from "../../helpers/auth-headers";

export default function ModalUsers(props) {
  const { API_URL } = config;
  const { t } = useTranslation("common");
  const [firstName, setFirstName] = React.useState("");
  const [cpf, setCpf] = React.useState("");
  const [crea, setCrea] = React.useState("");
  const [mail, setMail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [isActive, setIsActive] = React.useState(true);
  const [isAdm, setIsAdm] = React.useState(false);
  const [isSysAdm, setIsSysAdm] = React.useState(false);
  const [isReport, setIsReport] = React.useState(false);
  const [isOP, setIsOP] = React.useState(false);
  const [isOPAdmin, setIsOPAdmin] = React.useState(false);
  const [isMaint, setIsMaint] = React.useState(false);
  const [isAlert, setIsAlert] = React.useState(false);
  const [username, setUsername] = React.useState("");
  // const [signature, setSignature] = React.useState("");
  const [accountId, setAccountId] = React.useState(1);
  const [accountList, setAccountList] = React.useState([]);
  const [selectedPatios, setSelectedPatios] = React.useState([]); // Armazena os pátios selecionados
  const [patios, setPatios] = React.useState([]);
  const [isFinancial, setIsFinancial] = React.useState(false);

  console.log(props);
  React.useEffect(async () => {
    if (props.user) {
      setFirstName(props.user?.name);
      setCpf(props.user?.cpf);
      setCrea(props.user?.crea);
      setMail(props.user?.mail);
      setPhone(props.user?.phone);
      setIsActive(props.user?.isActive === 1);
      setIsAdm(props.user?.isAccountAdmin === 1);
      setIsSysAdm(props.user?.isSysAdmin === 1);
      setIsReport(props.user?.isFullReports === 1);
      setIsOP(props.user?.isOperational === 1);
      setIsMaint(props.user?.isMaintenance === 1);
      setIsAlert(props.user?.isAlert === 1);
      setUsername(props.user?.username);
      setIsOPAdmin(props.user?.isOPAdmin === 1);
      // setPhoto(props.user?.photo);
      // setSignature(props.user?.signature);
      setAccountId(props.user?.accounts?.idaccounts);
      setSelectedPatios(props.user?.isPatios);
      setIsFinancial(props.user?.isFinancial === 1);
    }
    if (accountList.length === 0) {
      const response = await axios.get(`${API_URL}accounts`, { headers: authHeader() });
      setAccountList(response.data);
    }
  }, []);

  const handleSave = () => {
    const user = {
      id: props.user?.idusers ? props.user.idusers : null,
      data: {
        name: firstName,
        username,
        cpf,
        crea,
        mail,
        phone,
        isAccountAdmin: isAdm,
        isSysAdmin: isSysAdm,
        isFullReports: isReport,
        isOperational: isOP,
        isMaintenance: isMaint,
        isAlert,
        isActive,
        isOPAdmin,
        accountsIdaccounts: accountId,
        isPatios: selectedPatios,
        isFinancial,
      },
    };
    props.onClose(user);
    console.log(user);
  };

  React.useEffect(() => {
    const patioList = async () => {
      axios.get(`${API_URL}patios/`, { headers: authHeader() }).then(
        (response) => {
          setPatios(response.data);
          console.log(response.data);
        },
        (error) => {
          console.log(error);
        }
      );
    };
    patioList();
  }, []);

  React.useEffect(() => {
    if (isAdm === true) {
      setIsOPAdmin(false);
      setIsFinancial(false);
      setIsSysAdm(false);
      setIsOP(false);
    }
  }, [isAdm]);

  React.useEffect(() => {
    if (isSysAdm === true) {
      setIsFinancial(false);
      setIsAdm(false);
      setIsOPAdmin(false);
      setIsOP(false);
    }
  }, [isSysAdm]);

  React.useEffect(() => {
    if (isOPAdmin === true) {
      setIsFinancial(false);
      setIsAdm(false);
      setIsSysAdm(false);
      setIsOP(false);
    }
  }, [isOPAdmin]);

  React.useEffect(() => {
    if (isOP === true) {
      setIsFinancial(false);
      setIsAdm(false);
      setIsSysAdm(false);
      setIsOPAdmin(false);
    }
  }, [isOP]);

  React.useEffect(() => {
    if (isFinancial === true) {
      setIsOPAdmin(false);
      setIsAdm(false);
      setIsSysAdm(false);
      setIsOP(false);
    }
  }, [isFinancial]);

  const handlePatioSelection = (selectedOptions) => {
    // Remove duplicates by creating a new Set and then converting it back to an array
    console.log("selectedOptionsselectedOptions", selectedOptions);
    const uniqueSelectedOptions = Array.from(
      new Set(selectedOptions.map((option) => JSON.stringify(option)))
    );
    console.log("uniqueSelectedOptions", uniqueSelectedOptions);
    setSelectedPatios(uniqueSelectedOptions.map((jsonString) => JSON.parse(jsonString)));
  };
  // console.log(selectedPatios);
  // console.log(patios);
  return (
    <Grid container xs={12} className="modal-transp">
      <Grid item xs={12} sm={9} md={8} lg={5} xl={4}>
        <MDBox className="card-modal" sx={{ bgcolor: "#ffff" }}>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={3}
            mt={-4}
            p={2}
            mb={3}
            textAlign="center"
            sx={{ width: "90%", bgcolor: "#888" }}
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              <Box>{props.user === null ? t("modal.mod_new") : props.title}</Box>
            </MDTypography>
          </MDBox>
          <Grid container>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <MDBox sx={{ mx: 1, px: 1 }}>
                <FormControlLabel
                  control={<Switch checked={isAdm} onChange={(e) => setIsAdm(e.target.checked)} />}
                  label={t("modal.mod_switch_adm_accounts")}
                />
              </MDBox>
            </Grid>
            {props.isSysAdm ? (
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <MDBox sx={{ mx: 1, px: 1 }}>
                  <FormControlLabel
                    control={
                      <Switch checked={isSysAdm} onChange={(e) => setIsSysAdm(e.target.checked)} />
                    }
                    label={t("modal.mod_switch_adm_system")}
                  />
                </MDBox>
              </Grid>
            ) : null}
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <MDBox sx={{ mx: 1, px: 1 }}>
                <FormControlLabel
                  control={
                    <Switch checked={isOPAdmin} onChange={(e) => setIsOPAdmin(e.target.checked)} />
                  }
                  label={t("modal.mod_switch_OPAdmin")}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <MDBox sx={{ mx: 1, px: 1 }}>
                <FormControlLabel
                  control={<Switch checked={isOP} onChange={(e) => setIsOP(e.target.checked)} />}
                  label={t("modal.mod_switch_operational")}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <MDBox sx={{ mx: 1, px: 1 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isFinancial}
                      onChange={(e) => setIsFinancial(e.target.checked)}
                    />
                  }
                  label={t("modal.mod_switch_financial")}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <MDBox sx={{ mx: 1, px: 1 }}>
                <FormControlLabel
                  control={
                    <Switch checked={isAlert} onChange={(e) => setIsAlert(e.target.checked)} />
                  }
                  label={t("modal.mod_switch_alert")}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <MDBox sx={{ mx: 1, px: 1 }}>
                <FormControlLabel
                  control={
                    <Switch checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />
                  }
                  label={t("modal.mod_switch_active")}
                />
              </MDBox>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <MDInput
                  id="outlined-basic"
                  // onChange={handleChangePatio}
                  label={t("modal.mod_input_name")}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  variant="standard"
                  fullWidth
                  style={{ height: "2rem" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <MDInput
                  id="outlined-basic"
                  // onChange={handleChangePatio}
                  label={t("modal.mod_input_phone")}
                  variant="standard"
                  fullWidth
                  style={{ height: "2rem" }}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </FormControl>
            </Grid>
            {props.isSysAdm ? (
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ width: "100%" }}>
                <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                  <InputLabel id="label-porto">{t("modal.mod_select_accounts")}</InputLabel>
                  <Select
                    labelId="label-porto"
                    id="select-porto"
                    style={{ height: "2rem" }}
                    value={accountId}
                    onChange={(e) => {
                      setAccountId(e.target.value);
                    }}
                  >
                    {accountList.map((p) => (
                      <MenuItem key={p.idaccounts} value={p.idaccounts} name={p.nickname}>
                        {p.nickname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : null}
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <MDInput
                  id="outlined-basic"
                  // onChange={handleChangePatio}
                  label={t("modal.mod_input_email")}
                  variant="standard"
                  disabled={props.user?.idusers > 0}
                  fullWidth
                  style={{ height: "2rem" }}
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <MDInput
                  id="outlined-basic"
                  // onChange={handleChangePatio}
                  label={t("modal.mod_input_username")}
                  variant="standard"
                  disabled={props.user?.idusers > 0}
                  fullWidth
                  style={{ height: "2rem" }}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <MDInput
                  id="outlined-basic"
                  // onChange={handleChangePatio}
                  label={t("modal.mod_input_crea")}
                  variant="standard"
                  fullWidth
                  style={{ height: "2rem" }}
                  value={crea}
                  onChange={(e) => setCrea(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <MDInput
                  id="outlined-basic"
                  // onChange={handleChangePatio}
                  label={t("modal.mod_input_cpf")}
                  variant="standard"
                  fullWidth
                  style={{ height: "2rem" }}
                  value={cpf}
                  onChange={(e) => setCpf(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                {console.log(patios)}
                <Autocomplete
                  multiple
                  id="multiple-limit-tags"
                  disableCloseOnSelect
                  options={patios.filter(
                    (patio) =>
                      !selectedPatios.some((selected) => selected.idpatios === patio.idpatios)
                  )}
                  getOptionLabel={(option) => option.name}
                  value={selectedPatios || []}
                  onChange={(event, newValue) => handlePatioSelection(newValue)}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={option.idpatios}
                        label={option.name}
                        {...getTagProps({ index })}
                        style={{ backgroundColor: "#696969", color: "white" }}
                      />
                    ))
                  }
                  renderInput={(params) => <TextField {...params} label="Pátios" />}
                  noOptionsText="Todos os pátios disponíveis foram selecionados!"
                />
              </FormControl>
            </Grid>
            <Grid container style={{ justifyContent: "right", alignItems: "center" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={6} xxl={6} p={1}>
                <Stack direction="row">
                  <FormControl
                    sx={{ p: 1, width: "100%" }}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <MDButton
                      onClick={() => {
                        props.onClose(null);
                      }}
                      sx={{ width: "100%" }}
                      variant="gradient"
                      color="info"
                      style={{ height: "3rem" }}
                    >
                      {t("modal.mod_cancel")}
                    </MDButton>
                  </FormControl>
                  <FormControl
                    sx={{ p: 1, width: "100%" }}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <MDButton
                      onClick={handleSave}
                      sx={{ width: "100%" }}
                      variant="gradient"
                      color="info"
                      style={{ height: "3rem" }}
                    >
                      {t("modal.mod_save")}
                    </MDButton>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Grid>
  );
}
