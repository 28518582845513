import * as React from "react";
// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// I18N
import { useTranslation } from "react-i18next";

import axios from "axios";
import authHeader from "helpers/auth-headers";
import config from "config/config.json";

export default function data(openModalCallBack) {
  const { API_URL } = config;
  // const response = await axios.get(API_URL+"users", { headers: authHeader() })
  // console.log(response.data)
  const { t } = useTranslation("common");
  const [usrList, setUsrList] = React.useState([]);

  const fetchData = async (force) => {
    if (usrList.length === 0 || force) {
      const response = await axios.get(`${API_URL}contatos`, { headers: authHeader() });
      // console.log(response.data);
      setUsrList(
        response.data.map((p) => ({
          name: (
            <MDTypography fontWeight="medium" fontSize="small">
              {p.name}
            </MDTypography>
          ),
          phone: (
            <MDTypography fontWeight="medium" fontSize="small">
              {p.phone}
            </MDTypography>
          ),
          isCarriers: p.isCarriers ? (
            <MDTypography fontWeight="medium" fontSize="small">
              {p.isCarriers.map((carrier) => carrier.name).join(" • ")}
            </MDTypography>
          ) : (
            <span>Nenhum carrier a exibir</span>
          ),
          mail: (
            <MDTypography fontWeight="medium" fontSize="small">
              {p.mail}
            </MDTypography>
          ),
          isPatios: p.isPatios ? (
            <MDTypography fontWeight="medium" fontSize="small">
              {p.isPatios.map((patio) => patio.name).join(" • ")}
            </MDTypography>
          ) : (
            <span>Nenhum pátio a exibir</span>
          ),
          actions: (
            <MDButton fontWeight="medium" fontSize="large" onClick={() => openModalCallBack(p)}>
              <Icon>edit</Icon>
            </MDButton>
          ),
        }))
      );
    }
  };
  fetchData();

  return {
    columns: [
      { Header: t("common.actions"), accessor: "actions", align: "center" },
      { Header: t("table_users.col_name"), accessor: "name", align: "left" },
      { Header: t("table_users.col_phone"), accessor: "phone", align: "left" },
      { Header: t("table_users.col_mail"), accessor: "mail", align: "center" },
      { Header: t("table_users.col_carrier"), accessor: "isCarriers", align: "left" },
      { Header: t("table_users.col_docks"), accessor: "isPatios", align: "left" },
      // { Header: t("table_users.col_account"), accessor: "account", align: "center" },
    ],
    rows: usrList,
    update: fetchData,
  };
}
