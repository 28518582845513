/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import * as React from "react";

// Material Dashboard 2 React components
import FormControl from "@mui/material/FormControl";
import { Box, FormControlLabel, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";

// @mui material components
import Grid from "@mui/material/Grid";

// I18N
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";

export default function ModalHarbor(props) {
  const [porto, setPorto] = React.useState("");

  const { t } = useTranslation("common");

  React.useEffect(() => {
    setPorto(props?.porto);
  }, []);

  const handleChangeData = (field, value) => {
    setPorto({ ...porto, [field]: value });
  };

  return (
    <Grid container xs={12} className="modal-transp">
      <Grid item xs={12} sm={9} md={8} lg={5} xl={4}>
        <MDBox className="card-modal" sx={{ bgcolor: "#ffff" }}>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={3}
            mt={-4}
            p={2}
            mb={3}
            textAlign="center"
            sx={{ width: "90%", bgcolor: "#888" }}
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              <Box>{props.title}</Box>
            </MDTypography>
          </MDBox>
          <MDBox sx={{ mx: 1, px: 1 }}>
            <FormControlLabel
              control={<Switch checked={porto?.isActive} />}
              label={t("modal.mod_switch_active")}
              labelPlacement="start"
              onChange={(e) => handleChangeData("isActive", e.target.checked)}
            />
          </MDBox>
          <Grid container>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <MDInput
                  id="outlined-basic"
                  // onChange={handleChangePatio}
                  value={porto?.name}
                  onChange={(e) => handleChangeData("name", e.target.value)}
                  label={t("modal.mod_input_name")}
                  variant="standard"
                  fullWidth
                  size="string"
                  style={{ height: "2rem" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <MDInput
                  id="outlined-basic"
                  // onChange={handleChangePatio}
                  label={t("modal.mod_legal_name")}
                  value={porto?.legalname}
                  onChange={(e) => handleChangeData("legalname", e.target.value)}
                  variant="standard"
                  fullWidth
                  size="string"
                  style={{ height: "2rem" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <MDInput
                  id="outlined-basic"
                  // onChange={handleChangePatio}
                  label={t("modal.mod_input_address")}
                  value={porto?.address}
                  onChange={(e) => handleChangeData("address", e.target.value)}
                  variant="standard"
                  fullWidth
                  size="string"
                  style={{ height: "2rem" }}
                />
              </FormControl>
            </Grid>
            <Grid container style={{ justifyContent: "right", alignItems: "center" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={6} xxl={6} p={1}>
                <Stack direction="row">
                  <FormControl
                    sx={{ p: 1, width: "100%" }}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <MDButton
                      sx={{ width: "100%" }}
                      variant="gradient"
                      color="info"
                      onClick={() => props.onClose(null)}
                      style={{ height: "3rem" }}
                    >
                      {t("modal.mod_cancel")}
                    </MDButton>
                  </FormControl>
                  <FormControl
                    sx={{ p: 1, width: "100%" }}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <MDButton
                      // onClick={props.onClose}
                      sx={{ width: "100%" }}
                      variant="gradient"
                      color="info"
                      onClick={() => props.onClose(porto)}
                      style={{ height: "3rem" }}
                    >
                      {t("modal.mod_save")}
                    </MDButton>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Grid>
  );
}
