/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import * as React from "react";

// Material Dashboard 2 React components
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { Box, FormControlLabel } from "@mui/material";
import Switch from "@mui/material/Switch";

// I18N
import { useTranslation } from "react-i18next";
import MDInput from "../../components/MDInput";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";

// name: string;
// owner: string;
// isActive: boolean;
// accountsIdaccounts: number;

export default function ModalShip(props) {
  const { t } = useTranslation("common");
  const [ship, setShip] = React.useState("");
  const [owner, setOwner] = React.useState("");
  const [isActive, setIsActive] = React.useState(false);

  console.log("PROPS: MODAL SHIP: ", props);
  React.useEffect(() => {
    setShip(props.ship?.name);
    setOwner(props.ship?.owner);
    setIsActive(props.ship?.isActive === 1);
  }, [props.ship]);

  const handleSave = () => {
    const p = {
      id: props.ship?.idnavios ? props.ship.idnavios : null,
      data: {
        name: ship,
        owner,
        isActive,
        accountsIdaccounts: props.ship?.accountsIdaccounts,
      },
    };
    props.onClose(p);
  };

  return (
    <Grid container xs={12} className="modal-transp">
      <Grid item xs={12} sm={9} md={8} lg={5} xl={4}>
        <MDBox className="card-modal" sx={{ bgcolor: "#ffff" }}>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={3}
            mt={-4}
            p={2}
            mb={3}
            textAlign="center"
            sx={{ width: "90%", bgcolor: "#888" }}
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              <Box>{props.ship === null ? t("modal.mod_new_ship") : props.title}</Box>
            </MDTypography>
          </MDBox>
          <Grid container>
            <Grid item xs={12}>
              <MDBox sx={{ mx: 1, px: 1 }}>
                <FormControlLabel
                  control={
                    <Switch checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />
                  }
                  label={t("modal.mod_switch_active")}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <MDInput
                  id="outlined-basic"
                  // onChange={handleChangeship}
                  label={t("modal.mod_input_ship")}
                  variant="standard"
                  fullWidth
                  size="string"
                  value={ship}
                  onChange={(e) => setShip(e.target.value)}
                  style={{ height: "2rem" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <MDInput
                  id="outlined-basic"
                  // onChange={handleChangeship}
                  label={t("modal.mod_input_owner")}
                  variant="standard"
                  fullWidth
                  size="string"
                  value={owner}
                  onChange={(e) => setOwner(e.target.value)}
                  style={{ height: "2rem" }}
                />
              </FormControl>
            </Grid>
            <Grid container style={{ justifyContent: "right", alignItems: "center" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={6} xxl={6} p={1}>
                <Stack direction="row">
                  <FormControl
                    sx={{ p: 1, width: "100%" }}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <MDButton
                      sx={{ width: "100%" }}
                      variant="gradient"
                      color="info"
                      style={{ height: "3rem" }}
                      onClick={() => props.onClose(null)}
                    >
                      {t("modal.mod_cancel")}
                    </MDButton>
                  </FormControl>
                  <FormControl
                    sx={{ p: 1, width: "100%" }}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <MDButton
                      // onClick={props.onClose}
                      sx={{ width: "100%" }}
                      variant="gradient"
                      color="info"
                      style={{ height: "3rem" }}
                      onClick={handleSave}
                    >
                      {t("modal.mod_save")}
                    </MDButton>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Grid>
  );
}
