/* eslint-disable react/require-default-props */
import * as React from "react";
import Card from "@mui/material/Card";
import PropTypes from "prop-types";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SaveIcon from "@mui/icons-material/Save";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import axios from "axios";
import moment from "moment";
import Icon from "@mui/material/Icon";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";

import { useTranslation } from "react-i18next";

import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import MDBox from "../../components/MDBox";
import SysDashLayout from "../../components_reefer/Layouts/SysDashLayout";
import bgImage from "../../assets/images/fundo001.jpg";
import config from "../../config/config.json";
import authHeader from "../../helpers/auth-headers";
import authService from "../../helpers/auth.service";
import DataTable from "../../components_reefer/Tables/DataTable";
import DataMonitoring from "components_reefer/Tables/DataTable/monitoring";
import ModalShip from "../../components_reefer/Modals/ModalShip";
import ModalTravel from "../../components_reefer/Modals/ModalTravel";
import ModalCarrier from "../../components_reefer/Modals/ModalCarrier";
import ModalMaterial from "../../components_reefer/Modals/ModalMaterial";
import FormatDate from "../../helpers/formatDateHour";

import { ToastContainer, toast } from "react-toastify";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {value === index && <MDBox sx={{ p: 3 }}>{children}</MDBox>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SystemAdjusts() {
  const user = authService.getCurrentUser();
  const { t } = useTranslation("common");
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { API_URL } = config;
  const [patio, setPatio] = React.useState("");
  const [porto, setPorto] = React.useState("");
  const [ship, setShip] = React.useState("");
  const [patioList, setPatioList] = React.useState([]);
  const [portoList, setPortoList] = React.useState([]);
  const [shipList, setShipList] = React.useState([]);
  const [carrierList, setCarrierList] = React.useState([]);
  const [materialList, setMaterialList] = React.useState([]);

  const [travel, setTravel] = React.useState("");
  const [travelDate, setTravelDate] = React.useState(new moment(Date.now()));
  const [isModalShip, setIsModalShip] = React.useState(false);
  const [editShip, setEditShip] = React.useState(null);
  const [editCarrier, setEditCarrier] = React.useState(null);
  const [isModalCarrier, setIsModalCarrier] = React.useState(false);
  const [isModalMaterial, setIsModalMaterial] = React.useState(false);

  const [isModalTravel, setIsModalTravel] = React.useState(false);
  const [editTravel, setEditTravel] = React.useState(null);
  const [returnList, setReturnList] = React.useState([]);
  const [monitList, setMonitList] = React.useState([]);
  const [openMonitConfirm, setOpenMonitConfirm] = React.useState(false);
  const [editMaterial, setEditMaterial] = React.useState(null);

  const [shipListRow, setShipListRow] = React.useState([]);
  const [travelListRow, setTravelListRow] = React.useState([]);
  const [returnListRow, setReturnListRow] = React.useState([]);
  const [monitListRow, setMonitListRow] = React.useState([]);
  const [carrierListRow, setCarrierListRow] = React.useState([]);
  const [materialListRow, setMaterialListRow] = React.useState([]);

  const [containerList, setContainerList] = React.useState([]);
  const [containerListRow, setContainerListRow] = React.useState([]);
  const [openContainerConfirm, setOpenContainerConfirm] = React.useState(false);

  const [maintList, setMaintList] = React.useState([]);
  const [maintListRow, setMaintListRow] = React.useState([]);
  const [openMaintConfirm, setOpenMaintConfirm] = React.useState(false);

  const [currentPage, setCurrentPage] = React.useState(0);

  const shipCols = [
    { Header: t("common.actions"), accessor: "actions", align: "center" },
    { Header: t("table_users.col_name"), accessor: "name", align: "left" },
    { Header: t("modal.mod_input_owner"), accessor: "owner", align: "left" },
    { Header: " ", accessor: " ", align: "left", width: -100 },
  ];
  const travelCols = [
    { Header: t("common.actions"), accessor: "actions", align: "center" },
    { Header: t("adjusts.tab_travel"), accessor: "name", align: "left" },
    { Header: t("common.date"), accessor: "owner", align: "left" },
  ];
  const returnCols = [
    { Header: t("common.return"), accessor: "actions", align: "left" },
    { Header: t("common.ctnr"), accessor: "ctnr", align: "left" },
    { Header: t("common.date"), accessor: "date", align: "left" },
  ];
  const monitCols = [
    { Header: t("common.remove"), accessor: "actions", align: "left" },
    { Header: t("common.ctnr"), accessor: "ctnr", align: "left" },
    { Header: t("common.date"), accessor: "date", align: "left" },
    { Header: t("common.stpoint"), accessor: "setPoint", align: "left" },
    { Header: t("common.temperature"), accessor: "temp", align: "left" },
    { Header: t("common.travel"), accessor: "travel", align: "left" },
  ];
  const carrierCols = [
    { Header: t("common.actions"), accessor: "actions", align: "center" },
    { Header: t("table_users.col_name"), accessor: "name", align: "left" },
    { Header: " ", accessor: " ", align: "left", width: -100 },
  ];

  const materialsCols = [
    { Header: t("common.actions"), accessor: "actions", align: "center" },
    { Header: t("table_users.col_name"), accessor: "name", align: "left" },
    { Header: t("table_users.col_value"), accessor: "value", align: "left" },
    { Header: " ", accessor: " ", align: "left", width: -100 },
  ];

  const containerCols = [
    { Header: t("common.remove"), accessor: "actions", align: "left" },
    { Header: t("common.ctnr"), accessor: "ctnr", align: "left" },
    { Header: t("common.carrier"), accessor: "carrier", align: "left" },
    { Header: t("common.date"), accessor: "date", align: "left" },
    { Header: t("common.temperature"), accessor: "temp", align: "left" },
    { Header: t("common.travel"), accessor: "travel", align: "left" },
  ];

  const maintCols = [
    { Header: t("common.remove"), accessor: "actions", align: "left" },
    { Header: t("common.ctnr"), accessor: "ctnr", align: "left" },
    { Header: t("common.date"), accessor: "date", align: "left" },
    { Header: t("common.size"), accessor: "size", align: "left" },
    { Header: t("common.voltage"), accessor: "voltage", align: "left" },
    { Header: t("common.hh"), accessor: "hh", align: "left" },
    { Header: t("common.manufactory"), accessor: "manufactory", align: "left" },
  ];

  const handleOpenShipModal = (u) => {
    setIsModalShip(true);
    setEditShip(u);
  };

  const handleOpenCarrierModal = (u) => {
    setIsModalCarrier(true);
    setEditCarrier(u);
  };

  const handleOpenTravelModal = (u) => {
    setIsModalTravel(true);
    setEditTravel(u);
  };

  const handleOpenMaterialModal = (u) => {
    setIsModalMaterial(true);
    setEditMaterial(u);
  };

  const handleUpdateShipList = async () => {
    axios.get(`${API_URL}navios/${user.accountsIdaccounts}`, { headers: authHeader() }).then(
      (response) => {
        setShipList(response.data.filter((item) => item.isActive === 1));
        setShipListRow(
          response.data.map((p) => ({
            actions: (
              <MDButton
                color="text"
                fontWeight="medium"
                fontSize="medium"
                onClick={() => handleOpenShipModal(p)}
              >
                <Icon>edit</Icon>
              </MDButton>
            ),
            name: (
              <MDTypography color="text" fontWeight="medium" fontSize="small">
                {p.name}
              </MDTypography>
            ),
            owner: (
              <MDTypography color="text" fontWeight="medium" fontSize="small">
                {p.owner}
              </MDTypography>
            ),
          }))
        );
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleUpdateCarrierList = async () => {
    axios.get(`${API_URL}carriers/${user.accountsIdaccounts}`, { headers: authHeader() }).then(
      (response) => {
        setCarrierList(response.data.filter((item) => item.isActive === 1));
        setCarrierListRow(
          response.data.map((p) => ({
            actions: (
              <MDButton
                fontWeight="medium"
                fontSize="medium"
                onClick={() => handleOpenCarrierModal(p)}
              >
                <Icon>edit</Icon>
              </MDButton>
            ),
            name: (
              <MDTypography color="text" fontWeight="medium" fontSize="small">
                {p.name}
              </MDTypography>
            ),
          }))
        );
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleUpdateMaterialList = async () => {
    axios.get(`${API_URL}materials/${user.accountsIdaccounts}`, { headers: authHeader() }).then(
      (response) => {
        setMaterialList(response.data.filter((item) => item.isActive === 1));
        setMaterialListRow(
          response.data.map((p) => ({
            actions: (
              <MDButton
                fontWeight="medium"
                fontSize="medium"
                onClick={() => handleOpenMaterialModal(p)}
              >
                <Icon>edit</Icon>
              </MDButton>
            ),
            name: (
              <MDTypography color="text" fontWeight="medium" fontSize="small">
                {p.name}
              </MDTypography>
            ),
            value: (
              <MDTypography color="text" fontWeight="medium" fontSize="small">
                {p.value}
              </MDTypography>
            ),
          }))
        );
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleUpdateTravelList = async () => {
    const date = moment(travelDate).format("YYYY-MM-DD");
    axios.get(`${API_URL}movimentacoes/${patio}/${ship}/${date}`, { headers: authHeader() }).then(
      (response) => {
        // console.log(response.data);
        setTravelListRow(
          response.data.map((p) => ({
            name: (
              <MDTypography color="text" fontWeight="medium" fontSize="small">
                {p.travel}
              </MDTypography>
            ),
            owner: (
              <MDTypography color="text" fontWeight="medium" fontSize="small">
                {p.date}
              </MDTypography>
            ),
            actions: (
              <MDButton
                color="text"
                fontWeight="medium"
                fontSize="large"
                onClick={() => handleOpenTravelModal(p)}
              >
                <Icon>edit</Icon>
              </MDButton>
            ),
          }))
        );
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleCheckedReturn = (isSelected, p) => {
    const tmpList = [];
    returnList.forEach((c) => {
      if (c.id === p.id) {
        const tmpC = {};
        tmpC.id = p.id;
        tmpC.isSelected = isSelected;
        tmpC.ctnr = p.ctnr;
        tmpC.date = p.date;
        tmpC.travelId = p.travelId;
        tmpList.push(tmpC);
      } else {
        tmpList.push(c);
      }
    });
    setReturnList(tmpList);
  };

  const handleCheckedMonit = (isSelected, p) => {
    const tmpList = [];
    monitList.forEach((c) => {
      if (c.id === p.id) {
        const tmpC = {};
        tmpC.id = c.id;
        tmpC.isSelected = isSelected;
        tmpC.container = c.container;
        tmpC.read_timestamp = c.read_timestamp;
        tmpC.travel = c.travel;
        tmpC.temperature = c.temperature;
        tmpC.setpoint = c.setpoint;
        tmpList.push(tmpC);
      } else {
        tmpList.push(c);
      }
    });
    setMonitList(tmpList);
  };

  const handleCheckedMaint = (isSelected, p) => {
    const tmpList = [];
    var dataArray = Object.keys(maintList).map(function (key) {
      return maintList[key];
    });
    dataArray.forEach((c) => {
      if (c.id === p.id) {
        const tmpC = {};
        tmpC.id = c.id;
        tmpC.isSelected = isSelected;
        tmpC.isActive = c.isActive;
        tmpC.description = c.description;
        tmpC.execution_date = c.execution_date;
        tmpC.container = c.container;
        tmpC.size = c.size;
        tmpC.voltage = c.voltage;
        tmpC.hh = c.hh;
        tmpC.manufactory = c.manufactory;
        tmpList.push(tmpC);
      } else {
        tmpList.push(c);
      }
    });
    setMaintList(tmpList);
  };

  const handleCheckedContainer = (isSelected, p) => {
    const tmpList = [];

    containerList.forEach((c) => {
      console.log(c);
      if (c.id === p.id) {
        const tmpC = {};
        tmpC.id = c.id;
        tmpC.isSelected = isSelected;
        tmpC.containers_label = c.containers_label;
        tmpC.travel = c.travel;
        tmpC.date_move = c.date_move;
        tmpC.temperature = c.temperature;
        tmpC.carrier = c.carrier;
        tmpList.push(tmpC);
      } else {
        tmpList.push(c);
      }
    });
    setContainerList(tmpList);
  };

  React.useEffect(() => {
    if (returnList.length === 0) {
      return;
    }
    setReturnListRow(
      returnList.map((p) => ({
        ctnr: (
          <MDTypography color="text" fontWeight="medium" fontSize="small">
            {p.ctnr}
          </MDTypography>
        ),
        date: (
          <MDTypography color="text" fontWeight="medium" fontSize="small">
            {moment(p.date).format("YYYY-MM-DD")}
          </MDTypography>
        ),
        actions: (
          <Checkbox
            checked={p.isSelected}
            onChange={(e) => {
              handleCheckedReturn(e.target.checked, p);
            }}
          />
        ),
      }))
    );
  }, [returnList]);

  React.useEffect(() => {
    if (Object.keys(monitList).length === 0) {
      console.log("nada a exibir");
      setMonitListRow([]);
      return;
    }
    setMonitListRow(
      monitList.map((p) => ({
        ctnr: (
          <MDTypography color="text" fontWeight="medium" fontSize="small">
            {p.container}
          </MDTypography>
        ),
        date: (
          <MDTypography color="text" fontWeight="medium" fontSize="small">
            {FormatDate(p.read_timestamp)}
          </MDTypography>
        ),
        temp: (
          <MDTypography color="text" fontWeight="medium" fontSize="small">
            {p.temperature}
          </MDTypography>
        ),
        setPoint: (
          <MDTypography color="text" fontWeight="medium" fontSize="small">
            {p.setpoint}
          </MDTypography>
        ),
        travel: (
          <MDTypography color="text" fontWeight="medium" fontSize="small">
            {p.travel}
          </MDTypography>
        ),
        actions: (
          <Checkbox
            checked={p.isSelected}
            onChange={(e) => {
              handleCheckedMonit(e.target.checked, p);
            }}
          />
        ),
      }))
    );
  }, [monitList]);

  React.useEffect(() => {
    console.log(maintList);
    if (Object.keys(maintList).length === 0) {
      console.log("nada a exibir");
      setMaintListRow([]);
      return;
    }
    var dataArray = Object.keys(maintList).map(function (key) {
      return maintList[key];
    });
    setMaintListRow(
      dataArray.map((p) => ({
        ctnr: (
          <MDTypography color="text" fontWeight="medium" fontSize="small">
            {p.container}
          </MDTypography>
        ),
        date: (
          <MDTypography color="text" fontWeight="medium" fontSize="small">
            {FormatDate(p.execution_date)}
          </MDTypography>
        ),
        size: (
          <MDTypography color="text" fontWeight="medium" fontSize="small">
            {p.size}
          </MDTypography>
        ),
        manufactory: (
          <MDTypography color="text" fontWeight="medium" fontSize="small">
            {p.manufactory}
          </MDTypography>
        ),
        voltage: (
          <MDTypography color="text" fontWeight="medium" fontSize="small">
            {p.voltage}
          </MDTypography>
        ),
        hh: (
          <MDTypography color="text" fontWeight="medium" fontSize="small">
            {p.hh}
          </MDTypography>
        ),
        actions: (
          <Checkbox
            checked={p.isSelected}
            onChange={(e) => {
              handleCheckedMaint(e.target.checked, p);
            }}
          />
        ),
      }))
    );
  }, [maintList]);

  React.useEffect(() => {
    if (Object.keys(containerList).length === 0) {
      console.log("nenhum container");
      setContainerListRow([]);
      return;
    }
    console.log(containerList);
    setContainerListRow(
      containerList.map((p) => ({
        ctnr: (
          <MDTypography color="text" fontWeight="medium" fontSize="small">
            {p.containers_label}
          </MDTypography>
        ),
        carrier: (
          <MDTypography color="text" fontWeight="medium" fontSize="small">
            {p.carrier}
          </MDTypography>
        ),
        temp: (
          <MDTypography color="text" fontWeight="medium" fontSize="small">
            {p.temperature}
          </MDTypography>
        ),
        date: (
          <MDTypography color="text" fontWeight="medium" fontSize="small">
            {FormatDate(p.date_move)}
          </MDTypography>
        ),
        travel: (
          <MDTypography color="text" fontWeight="medium" fontSize="small">
            {p.travel}
          </MDTypography>
        ),
        actions: (
          <Checkbox
            checked={p.isSelected}
            onChange={(e) => {
              handleCheckedContainer(e.target.checked, p);
            }}
          />
        ),
      }))
    );
  }, [containerList]);

  const handleUpdateReturnList = async () => {
    await axios
      .get(`${API_URL}movimentacoes-has-containers/${patio}/${ship}/${travel}`, {
        headers: authHeader(),
      })
      .then(
        (response) => {
          if (response.data.length === 0) {
            setReturnListRow([]);
            setReturnList([]);
            return;
          }
          setReturnList(
            response.data.map((p) => ({
              id: p.id,
              isSelected: false,
              ctnr: p.containers_label,
              date: p.date_move,
              travelId: p.id_mov,
            }))
          );
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleUpdateMonitList = async () => {
    if (patio > 0) {
      const date = moment(travelDate).format("DD/MM/YYYY");
      const dataParam = {
        init: date,
        end: date,
        patio,
        isErrOnly: false,
        isPlain: true,
        isMaintenance: false,
        isQueries: false,
        isPdf: false,
      };
      setMonitListRow([]);
      const r = await axios.get(`${API_URL}reports`, { params: dataParam });
      // console.log(r.data);
      if (r.data.length === 0) {
        setMonitList([]);
        return;
      }
      setMonitList(r.data);
    }
  };

  const handleUpdateContainerList = async () => {
    setCurrentPage(0);
    if (patio > 0) {
      setContainerListRow([]);
      const r = await axios.get(`${API_URL}agenda/${patio}`, { headers: authHeader() });
      // console.log(r.data);
      if (r.data.length === 0) {
        setContainerList([]);
        toast.warning(t("alerts.none"));
        return;
      }
      setContainerList(r.data);
    }
  };

  const handleUpdateMaintList = async () => {
    if (patio > 0) {
      const date = moment(travelDate).format("DD/MM/YYYY");
      const dataParam = {
        init: date,
        end: date,
        patio,
        isErrOnly: false,
        isPlain: false,
        isMaintenance: true,
        isQueries: false,
      };
      setMaintListRow([]);
      const r = await axios.get(`${API_URL}reports/`, { params: dataParam });
      console.log(r.data);
      if (r.data.length === 0) {
        setMaintList([]);
        return;
      }
      setMaintList(r.data);
    }
  };

  const handleSaveReturn = async () => {
    const tmpList = [];
    returnList.forEach((c) => {
      if (c.isSelected) {
        tmpList.push(c);
      }
    });
    if (tmpList.length === 0) {
      return;
    }
    await axios.put(`${API_URL}movimentacoes-has-containers/${tmpList[0].travelId}`, tmpList, {
      headers: authHeader(),
    });
    handleUpdateReturnList();
    // props.onClose(tmpList);
  };

  const handleSaveMonit = async () => {
    monitList.forEach(async (c) => {
      if (c.isSelected) {
        await axios.delete(`${API_URL}monitoring/${c.id}`, {
          headers: authHeader(),
        });
      }
    });
    handleUpdateMonitList();
    setOpenMonitConfirm(false);
  };

  const handleSaveMaint = async () => {
    var dataArray = Object.keys(maintList).map(function (key) {
      return maintList[key];
    });
    console.log(dataArray);
    dataArray.forEach(async (m) => {
      console.log(m);
      try {
        if (m.isSelected) {
          await axios.put(
            `${API_URL}manutencoes/${m.id}`,
            { isActive: false },
            {
              headers: authHeader(),
            }
          );
          setMaintListRow([]);
          setPatio("");
        } else {
          console.log("nenhum container selecionado");
        }
      } catch (error) {
        toast.error(t("alerts.error_save"));
        console.log(error);
      }
      setOpenMaintConfirm(false);
    });
    // handleUpdateMaintList();

    toast.success(t("alerts.cont_delete_success"));
  };

  const handleSaveContainer = async () => {
    const validation = containerList.filter((item) => item.isSelected === true);
    if (validation.length < 1) {
      setOpenContainerConfirm(false);
      toast.error(t("alerts.cont_none"));
      return;
    }
    containerList.forEach(async (c) => {
      try {
        if (c.isSelected) {
          console.log(c);

          await axios.post(
            `${API_URL}agenda`,
            {
              id: c.id,
            },
            { headers: authHeader() }
          );

          setContainerListRow([]);
          setPatio("");
        } else {
          console.log("nenhum container selecionado");
        }
      } catch (error) {
        toast.error(t("alerts.error_save"));
        console.log(error);
      }

      setOpenContainerConfirm(false);
    });
    toast.success(t("alerts.cont_delete_success"));
  };

  const handleOpenMonitConfirm = () => {
    setOpenMonitConfirm(true);
  };

  const handleOpenContainerConfirm = () => {
    setOpenContainerConfirm(true);
  };

  const handleCloseMonitConfirm = () => {
    setOpenMonitConfirm(false);
  };

  const handleCloseContainerConfirm = () => {
    setOpenContainerConfirm(false);
  };

  const handleOpenMaintConfirm = () => {
    setOpenMaintConfirm(true);
  };

  const handleCloseMaintConfirm = () => {
    setOpenMaintConfirm(false);
  };

  const handleCloseShipModal = async (u) => {
    if (u === null) {
      setIsModalShip(false);
      setEditShip(null);
      return;
    }
    if (u.id !== null) {
      await axios.put(`${API_URL}navios/${u.id}`, u.data, { headers: authHeader() });
    } else {
      const data = {
        name: u.data.name,
        owner: u.data.owner,
        isActive: u.data.isActive,
        accountsIdaccounts: user.accountsIdaccounts,
      };
      await axios.post(`${API_URL}navios`, data, { headers: authHeader() });
    }
    handleUpdateShipList();
    setIsModalShip(false);
    setEditShip(null);
  };
  const handleCloseCarrierModal = async (u) => {
    console.log("DATA", u);
    if (u === null) {
      setIsModalCarrier(false);
      setEditCarrier(null);
      return;
    }
    if (u.id !== null) {
      console.log(u);
      if (u.data.name === "") {
        console.log("nome vazio");
        toast.warning(t("alerts.fill_fields"));
      } else {
        await axios.put(`${API_URL}carriers/${u.id}`, u.data, { headers: authHeader() });
      }
    } else {
      const data = {
        name: u.data.name,
        isActive: u.data.isActive,
        accountsIdaccounts: user.accountsIdaccounts,
      };
      await axios.post(`${API_URL}carriers`, data, { headers: authHeader() });
    }
    handleUpdateCarrierList();
    setIsModalCarrier(false);
    setEditCarrier(null);
  };
  const handleCloseMaterialModal = async (u) => {
    console.log("DATA", u);
    if (u === null) {
      setIsModalMaterial(false);
      setEditMaterial(null);
      return;
    }
    if (u.id !== null) {
      await axios.put(`${API_URL}materials/${u.id}`, u.data, { headers: authHeader() });
    } else {
      const data = {
        name: u.data.name,
        value: u.data.value,
        isActive: u.data.isActive,
        accountsIdaccounts: user.accountsIdaccounts,
      };
      await axios.post(`${API_URL}materials`, data, { headers: authHeader() });
    }
    handleUpdateMaterialList();
    setIsModalMaterial(false);
    setEditMaterial(null);
  };
  const handleCloseTravelModal = async (u) => {
    if (u !== null) {
      const date = moment(u.data.date).format("YYYY-MM-DD");
      const data = {
        travel: u.data.travel,
        date,
        isActive: u.data.isActive,
        naviosIdnavios: u.data.naviosIdnavios,
        portosIdportos: u.data.portosIdportos,
      };
      await axios.put(`${API_URL}movimentacoes/${u.id}`, data, { headers: authHeader() });
    }
    handleUpdateTravelList();
    setIsModalTravel(false);
    setEditTravel(null);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      if (portoList.length === 0) {
        axios
          .get(`${API_URL}portos/${user.accountsIdaccounts}`, {}, { headers: authHeader() })
          .then(
            (response) => {
              // console.log(response.data);
              setPortoList(response.data);
              if (response.data.length === 1) {
                setPorto(response.data[0]);
                // console.log(porto);
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }
      if (shipList.length === 0) {
        axios.get(`${API_URL}navios/${user.accountsIdaccounts}`, { headers: authHeader() }).then(
          (response) => {
            setShipList(response.data.filter((item) => item.isActive === 1));
            setShipListRow(
              response.data.map((p) => ({
                actions: (
                  <MDButton
                    fontWeight="medium"
                    fontSize="large"
                    onClick={() => handleOpenShipModal(p)}
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                ),
                name: (
                  <MDTypography color="text" fontWeight="medium" fontSize="small">
                    {p.name}
                  </MDTypography>
                ),
                owner: (
                  <MDTypography color="text" fontWeight="medium" fontSize="small">
                    {p.owner}
                  </MDTypography>
                ),
              }))
            );
          },
          (error) => {
            console.log(error);
          }
        );
      }
      if (carrierList.length === 0) {
        axios.get(`${API_URL}carriers/${user.accountsIdaccounts}`, { headers: authHeader() }).then(
          (response) => {
            setCarrierList(response.data.filter((item) => item.isActive === 1));
            setCarrierListRow(
              response.data.map((p) => ({
                actions: (
                  <MDButton
                    fontWeight="medium"
                    fontSize="large"
                    onClick={() => handleOpenCarrierModal(p)}
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                ),
                name: (
                  <MDTypography color="text" fontWeight="medium" fontSize="small">
                    {p.name}
                  </MDTypography>
                ),
              }))
            );
          },
          (error) => {
            console.log(error);
          }
        );
      }
      if (materialList.length === 0) {
        axios.get(`${API_URL}materials/${user.accountsIdaccounts}`, { headers: authHeader() }).then(
          (response) => {
            setMaterialList(response.data.filter((item) => item.isActive === 1));
            setMaterialListRow(
              response.data.map((p) => ({
                actions: (
                  <MDButton
                    fontWeight="medium"
                    fontSize="large"
                    onClick={() => handleOpenMaterialModal(p)}
                  >
                    <Icon>edit</Icon>
                  </MDButton>
                ),
                name: (
                  <MDTypography color="text" fontWeight="medium" fontSize="small">
                    {p.name}
                  </MDTypography>
                ),
                value: (
                  <MDTypography color="text" fontWeight="medium" fontSize="small">
                    {p.value}
                  </MDTypography>
                ),
              }))
            );
          },
          (error) => {
            console.log(error);
          }
        );
      }
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      if (porto?.idportos) {
        // console.log(porto);
        axios.get(`${API_URL}patios/${porto.idportos}`, { headers: authHeader() }).then(
          (response) => {
            setPatioList(response.data);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    };
    fetchData();
  }, [porto]);

  const handleChangePorto = (event) => {
    // console.log(event.target)
    setPorto({ idportos: event.target.value, name: event.target.name });
  };

  const handleChangePatio = (event) => {
    setPatio(event.target.value);
  };

  const handleChangeTravel = (event) => {
    setTravel(event.target.value);
  };

  const handleChangeShip = (event) => {
    setShip(event.target.value);
  };

  const handleChangeTravelDate = (newValue) => {
    // console.log(newValue)
    setTravelDate(newValue);
  };

  const handleCurrentPage = (newPageIndex) => {
    setCurrentPage(newPageIndex); // Certifique-se de que setCurrentPage é uma função válida
  };

  return (
    <SysDashLayout image={bgImage}>
      <ToastContainer />
      <Card>
        <MDBox
          variant="gradient"
          width="100%"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {t("menu.adj")}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3} sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label={t("adjusts.tab_return")} {...a11yProps(0)} />
            <Tab label={t("adjusts.tab_travel")} {...a11yProps(1)} />
            <Tab label={t("adjusts.tab_ship")} {...a11yProps(2)} />
            <Tab label={t("adjusts.tab_monitoring")} {...a11yProps(3)} />
            <Tab label={t("adjusts.tab_carrier")} {...a11yProps(4)} />
            <Tab label={t("adjusts.tab_materials")} {...a11yProps(5)} />
            <Tab label={t("adjusts.tab_containers")} {...a11yProps(6)} />
            <Tab label={t("adjusts.tab_maintenance")} {...a11yProps(7)} />
          </Tabs>
        </MDBox>
        {/* START RETURN */}
        {console.log(returnList)}
        <TabPanel value={value} index={0}>
          <MDBox pt={4} pb={3} px={2}>
            <Grid container spacing={1} alignItems="flex-end">
              {portoList.length > 1 ? (
                <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="label-porto">{t("operational.harbor_select")}</InputLabel>
                    <Select
                      labelId="label-porto"
                      id="select-porto"
                      // value={porto.idportos}
                      onChange={handleChangePorto}
                      style={{ height: "3rem" }}
                    >
                      {portoList.map((p) => (
                        <MenuItem key={p.idportos} value={p.idportos}>
                          {p.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
              <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="label-patio">{t("operational.dock_select")}</InputLabel>
                  <Select
                    labelId="label-patio"
                    id="select-patio"
                    // value={patio}
                    onChange={handleChangePatio}
                    style={{ height: "3rem" }}
                    fullWidth
                  >
                    {patioList.map((p) => (
                      <MenuItem key={p.idpatios} value={p.idpatios}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="label-ship">{t("operational.ship_select")}</InputLabel>
                  <Select
                    labelId="label-ship"
                    id="select-ship"
                    // value={ship.idnavios}
                    onChange={handleChangeShip}
                    style={{ height: "3rem" }}
                    fullWidth
                  >
                    {shipList.map((p) => (
                      <MenuItem key={p.idnavios} value={p.idnavios}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                <TextField
                  id="outlined-basic"
                  onChange={handleChangeTravel}
                  style={{ height: "3rem" }}
                  label={t("adjusts.tab_travel")}
                  variant="standard"
                  fullWidth
                />
              </Grid>
              <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                <MDButton variant="gradient" color="info" m={2} onClick={handleUpdateReturnList}>
                  {t("common.search")}
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox pt={4} pb={3} px={2}>
            <DataTable
              currentPage={handleCurrentPage}
              numberPage={currentPage}
              table={{ columns: returnCols, rows: returnListRow }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
          <MDBox
            mt={2}
            width="100%"
            justifyContent="flex-end"
            alignItems="center"
            sx={{ display: "flex" }}
          >
            <MDButton
              variant="gradient"
              color="info"
              size="large"
              mx={3}
              endIcon={<SaveIcon />}
              onClick={handleSaveReturn}
            >
              {t("common.save")}
            </MDButton>
          </MDBox>
        </TabPanel>
        {/* END RETURN */}
        {/* START TRAVEL */}
        <TabPanel value={value} index={1}>
          <MDBox pt={4} pb={3} px={2}>
            <Grid container spacing={1} alignItems="flex-end">
              {portoList.length > 1 ? (
                <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="label-porto">{t("operational.harbor_select")}</InputLabel>
                    <Select
                      labelId="label-porto"
                      id="select-porto"
                      // value={porto.idportos}
                      onChange={handleChangePorto}
                      style={{ height: "3rem" }}
                    >
                      {portoList.map((p) => (
                        <MenuItem key={p.idportos} value={p.idportos}>
                          {p.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
              <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="label-patio">{t("operational.dock_select")}</InputLabel>
                  <Select
                    labelId="label-patio"
                    id="select-patio"
                    // value={patio.idpatios}
                    onChange={handleChangePatio}
                    style={{ height: "3rem" }}
                    fullWidth
                  >
                    {patioList.map((p) => (
                      <MenuItem key={p.idpatios} value={p.idpatios}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="label-ship">{t("operational.ship_select")}</InputLabel>
                  <Select
                    labelId="label-ship"
                    id="select-ship"
                    // value={ship.idnavios}
                    onChange={handleChangeShip}
                    style={{ height: "3rem" }}
                    fullWidth
                  >
                    {shipList.map((p) => (
                      <MenuItem key={p.idnavios} value={p.idnavios}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                <DesktopDatePicker
                  label={t("operational.initial_date")}
                  inputFormat="DD/MM/yyyy"
                  variant="standard"
                  style={{ height: "3rem" }}
                  value={travelDate}
                  onChange={handleChangeTravelDate}
                  renderInput={(params) => <TextField variant="standard" {...params} />}
                />
              </Grid>
              <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                <MDButton variant="gradient" color="info" m={2} onClick={handleUpdateTravelList}>
                  {t("common.search")}
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox pt={4} pb={3} px={2}>
            <DataTable
              currentPage={handleCurrentPage}
              numberPage={currentPage}
              table={{ columns: travelCols, rows: travelListRow }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
          {isModalTravel ? (
            <ModalTravel
              title={t("modal.mod_edit_travel")}
              onClose={handleCloseTravelModal}
              travel={editTravel}
            />
          ) : null}
        </TabPanel>
        {/* END TRAVEL */}
        {/* START SHIP */}
        <TabPanel value={value} index={2}>
          <MDBox pt={2} pb={3} px={2}>
            <DataTable
              currentPage={handleCurrentPage}
              numberPage={currentPage}
              table={{ columns: shipCols, rows: shipListRow }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
          {isModalShip ? (
            <ModalShip
              title={t("modal.mod_edit_ship")}
              onClose={handleCloseShipModal}
              ship={editShip}
            />
          ) : null}
          <Grid container style={{ justifyContent: "end", alignItems: "center" }}>
            <Grid item mr={5}>
              <Stack direction="row">
                <FormControl
                  sx={{ p: 1, width: "100%" }}
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <MDButton
                    onClick={() => handleOpenShipModal(null)}
                    sx={{ width: "100%" }}
                    variant="gradient"
                    color="info"
                    style={{ height: "3rem" }}
                  >
                    {t("modal.mod_new")}
                  </MDButton>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        {/* END SHIP */}
        {/* START CARRIER */}
        <TabPanel value={value} index={4}>
          <MDBox pt={2} pb={3} px={2}>
            <DataTable
              currentPage={handleCurrentPage}
              numberPage={currentPage}
              table={{ columns: carrierCols, rows: carrierListRow }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
          {isModalCarrier ? (
            <ModalCarrier
              title={t("modal.mod_edit_carrier")}
              onClose={handleCloseCarrierModal}
              carrier={editCarrier}
            />
          ) : null}
          <Grid container style={{ justifyContent: "end", alignItems: "center" }}>
            <Grid item mr={5}>
              <Stack direction="row">
                <FormControl
                  sx={{ p: 1, width: "100%" }}
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <MDButton
                    onClick={() => handleOpenCarrierModal(null)}
                    sx={{ width: "100%" }}
                    variant="gradient"
                    color="info"
                    style={{ height: "3rem" }}
                  >
                    {t("modal.mod_new")}
                  </MDButton>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        {/* END CARRIER */}
        {/* START MONITORING */}
        <TabPanel value={value} index={3}>
          <MDBox pt={4} pb={3} px={2}>
            <Grid container spacing={1} alignItems="flex-end">
              {portoList.length > 1 ? (
                <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="label-porto">{t("operational.harbor_select")}</InputLabel>
                    <Select
                      labelId="label-porto"
                      id="select-porto"
                      // value={porto.idportos}
                      onChange={handleChangePorto}
                      style={{ height: "3rem" }}
                    >
                      {portoList.map((p) => (
                        <MenuItem key={p.idportos} value={p.idportos}>
                          {p.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
              <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="label-patio">{t("operational.dock_select")}</InputLabel>
                  <Select
                    labelId="label-patio"
                    id="select-patio"
                    // value={patio.idpatios}
                    onChange={handleChangePatio}
                    style={{ height: "3rem" }}
                    fullWidth
                  >
                    {patioList.map((p) => (
                      <MenuItem key={p.idpatios} value={p.idpatios}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                <DesktopDatePicker
                  label={t("operational.retro_date")}
                  inputFormat="DD/MM/yyyy"
                  variant="standard"
                  style={{ height: "3rem" }}
                  value={travelDate}
                  onChange={handleChangeTravelDate}
                  renderInput={(params) => <TextField variant="standard" {...params} />}
                />
              </Grid>
              <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                <MDButton variant="gradient" color="info" m={2} onClick={handleUpdateMonitList}>
                  {t("common.search")}
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox pt={4} pb={3} px={2}>
            <DataMonitoring
              currentPage={handleCurrentPage}
              numberPage={currentPage}
              table={{ columns: monitCols, rows: monitListRow }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
          <MDBox
            pt={4}
            pb={3}
            px={3}
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              width: "100%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <MDButton variant="gradient" color="info" m={1} onClick={handleOpenMonitConfirm}>
              {t("common.remove")}
            </MDButton>
          </MDBox>
          <Dialog
            open={openMonitConfirm}
            // onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{t("common.delete")}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("common.delete_text")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseMonitConfirm}>Cancelar</Button>
              <Button onClick={handleSaveMonit} autoFocus>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </TabPanel>
        {/* END MONITORING */}
        {/* START MATERIALS */}
        <TabPanel value={value} index={5}>
          <MDBox pt={2} pb={3} px={2}>
            <DataTable
              currentPage={handleCurrentPage}
              numberPage={currentPage}
              table={{ columns: materialsCols, rows: materialListRow }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
          {isModalMaterial ? (
            <ModalMaterial
              title={t("modal.mod_edit_material")}
              onClose={handleCloseMaterialModal}
              material={editMaterial}
            />
          ) : null}
          <Grid container style={{ justifyContent: "end", alignItems: "center" }}>
            <Grid item mr={5}>
              <Stack direction="row">
                <FormControl
                  sx={{ p: 1, width: "100%" }}
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <MDButton
                    onClick={() => handleOpenMaterialModal(null)}
                    sx={{ width: "100%" }}
                    variant="gradient"
                    color="info"
                    style={{ height: "3rem" }}
                  >
                    {t("modal.mod_new")}
                  </MDButton>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
        </TabPanel>
        {/* END MATERIALS */}
        {/* START CONTAINERS */}
        <TabPanel value={value} index={6}>
          <MDBox pt={4} pb={3} px={2}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="label-patio">{t("operational.dock_select")}</InputLabel>
                  <Select
                    labelId="label-patio"
                    id="select-patio"
                    value={patio}
                    onChange={handleChangePatio}
                    style={{ height: "3rem" }}
                    fullWidth
                  >
                    {patioList.map((p) => (
                      <MenuItem key={p.idpatios} value={p.idpatios}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                <MDButton variant="gradient" color="info" m={2} onClick={handleUpdateContainerList}>
                  {t("common.search")}
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox pt={4} pb={3} px={2}>
            <DataTable
              currentPage={handleCurrentPage}
              numberPage={currentPage}
              table={{ columns: containerCols, rows: containerListRow }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
          <MDBox
            pt={4}
            pb={3}
            px={3}
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              width: "100%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <MDButton variant="gradient" color="info" m={1} onClick={handleOpenContainerConfirm}>
              {t("common.remove")}
            </MDButton>
          </MDBox>
          <Dialog
            open={openContainerConfirm}
            // onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{t("common.delete")}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("common.delete_text")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseContainerConfirm}>{t("common.cancel")}</Button>
              <Button onClick={handleSaveContainer} autoFocus>
                {t("common.agree")}
              </Button>
            </DialogActions>
          </Dialog>
        </TabPanel>
        {/* END CONTAINERS */}
        {/* START MAINTENANCE */}
        <TabPanel value={value} index={7}>
          <MDBox pt={4} pb={3} px={2}>
            <Grid container spacing={1} alignItems="flex-end">
              {portoList.length > 1 ? (
                <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="label-porto">{t("operational.harbor_select")}</InputLabel>
                    <Select
                      labelId="label-porto"
                      id="select-porto"
                      // value={porto.idportos}
                      onChange={handleChangePorto}
                      style={{ height: "3rem" }}
                    >
                      {portoList.map((p) => (
                        <MenuItem key={p.idportos} value={p.idportos}>
                          {p.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
              <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel id="label-patio">{t("operational.dock_select")}</InputLabel>
                  <Select
                    labelId="label-patio"
                    id="select-patio"
                    value={patio}
                    onChange={handleChangePatio}
                    style={{ height: "3rem" }}
                    fullWidth
                  >
                    {patioList.map((p) => (
                      <MenuItem key={p.idpatios} value={p.idpatios}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                <DesktopDatePicker
                  label={t("operational.retro_date")}
                  inputFormat="DD/MM/yyyy"
                  variant="standard"
                  style={{ height: "3rem" }}
                  value={travelDate}
                  onChange={handleChangeTravelDate}
                  renderInput={(params) => <TextField variant="standard" {...params} />}
                />
              </Grid>
              <Grid item xs={11} sm={11} md={3} lg={3} xl={3}>
                <MDButton variant="gradient" color="info" m={2} onClick={handleUpdateMaintList}>
                  {t("common.search")}
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
          <MDBox pt={4} pb={3} px={2}>
            <DataTable
              currentPage={handleCurrentPage}
              numberPage={currentPage}
              table={{ columns: maintCols, rows: maintListRow }}
              isSorted={false}
              entriesPerPage={false}
              showTotalEntries={false}
              noEndBorder
            />
          </MDBox>
          <MDBox
            pt={4}
            pb={3}
            px={3}
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              width: "100%",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <MDButton variant="gradient" color="info" m={1} onClick={handleOpenMaintConfirm}>
              {t("common.remove")}
            </MDButton>
          </MDBox>
          <Dialog
            open={openMaintConfirm}
            // onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{t("common.delete")}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("common.delete_text")}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseMaintConfirm}>Cancelar</Button>
              <Button onClick={handleSaveMaint} autoFocus>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </TabPanel>
        {/* END MAINTENANCE */}
      </Card>
    </SysDashLayout>
  );
}

export default SystemAdjusts;
