/* eslint-disable react/jsx-key */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import * as React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Alert, IconButton } from "@mui/material";
import authHeader from "../../helpers/auth-headers";
import config from "../../config/config.json";
import SysDashLayout from "../../components_reefer/Layouts/SysDashLayout";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import authService from "../../helpers/auth.service";
import Report from "../../components_reefer/Report/Report";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <MDBox sx={{ p: 3 }}>
          <MDTypography>{children}</MDTypography>
        </MDBox>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function SystemQueries() {
  const yearList = [];
  for (let i = 2021; i <= new Date().getFullYear(); i += 1) {
    yearList.push(i);
  }

  const [patio, setPatio] = React.useState("");
  const [porto, setPorto] = React.useState("");
  const [patioList, setPatioList] = React.useState([]);
  const [portoList, setPortoList] = React.useState([]);
  const [reportData, setReportData] = React.useState({});
  const [reportDataKeys, setReportDataKeys] = React.useState([]);
  const [reportPeriod, setReportPeriod] = React.useState([]);
  // const [valueMonit, setValueMonit] = React.useState([]);
  const [alertError, setAlertError] = React.useState([]);
  const [maintenanceType, setMaintenanceType] = React.useState([]);
  const [consigList, setConsigList] = React.useState([]);
  const [consig, setConsig] = React.useState([]);
  const [queriesContainer, setQueriesContainer] = React.useState([]);
  const [queriesTravel, setQueriesTravel] = React.useState([]);
  const [queriesShip, setQueriesShip] = React.useState([]);
  const [typeReport, setTypeReport] = React.useState([]);

  const [loading, setLoading] = React.useState(false);
  // const [queriesConsig, setQueriesConsig] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const user = authService.getCurrentUser();

  const { t } = useTranslation("common");

  const handleChange = (event, newValue) => {
    setReportData({});
    setReportDataKeys([]);
    setAlertError("");
    setValue(newValue);
  };

  const [initDate, setInitDate] = React.useState(moment().format("01/MM/YYYY").toString());
  const [endDate, setEndDate] = React.useState(moment().format("DD/MM/YYYY").toString());

  const { API_URL } = config;

  console.log("consig", consig);

  React.useEffect(async () => {
    const fetchData = async () => {
      if (portoList.length === 0) {
        axios.get(`${API_URL}portos/${user.accountsIdaccounts}`, { headers: authHeader() }).then(
          (response) => {
            setPortoList(response.data);
            if (response.data.length === 1) {
              setPorto(response.data[0]);
              //  console.log(porto)
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    };
    await fetchData();
  }, []);

  React.useEffect(() => {
    const fetchMaintType = async () => {
      if (maintenanceType.length === 0) {
        axios.get(`${API_URL}maintenance-type/`, { headers: authHeader() }).then(
          (response) => {
            setMaintenanceType(response.data);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    };
    fetchMaintType();
  }, [maintenanceType]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (porto.idportos > 0) {
        axios.get(`${API_URL}patios/${porto.idportos}`, { headers: authHeader() }).then(
          (response) => {
            setPatioList(response.data);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    };
    fetchData();
  }, [porto]);

  React.useEffect(() => {
    const fetchPeriod = async () => {
      const tmpData = [];
      if (reportPeriod.length === 0) {
        await axios
          .get(`${API_URL}accounts/${user.accountsIdaccounts}`, { headers: authHeader() })
          .then(
            (response) => {
              tmpData.push(response.data);
            },
            (error) => {
              console.log(error);
            }
          );
        setReportPeriod(tmpData);
      }
    };
    fetchPeriod();
  }, []);

  React.useEffect(() => {
    const fetchConsig = async () => {
      if (patioList.length > 0) {
        axios.get(`${API_URL}agenda/${patio}`, { headers: authHeader() }).then(
          (response) => {
            const uniqueConsignees = [];
            const seenConsignees = new Set();

            for (const item of response.data) {
              if (!seenConsignees.has(item.consig)) {
                seenConsignees.add(item.consig);
                uniqueConsignees.push(item);
              }
            }

            // Ordenar os itens em ordem alfabética
            uniqueConsignees.sort((a, b) => a.consig.localeCompare(b.consig));

            setConsigList(uniqueConsignees);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    };
    fetchConsig();
  }, [patio]);

  const requestReportContainer = async () => {
    if (patio > 0) {
      setTypeReport("");
      setReportData({});
      setReportDataKeys([]);
      setAlertError("");
      setTypeReport("container");
      const dataParam = {
        init: initDate,
        end: endDate,
        patio,
        isErrOnly: false,
        isMaintenance: false,
        isQueries: true,
        isContainer: queriesContainer,
        isPdf: false,
      };
      const r = await axios.get(`${API_URL}reports`, { params: dataParam });
      setReportData(r.data);
      setReportDataKeys(Object.keys(r.data));
      console.log("Parametros", dataParam);
    } else {
      setAlertError(2);
    }
  };

  const requestReportTravel = async () => {
    if (patio > 0) {
      setTypeReport("");
      setReportData({});
      setReportDataKeys([]);
      setAlertError("");
      setTypeReport("travel");
      const dataParam = {
        init: initDate,
        end: endDate,
        patio,
        isErrOnly: false,
        isMaintenance: false,
        isQueries: true,
        isTravel: queriesTravel,
        isPdf: false,
      };
      const r = await axios.get(`${API_URL}reports`, { params: dataParam });
      setReportData(r.data);
      setReportDataKeys(Object.keys(r.data));
      console.log("Parametros", dataParam);
    } else {
      setAlertError(2);
    }
  };

  const requestReportShip = async () => {
    if (patio > 0) {
      setTypeReport("");
      setReportData({});
      setReportDataKeys([]);
      setAlertError("");
      setTypeReport("ship");
      const dataParam = {
        init: initDate,
        end: endDate,
        patio,
        isErrOnly: false,
        isMaintenance: false,
        isQueries: true,
        isShip: queriesShip,
        isPdf: false,
      };
      const r = await axios.get(`${API_URL}reports`, { params: dataParam });
      setReportData(r.data);
      setReportDataKeys(Object.keys(r.data));
      console.log("Parametros", dataParam);
    } else {
      setAlertError(2);
    }
  };

  const requestReportConsig = async () => {
    setLoading(true);
    if (patio > 0) {
      setTypeReport("");
      setReportData({});
      setReportDataKeys([]);
      setAlertError("");
      setTypeReport("consig");
      const consigFind = consigList.find((p) => p.id === consig).consig;
      const dataParam = {
        init: initDate,
        end: endDate,
        patio,
        isErrOnly: false,
        isMaintenance: false,
        isQueries: true,
        isConsig: consigFind,
        isPdf: false,
      };
      console.log("PARAMETROS CONSIG", dataParam);
      const r = await axios.get(`${API_URL}reports`, { params: dataParam });
      setReportData(r.data);
      setReportDataKeys(Object.keys(r.data));
      console.log(consigList);
      console.log("Parametros", dataParam);
      setLoading(false);
    } else {
      setLoading(false);
      setAlertError(2);
    }
  };
  const handleChangePorto = (event) => {
    setPorto({ idportos: event.target.value, name: event.target.name });
  };

  const handleChangePatio = (event) => {
    setPatio("");
    setPatio(event.target.value);
    setReportData({});
    setReportDataKeys([]);
  };
  const handleChangeQuerieContainer = (event) => {
    setQueriesContainer(event.target.value);
    setReportData({});
    setReportDataKeys([]);
  };

  const handleChangeQuerieTravel = (event) => {
    setQueriesTravel(event.target.value);
    setReportData({});
    setReportDataKeys([]);
  };
  const handleChangeQuerieShip = (event) => {
    setQueriesShip(event.target.value);
    setReportData({});
    setReportDataKeys([]);
  };

  // const handleChangeQuerieConsig = (event) => {
  //   setQueriesConsig(event.target.value);
  //   setReportData({});
  //   setReportDataKeys([]);
  // };

  const handleChangeConsig = (event) => {
    setConsig(event.target.value);
    setReportData({});
    setReportDataKeys([]);
  };

  return (
    <SysDashLayout>
      {/* <Card style={{ justifyContent: "center", alignItems: "center" }}> */}
      <Card p={1}>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {t("menu.queries")}
          </MDTypography>
        </MDBox>

        <MDBox pt={4} pb={3} px={3} sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label={t("queries.tab_container")} {...a11yProps(0)} />
            <Tab label={t("queries.tab_travel")} {...a11yProps(1)} />
            <Tab label={t("queries.tab_ship")} {...a11yProps(2)} />
            <Tab label={t("queries.tab_consig")} {...a11yProps(3)} />
          </Tabs>
        </MDBox>

        {/* ###### START CONTAINER SESSION ######## */}
        <Box m={5} sx={{ width: "95%" }}>
          <TabPanel value={value} index={0}>
            <Grid container spacing={1}>
              {portoList.length > 1 ? (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={1}>
                  <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                    <InputLabel id="label-porto">{t("queries.rep_harbor")}</InputLabel>
                    <Select
                      labelId="label-porto"
                      id="select-porto"
                      onChange={handleChangePorto}
                      // value={age}
                      // label="Age"
                      style={{ height: "3rem" }}
                    >
                      {portoList.map((p) => (
                        <MenuItem key={p.idportos} value={p.idportos}>
                          {p.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
              <Grid item xs={12} sm={12} md={4} lg={6} xl={6} p={1}>
                <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                  <InputLabel id="label-patio">{t("queries.rep_dock")}</InputLabel>
                  <Select
                    labelId="label-patio"
                    id="select-patio"
                    onChange={handleChangePatio}
                    style={{ height: "3rem" }}
                  >
                    {patioList.map((p) => (
                      <MenuItem key={p.idpatios} value={p.idpatios}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6} xl={6} p={1}>
                <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%", mt: 3.5 }}>
                  <TextField
                    id="outlined-basic"
                    onChange={handleChangeQuerieContainer}
                    style={{ height: "3rem" }}
                    label={t("queries.container_search")}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6} xl={6} p={1}>
                <FormControl sx={{ m: 1, p: 1, width: "95%" }}>
                  <DesktopDatePicker
                    label={t("queries.rep_initial_date")}
                    inputFormat="DD/MM/yyyy"
                    value={moment(initDate, "DD/MM/YYYY")}
                    disableFuture
                    minDate={moment(initDate, "DD/MM/YYYY").startOf("year")}
                    onChange={(e) => {
                      setInitDate(e.format("DD/MM/YYYY").toString());
                    }}
                    renderInput={(params) => <TextField variant="standard" {...params} />}
                    style={{ height: "3rem" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6} xl={6} p={1}>
                <FormControl sx={{ m: 1, p: 1, width: "95%" }}>
                  <DesktopDatePicker
                    label={t("queries.rep_final_date")}
                    inputFormat="DD/MM/yyyy"
                    value={moment(endDate, "DD/MM/YYYY")}
                    disableFuture
                    minDate={moment(initDate, "DD/MM/YYYY").startOf("year")}
                    onChange={(e) => {
                      setEndDate(e.format("DD/MM/YYYY").toString());
                    }}
                    renderInput={(params) => <TextField variant="standard" {...params} />}
                    style={{ height: "3rem" }}
                  />
                </FormControl>
              </Grid>
              <Grid container style={{ justifyContent: "left", alignItems: "cente" }}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={1}>
                  <FormControl
                    sx={{ m: 1, p: 1, width: "50%" }}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <MDButton
                      sx={{ width: "80%" }}
                      variant="gradient"
                      color="info"
                      style={{ height: "3rem" }}
                      onClick={requestReportContainer}
                    >
                      {t("reports.rep_generate")}
                    </MDButton>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
          {/* ###### END CONTAINER SESSION ######## */}
          {/* ###### START TRAVEL SESSION ######## */}
          <TabPanel value={value} index={1}>
            <Grid container spacing={1}>
              {portoList.length > 1 ? (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={1}>
                  <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                    <InputLabel id="label-porto">{t("queries.rep_harbor")}</InputLabel>
                    <Select
                      labelId="label-porto"
                      id="select-porto"
                      onChange={handleChangePorto}
                      // value={age}
                      // label="Age"
                      style={{ height: "3rem" }}
                    >
                      {portoList.map((p) => (
                        <MenuItem key={p.idportos} value={p.idportos}>
                          {p.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
              <Grid item xs={12} sm={12} md={4} lg={6} xl={6} p={1}>
                <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                  <InputLabel id="label-patio">{t("queries.rep_dock")}</InputLabel>
                  <Select
                    labelId="label-patio"
                    id="select-patio"
                    onChange={handleChangePatio}
                    style={{ height: "3rem" }}
                  >
                    {patioList.map((p) => (
                      <MenuItem key={p.idpatios} value={p.idpatios}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6} xl={6} p={1}>
                <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%", mt: 3.5 }}>
                  <TextField
                    id="outlined-basic"
                    onChange={handleChangeQuerieTravel}
                    style={{ height: "3rem" }}
                    label={t("queries.container_search")}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6} xl={6} p={1}>
                <FormControl sx={{ m: 1, p: 1, width: "95%" }}>
                  <DesktopDatePicker
                    label={t("queries.rep_initial_date")}
                    inputFormat="DD/MM/yyyy"
                    value={moment(initDate, "DD/MM/YYYY")}
                    disableFuture
                    minDate={moment(initDate, "DD/MM/YYYY").startOf("year")}
                    onChange={(e) => {
                      setInitDate(e.format("DD/MM/YYYY").toString());
                    }}
                    renderInput={(params) => <TextField variant="standard" {...params} />}
                    style={{ height: "3rem" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6} xl={6} p={1}>
                <FormControl sx={{ m: 1, p: 1, width: "95%" }}>
                  <DesktopDatePicker
                    label={t("queries.rep_final_date")}
                    inputFormat="DD/MM/yyyy"
                    value={moment(endDate, "DD/MM/YYYY")}
                    disableFuture
                    minDate={moment(initDate, "DD/MM/YYYY").startOf("year")}
                    onChange={(e) => {
                      setEndDate(e.format("DD/MM/YYYY").toString());
                    }}
                    renderInput={(params) => <TextField variant="standard" {...params} />}
                    style={{ height: "3rem" }}
                  />
                </FormControl>
              </Grid>
              <Grid container style={{ justifyContent: "left", alignItems: "cente" }}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={1}>
                  <FormControl
                    sx={{ m: 1, p: 1, width: "50%" }}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <MDButton
                      sx={{ width: "80%" }}
                      variant="gradient"
                      color="info"
                      style={{ height: "3rem" }}
                      onClick={requestReportTravel}
                    >
                      {t("reports.rep_generate")}
                    </MDButton>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
          {/* ###### END TRAVEL SESSION ######## */}
          {/* ###### START SHIP SESSION ######## */}
          <TabPanel value={value} index={2}>
            <Grid container spacing={1}>
              {portoList.length > 1 ? (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={1}>
                  <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                    <InputLabel id="label-porto">{t("queries.rep_harbor")}</InputLabel>
                    <Select
                      labelId="label-porto"
                      id="select-porto"
                      onChange={handleChangePorto}
                      // value={age}
                      // label="Age"
                      style={{ height: "3rem" }}
                    >
                      {portoList.map((p) => (
                        <MenuItem key={p.idportos} value={p.idportos}>
                          {p.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
              <Grid item xs={12} sm={12} md={4} lg={6} xl={6} p={1}>
                <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                  <InputLabel id="label-patio">{t("queries.rep_dock")}</InputLabel>
                  <Select
                    labelId="label-patio"
                    id="select-patio"
                    onChange={handleChangePatio}
                    style={{ height: "3rem" }}
                  >
                    {patioList.map((p) => (
                      <MenuItem key={p.idpatios} value={p.idpatios}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6} xl={6} p={1}>
                <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%", mt: 3.5 }}>
                  <TextField
                    id="outlined-basic"
                    onChange={handleChangeQuerieShip}
                    style={{ height: "3rem" }}
                    label={t("queries.container_search")}
                    variant="outlined"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6} xl={6} p={1}>
                <FormControl sx={{ m: 1, p: 1, width: "95%" }}>
                  <DesktopDatePicker
                    label={t("queries.rep_initial_date")}
                    inputFormat="DD/MM/yyyy"
                    value={moment(initDate, "DD/MM/YYYY")}
                    disableFuture
                    minDate={moment(initDate, "DD/MM/YYYY").startOf("year")}
                    onChange={(e) => {
                      setInitDate(e.format("DD/MM/YYYY").toString());
                    }}
                    renderInput={(params) => <TextField variant="standard" {...params} />}
                    style={{ height: "3rem" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6} xl={6} p={1}>
                <FormControl sx={{ m: 1, p: 1, width: "95%" }}>
                  <DesktopDatePicker
                    label={t("queries.rep_final_date")}
                    inputFormat="DD/MM/yyyy"
                    value={moment(endDate, "DD/MM/YYYY")}
                    disableFuture
                    minDate={moment(initDate, "DD/MM/YYYY").startOf("year")}
                    onChange={(e) => {
                      setEndDate(e.format("DD/MM/YYYY").toString());
                    }}
                    renderInput={(params) => <TextField variant="standard" {...params} />}
                    style={{ height: "3rem" }}
                  />
                </FormControl>
              </Grid>
              <Grid container style={{ justifyContent: "left", alignItems: "cente" }}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={1}>
                  <FormControl
                    sx={{ m: 1, p: 1, width: "50%" }}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <MDButton
                      sx={{ width: "80%" }}
                      variant="gradient"
                      color="info"
                      style={{ height: "3rem" }}
                      onClick={requestReportShip}
                    >
                      {t("reports.rep_generate")}
                    </MDButton>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
          {/* ###### END SHIP SESSION ######## */}
          {/* ###### START CONSIG SESSION ######## */}
          <TabPanel value={value} index={3}>
            <Grid container spacing={1}>
              {portoList.length > 1 ? (
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={1}>
                  <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                    <InputLabel id="label-porto">{t("queries.rep_harbor")}</InputLabel>
                    <Select
                      labelId="label-porto"
                      id="select-porto"
                      onChange={handleChangePorto}
                      style={{ height: "3rem" }}
                    >
                      {portoList.map((p) => (
                        <MenuItem key={p.idportos} value={p.idportos}>
                          {p.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              ) : null}
              <Grid item xs={12} sm={12} md={4} lg={6} xl={6} p={1}>
                <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                  <InputLabel id="label-patio">{t("queries.rep_dock")}</InputLabel>
                  <Select
                    labelId="label-patio"
                    id="select-patio"
                    onChange={handleChangePatio}
                    style={{ height: "3rem" }}
                  >
                    {patioList.map((p) => (
                      <MenuItem key={p.idpatios} value={p.idpatios}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6} xl={6} p={1}>
                <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                  <InputLabel id="label-consig">{t("queries.tab_consig")}</InputLabel>
                  <Select
                    labelId="label-consig"
                    id="select-consig"
                    onChange={handleChangeConsig}
                    style={{ height: "3rem" }}
                  >
                    {consigList.map((p) => (
                      <MenuItem key={p.id} value={p.id}>
                        {p.consig}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12} md={4} lg={6} xl={6} p={1}>
                <FormControl sx={{ m: 1, p: 1, width: "95%" }}>
                  <DesktopDatePicker
                    label={t("queries.rep_initial_date")}
                    inputFormat="DD/MM/yyyy"
                    value={moment(initDate, "DD/MM/YYYY")}
                    disableFuture
                    minDate={moment(initDate, "DD/MM/YYYY").startOf("year")}
                    onChange={(e) => {
                      setInitDate(e.format("DD/MM/YYYY").toString());
                    }}
                    renderInput={(params) => <TextField variant="standard" {...params} />}
                    style={{ height: "3rem" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={6} xl={6} p={1}>
                <FormControl sx={{ m: 1, p: 1, width: "95%" }}>
                  <DesktopDatePicker
                    label={t("queries.rep_final_date")}
                    inputFormat="DD/MM/yyyy"
                    value={moment(endDate, "DD/MM/YYYY")}
                    disableFuture
                    minDate={moment(initDate, "DD/MM/YYYY").startOf("year")}
                    onChange={(e) => {
                      setEndDate(e.format("DD/MM/YYYY").toString());
                    }}
                    renderInput={(params) => <TextField variant="standard" {...params} />}
                    style={{ height: "3rem" }}
                  />
                </FormControl>
              </Grid>
              <Grid container style={{ justifyContent: "left", alignItems: "cente" }}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6} p={1}>
                  <FormControl
                    sx={{ m: 1, p: 1, width: "50%" }}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <MDButton
                      sx={{ width: "80%" }}
                      variant="gradient"
                      color="info"
                      style={{ height: "3rem" }}
                      onClick={requestReportConsig}
                    >
                      {t("reports.rep_generate")}
                    </MDButton>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>
          {/* ###### END CONSIG SESSION ######## */}
        </Box>

        {alertError === 1 ? (
          <Grid container mt={-30} style={{ position: "fixed" }}>
            <Grid xs={11} item>
              <Box style={{ display: "flex", justifyContent: "right" }} className="text-title">
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      size="small"
                      color="inherit"
                      onClick={() => {
                        setAlertError(0);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  severity="error"
                >
                  {t("Nenhum dado para mostrar!")}
                </Alert>
              </Box>
            </Grid>
          </Grid>
        ) : null}

        {loading && (
          <Box sx={{ width: "100%" }} ml="48%" mt={5}>
            <MDTypography variant="h5" ml={-3} color="textPrimary">
              {t("alerts.loading")}
            </MDTypography>
            <CircularProgress color="info" />
          </Box>
        )}
        {alertError === 2 ? (
          <Grid container mt={-30} style={{ position: "fixed" }}>
            <Grid xs={11} item>
              <Box style={{ display: "flex", justifyContent: "right" }} className="text-title">
                <Alert
                  action={
                    <IconButton
                      aria-label="close"
                      size="small"
                      color="inherit"
                      onClick={() => {
                        setAlertError(0);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  severity="error"
                >
                  {t("Preencha todos os campos!")}
                </Alert>
              </Box>
            </Grid>
          </Grid>
        ) : null}
      </Card>
      {reportDataKeys.length > 0
        ? reportDataKeys.map((key) => (
            <Report
              type={typeReport}
              ship={key}
              reportData={reportData[key]}
              InitDate={initDate}
              EndDate={endDate}
              patio={patioList.find((p) => p.idpatios === patio).name}
              reportAll={reportData}
            />
          ))
        : null}
    </SysDashLayout>
  );
}

export default SystemQueries;
