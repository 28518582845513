import * as XLSX from "xlsx";
import formatDate from "../../../../helpers/formatDate";
import formatHour from "../../../../helpers/formatHour";

const XlsxMonthlyComplete = (props) => {
  console.log("RECEBIMENTO DE PROPS NO XLX COMPLETO", props);
  const wb = XLSX.utils.book_new();

  const data = [
    [
      "NAVIO",
      "CONTAINER",
      "VIAGEM DE ENTRADA",
      "PRIMEIRO MONITORAMENTO MÊS",
      "ÚLTIMO MONITORAMENTO MÊS",
      "SETPOINT",
      "TOTAL",
      "TOTAL AM",
      "TOTAL PM",
      "FALHAS",
      "PROBLEMAS",
      "SUBTOTAL (R$)",
    ],
  ];
  // const keyName = Object.keys(props);
  // console.log("PRIMERAS CHAVES", keyName);
  // const resultPrimeiro = keyName.forEach((key) => {

  // // });
  // console.log("PRIMEROS RESULTADOS", resultPrimeiro);

  Object.keys(props?.reportAll).forEach((navioKey) => {
    const navio = props?.reportAll[navioKey];
    const valueMonitoringUser = props.valuePerMonitoring;
    // Iterar sobre os contêineres
    Object.keys(navio.containers).forEach((containerKey) => {
      const container = navio.containers[containerKey];
      const e = [];
      const dateErr = [];
      // Iterar sobre os registros de monitoramento
      container.monitoring.forEach((registro) => {
        if (registro.errType > 0) {
          e.push(registro.errType);
          const fDate = formatDate(registro.read_timestamp);
          const fHour = parseFloat(formatHour(registro.read_timestamp));
          if (fHour <= 12) {
            dateErr.push(`${fDate} - AM        `);
          } else {
            dateErr.push(`${fDate} - PM        `);
          }
        }
      });
      const hourIn = parseFloat(formatHour(container.date_in));
      const hourOut = parseFloat(formatHour(container.date_out));
      const dateIn = formatDate(container.date_in);
      const dateOut = formatDate(container.date_out);

      data.push([
        navioKey,
        containerKey,
        navio.travel,
        hourIn <= 12 ? `${dateIn} - AM` : `${dateIn} - PM`,
        hourOut <= 12 ? `${dateOut} - AM` : `${dateOut} - PM`,
        container.temperature,
        container.monitoring.length,
        container.am,
        container.pm,
        container.err,
        dateErr.join(""),
        container.monitoring.length * valueMonitoringUser,
      ]);
    });
  });
  // const keys = Object.keys(props?.reportAll);
  // keys.forEach((key) => {
  //   // const containersKey = Object.keys(props?.reportAll[key].containers);
  //   const valueMonitoringUser = props.valuePerMonitoring;
  //   const e = [];
  //   const dateErr = [];
  //   key.forEach((containersKey) => {
  //     containersKey.monitoring.forEach((m) => {
  //       if (m.errType > 0) {
  //         e.push(m.errType);
  //         const fDate = formatDate(m.read_timestamp);
  //         const fHour = parseFloat(formatHour(m.read_timestamp));
  //         if (fHour <= 12) {
  //           dateErr.push(`${fDate} - AM        `);
  //         } else {
  //           dateErr.push(`${fDate} - PM        `);
  //         }
  //       }
  //     });
  //     const hourIn = parseFloat(formatHour(props.reportData.containers[key].date_in));
  //     const hourOut = parseFloat(formatHour(props.reportData.containers[key].date_out));
  //     const dateIn = formatDate(props.reportData.containers[key].date_in);
  //     const dateOut = formatDate(props.reportData.containers[key].date_out);

  //     data.push([
  //       props.ship,
  //       key,
  //       props.reportData.travel,
  //       hourIn <= 12 ? `${dateIn} - AM` : `${dateIn} - PM`,
  //       hourOut <= 12 ? `${dateOut} - AM` : `${dateOut} - PM`,
  //       props.reportData.containers[key].temperature,
  //       props.reportData.containers[key].monitoring.length,
  //       props.reportData.containers[key].am,
  //       props.reportData.containers[key].pm,
  //       props.reportData.containers[key].err,
  //       dateErr.join(""),
  //       props.reportData.containers[key].monitoring.length * valueMonitoringUser,
  //     ]);
  //   });
  // });

  wb.Props = {
    Title: "Espelho mensal",
    Subject: "Relatório mensal espelho em formato de excel",
    Author: "Kevlan Costa",
    CreatedDate: new Date(),
  };

  wb.SheetNames.push("Relatório mensal Completo");

  const ws = XLSX.utils.aoa_to_sheet(data);

  wb.Sheets["Relatório mensal Completo"] = ws;

  XLSX.writeFile(wb, "Relatório mensal Completo.xlsx", { bookType: "xlsx", type: "binary" });
};

export default XlsxMonthlyComplete;
