/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import * as React from "react";

// Material Dashboard 2 React components
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

// I18N
import { useTranslation } from "react-i18next";
import MDInput from "../../components/MDInput";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";

export default function ModalTravel(props) {
  const { t } = useTranslation("common");
  const [travel, setTravel] = React.useState("");
  const [date, setDate] = React.useState("");

  React.useEffect(() => {
    setTravel(props.travel?.travel);
    setDate(props.travel?.date);
  }, [props.travel]);

  const handleSave = () => {
    const p = {
      id: props.travel?.idmovimentacoes,
      data: {
        isArrive: props.travel?.isArrive,
        naviosIdnavios: props.travel?.naviosIdnavios,
        patiosIdpatios: props.travel?.patiosIdpatios,
        travel,
        date,
      },
    };
    props.onClose(p);
  };

  return (
    <Grid container xs={12} className="modal-transp">
      <Grid item xs={12} sm={9} md={8} lg={5} xl={4}>
        <MDBox className="card-modal" sx={{ bgcolor: "#ffff" }}>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={3}
            mt={-4}
            p={2}
            mb={3}
            textAlign="center"
            sx={{ width: "90%", bgcolor: "#888" }}
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              <Box>{props.title}</Box>
            </MDTypography>
          </MDBox>
          <Grid container justifyItems="center" justifyContent="center">
            <Grid item xs={12} sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <MDInput
                  id="outlined-basic"
                  // onChange={handleChangePatio}
                  label={t("modal.mod_input_travel")}
                  variant="standard"
                  fullWidth
                  size="string"
                  value={travel}
                  onChange={(e) => setTravel(e.target.value)}
                  style={{ height: "2rem" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} sx={{ width: "100%" }} my={4}>
              <DesktopDatePicker
                label={t("operational.initial_date")}
                inputFormat="DD/MM/yyyy"
                variant="standard"
                style={{ height: "3rem" }}
                value={date}
                onChange={setDate}
                renderInput={(params) => <TextField variant="standard" {...params} />}
              />
            </Grid>
            <Grid container style={{ justifyContent: "right", alignItems: "center" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={6} xxl={6} p={1}>
                <Stack direction="row">
                  <FormControl
                    sx={{ p: 1, width: "100%" }}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <MDButton
                      sx={{ width: "100%" }}
                      variant="gradient"
                      color="info"
                      style={{ height: "3rem" }}
                      onClick={() => props.onClose(null)}
                    >
                      {t("modal.mod_cancel")}
                    </MDButton>
                  </FormControl>
                  <FormControl
                    sx={{ p: 1, width: "100%" }}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <MDButton
                      // onClick={props.onClose}
                      sx={{ width: "100%" }}
                      variant="gradient"
                      color="info"
                      style={{ height: "3rem" }}
                      onClick={handleSave}
                    >
                      {t("modal.mod_save")}
                    </MDButton>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Grid>
  );
}
