import React, { useEffect } from "react";

function Pdf() {
  const handlePdf = async () => {
    window.open("/ManualRapidoAdmin.pdf", "_blank")?.focus();
  };

  useEffect(() => {
    handlePdf();
  }, []);

  return (
    <div style={{ padding: 32 }}>
      <h1 style={{ fontSize: 35 }}>
        Para maiores dúvidas, <br />
        entre em contato com a nossa equipe de suporte!
      </h1>

      {/* <div style={{ padding: 32 }}>
        <h1 style={{ fontSize: 20 }}>1º Turno</h1>
        <h1>Marvin Rocha</h1>
        <a style={{ padding: 10 }} href="mailto:marvin.rocha@grupoicts.com.br">
          marvin.rocha@grupoicts.com.br
        </a>
        <h1 style={{ fontSize: 20, marginTop: 5 }}>2º Turno</h1>
        <h1>Kevlan Betto</h1>
        <a style={{ padding: 10 }} href="mailto:kevlan.costa@grupoicts.com.br">
          kevlan.costa@grupoicts.com.br
        </a>
        <h1 style={{ fontSize: 20, marginTop: 5 }}>3º Turno</h1>
        <h1>Francisco Neto</h1>
        <a style={{ padding: 10 }} href="mailto:francisco.neto@grupoicts.com.br">
          francisco.neto@grupoicts.com.br
        </a>
      </div>

      <p style={{ fontSize: 15 }}>
        Nossa equipe de suporte está disponivel em turnos, favor contatar o profissional referente
        ao seu turno de trabalho.
      </p> */}
    </div>
  );
}

export default Pdf;
