import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
// import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from "react-i18next";
import "./index.css";

// import i18n (needs to be bundled ;))

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import App from "App";
// date-fns
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// or for Day.js
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// or for Luxon
// import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
// or for Moment.js
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import i18next from "./helpers/i18n";

ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <I18nextProvider i18n={i18next}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <App />
        </LocalizationProvider>
      </I18nextProvider>
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
