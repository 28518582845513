/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";

// I18
import { useTranslation } from "react-i18next";
// import authService from "../../helpers/auth.service";
import MDButton from "../../components/MDButton";
import LoadingButton from "@mui/lab/LoadingButton";
import PrintIcon from "@mui/icons-material/Print";
import DescIcon from "@mui/icons-material/Description";
import NoteIcon from "@mui/icons-material/InsertPageBreak";
import ReportFooter from "./ReportFooter";
import ReportHeader from "./ReportHeader";
import ReportHeaderMonthly from "./ReportHeaderMonthly";
import MaintenanceHeader from "./Maintenance/MaintenanceHeader";
import ReportHeaderMontSheet from "./ReportHeaderMontSheet";
import ReportBody from "./ReportBody";
import ReportBodyMonthly from "./ReportBodyMonthly";
import ReportBodyMontSheet from "./ReportBodyMontSheetOld";
import GeneratePdfMontClosing from "./Export/pdf/GeneratePdfMontClosing";
import GeneratePdfDaily from "./Export/pdf/GeneratePdfDaily";
import MaintenanceClosing from "./Maintenance/MaintenanceClosing";
import XlsxDaily from "./Export/xlsx/XlsxDaily";
import XlsxDailyComplete from "./Export/xlsx/XlsxDailyComplete";
import XlsxMontClosing from "./Export/xlsx/XlsxMontClosing";
import XlsxMonthly from "./Export/xlsx/XlsxMonthly";
import XlsxMonthlyComplete from "./Export/xlsx/XlsxMonthlyComplete";
import XlsxQueriesComplete from "./Export/xlsx/XlsxQueriesComplete";
import XlsxConsigComplete from "./Export/xlsx/XlsxConsigComplete";
import XlsxContainerComplete from "./Export/xlsx/XlsxContainerComplete";
import MaintenanceOS from "./Maintenance/MaintenanceOS";
import MaintenanceHeaderOS from "./Maintenance/MaintenanceHeaderOS";
import MaintenanceDaily from "./Maintenance/MaintenanceDaily";
import Container from "./Queries/Container";
import Travel from "./Queries/Travel";
import Ship from "./Queries/Ship";
import Consig from "./Queries/Consig";
import config from "../../config/config.json";
import axios from "axios";

export default function Report(props) {
  const { t } = useTranslation("common");
  // const usr = authService.getCurrentUser();
  const { API_URL } = config;

  const [loadingPdf, setLoadingPdf] = React.useState(false);
  const [loadingXlsx, setLoadingXlsx] = React.useState(false);
  const [loadingXlsxComplete, setLoadingXlsxComplete] = React.useState(false);

  const PrintPDF = async () => {
    setLoadingPdf(true);
    console.log("clicado no pdf", props);
    if (props?.ship === "all") {
      GeneratePdfMontClosing(props);
      setLoadingPdf(false);
    } else if (props?.isMonthly && props?.ship !== "all") {
      // Função para obter o PDF do backend e exibi-lo
      console.log("PDF PROPS", props);
      const dataParam = {
        init: props.InitDate,
        end: props.EndDate,
        patio: props?.reportData.idPatio,
        isPdf: true,
        isShip: props?.ship,
      };
      console.log("PARAMTROS PDF", dataParam);
      try {
        // Fazendo uma solicitação GET para o endpoint do backend que retorna o PDF
        const response = await axios.get(`${API_URL}reports`, {
          params: dataParam,
          responseType: "arraybuffer", // Indica que estamos esperando um array de bytes (buffer) como resposta
        });
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        window.open(url);
        console.log(response.data);
      } catch (error) {
        console.error("Erro ao obter o PDF do backend:", error);
      } finally {
        setLoadingPdf(false);
      }
    } else if (
      !props?.isMonthly &&
      props?.ship !== "all" &&
      props.type === undefined &&
      !props.maintenance
    ) {
      console.log("CAIU AQUI");
      GeneratePdfDaily(props);
      setLoadingPdf(false);
    } else if (props.type === "container") {
      // Função para obter o PDF do backend e exibi-lo
      console.log("PDF PROPS", props);
      const dataParam = {
        init: props.InitDate,
        end: props.EndDate,
        patio: props?.reportData?.idPatio,
        isQueries: true,
        isPdf: true,
        isContainer: props?.reportData?.container,
      };
      console.log("PARAMTROS PDF", dataParam);
      try {
        // Fazendo uma solicitação GET para o endpoint do backend que retorna o PDF
        const response = await axios.get(`${API_URL}reports`, {
          params: dataParam,
          responseType: "arraybuffer", // Indica que estamos esperando um array de bytes (buffer) como resposta
        });
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        window.open(url);
        console.log(response.data);
      } catch (error) {
        console.error("Erro ao obter o PDF do backend:", error);
      } finally {
        setLoadingPdf(false);
      }
    } else if (
      props.type === "travel" ||
      props.type === "ship" ||
      props.type === "consig" ||
      props.type === "reportDaily"
    ) {
      // Função para obter o PDF do backend e exibi-lo
      console.log("PDF PROPS", props);
      const dataParam =
        props.type === "travel"
          ? {
              init: props.InitDate,
              end: props.EndDate,
              patio: props?.reportData?.idPatio,
              isQueries: true,
              isPdf: true,
              isTravel: props?.reportData?.travel,
            }
          : props.type === "ship"
          ? {
              init: props.InitDate,
              end: props.EndDate,
              patio: props?.reportData?.idPatio,
              isQueries: true,
              isPdf: true,
              isShip: props?.ship,
            }
          : props.type === "consig"
          ? {
              init: props.InitDate,
              end: props.EndDate,
              patio: props?.reportData?.idPatio,
              isQueries: true,
              isPdf: true,
              isConsig: props?.reportData?.consig,
            }
          : props.type === "reportDaily"
          ? {
              init: props.InitDate,
              end: props.EndDate,
              patio: props?.reportData?.idPatio,
              isPdf: true,
              isShip: props?.ship,
              type: props?.type,
            }
          : null;
      console.log("PARAMTROS PDF", dataParam);
      try {
        // Fazendo uma solicitação GET para o endpoint do backend que retorna o PDF
        const response = await axios.get(`${API_URL}reports`, {
          params: dataParam,
          responseType: "arraybuffer", // Indica que estamos esperando um array de bytes (buffer) como resposta
        });
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        window.open(url);
        console.log(response.data);
      } catch (error) {
        console.error("Erro ao obter o PDF do backend:", error);
      } finally {
        setLoadingPdf(false);
      }
    } else {
      console.log("PDF MANUTENCAO");
      console.log("PDF PROPS", props);

      const dataParam =
        props?.maintenance === "OS" || props?.maintenance === "daily"
          ? {
              init: props.InitDate,
              end: props.EndDate,
              isMaintenance: true,
              patio: props?.data.idPatio,
              isPdf: true,
              isShip: props?.data.id,
              maintenanceValue: props?.maintenanceValue,
              isConsig: props?.patio.name,
            }
          : {
              init: props.InitDate,
              end: props.EndDate,
              isMaintenance: true,
              patio: props?.patio.idpatios,
              isPdf: true,
              isShip: "closing",
              maintenanceValue: props?.maintenanceValue,
            };

      console.log("PARAMTROS PDF", dataParam);
      try {
        // Fazendo uma solicitação GET para o endpoint do backend que retorna o PDF
        const response = await axios.get(`${API_URL}reports`, {
          params: dataParam,
          responseType: "arraybuffer", // Indica que estamos esperando um array de bytes (buffer) como resposta
        });
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        window.open(url);
        console.log(response.data);
      } catch (error) {
        console.error("Erro ao obter o PDF do backend:", error);
      } finally {
        setLoadingPdf(false);
      }
    }
  };
  const PrintXlsx = () => {
    console.log("clicado no xlsx", props);
    setLoadingXlsx(true);
    if (props?.ship === "all") {
      XlsxMontClosing(props);
      setLoadingXlsx(false);
    } else if (props?.isMonthly && props?.ship !== "all") {
      XlsxMonthly(props);
      setLoadingXlsx(false);
    } else if (!props?.isMonthly && props?.ship !== "all") {
      XlsxDaily(props);
    } else if (props?.type === "container") {
      XlsxQueriesComplete(props);
      setLoadingXlsx(false);
    }
  };
  const PrintXlsxComplete = async () => {
    console.log("clicado no xlsx completo", props);
    setLoadingXlsxComplete(true);
    if (props?.type === "consig" || props?.type === "travel" || props?.type === "ship") {
      XlsxConsigComplete(props);
      setLoadingXlsxComplete(false);
    } else if (props?.type === "container") {
      XlsxContainerComplete(props);
      setLoadingXlsxComplete(false);
    } else if (props?.isMonthly && props?.ship !== "all") {
      XlsxMonthlyComplete(props);
      setLoadingXlsxComplete(false);
    } else if (!props?.isMonthly && props?.ship !== "all") {
      XlsxDailyComplete(props);
      setLoadingXlsxComplete(false);
    } else if (props?.type === "container") {
      XlsxDailyComplete(props);
      setLoadingXlsxComplete(false);
    }
  };
  console.log("PROPS REPORTs:", props);
  return (
    <Card sx={{ mt: 3, p: 3 }}>
      <Grid container p={1}>
        <Box className="text-name-report">
          {props?.ship === "all" ? t("reports.rep_title_mont") : null}
          {props?.isMonthly && props?.ship !== "all"
            ? `${t("reports.title_closing")} - ${props?.ship}`
            : null}
          {props.type === "reportDaily" ? `${t("reports.title_closing")} - ${props?.ship}` : null}
          {props?.maintenance === "closing"
            ? `${t("reports.title_maintenance_header_closing")}`
            : null}
          {props?.maintenance === "OS" ? `${t("reports.title_maintenance")}` : null}
          {props?.maintenance === "daily" ? `${t("reports.title_maintenance")}` : null}
          {props?.type === "container" ? `${t("reports.title_closing")}` : null}
          {props?.type === "travel" ? `${t("reports.title_closing")}` : null}
          {props?.type === "ship" ? `${t("reports.title_closing")}` : null}
          {props?.type === "consig" ? `${t("reports.title_closing")}` : null}
        </Box>
        <Box className="text-date-header">
          {props?.ship === "all" || (props?.ship === "all" && props?.isMonthly)
            ? `${props?.patio}`
            : null}
          {props?.isMonthly && props?.ship !== "all" ? `${props?.patio}` : null}
          {!props?.isMonthly && props?.type === "reportDaily" ? `${props?.patio}` : null}
          {props?.maintenance === "closing" ? `${props?.patio.name}` : null}
          {props?.maintenance === "OS" ? null : null}
          {props?.maintenance === "daily" ? null : null}
          {props?.type === "container" ? `${props?.patio}` : null}
          {props?.type === "travel" ? `${props?.patio}` : null}
          {props?.type === "ship" ? `${props?.patio}` : null}
          {props?.type === "consig" ? `${props?.patio}` : null}
        </Box>
        <Box className="text-date-header">
          {props?.ship === "all" ? `${props?.InitDate} a ${props?.EndDate}` : null}
          {props?.isMonthly && props?.ship !== "all"
            ? `${props?.InitDate} a ${props?.EndDate}`
            : null}
          {!props?.isMonthly && props?.type === "reportDaily" ? `${props?.InitDate}` : null}
          {props?.maintenance === "closing" ? `${props?.InitDate} a ${props?.EndDate}` : null}
          {props?.maintenance === "daily" ? `${props?.InitDate}` : null}
          {props?.type === "container" ? `${props?.InitDate} a ${props?.EndDate}` : null}
          {props?.type === "travel" ? `${props?.InitDate} a ${props?.EndDate}` : null}
          {props?.type === "ship" ? `${props?.InitDate} a ${props?.EndDate}` : null}
          {props?.type === "consig" ? `${props?.InitDate} a ${props?.EndDate}` : null}
        </Box>
      </Grid>
      <Divider variant="inset" />
      <Grid container p={1}>
        {props?.ship === "all" ? <ReportHeaderMonthly /> : null}
        {props?.isMonthly && props?.ship !== "all" ? <ReportHeaderMontSheet /> : null}
        {!props?.isMonthly && props?.type === "reportDaily" ? <ReportHeader /> : null}
        {props?.maintenance === "closing" ? <MaintenanceHeader /> : null}
        {props?.maintenance === "OS" ? <MaintenanceHeaderOS /> : null}
        {props?.maintenance === "daily" ? <MaintenanceHeaderOS /> : null}
        {props?.type === "container" ? <ReportHeader /> : null}
        {props?.type === "travel" ? <ReportHeader /> : null}
        {props?.type === "ship" ? <ReportHeader /> : null}
        {props?.type === "consig" ? <ReportHeader /> : null}
        <Grid item p={1} xs={12}>
          <Box>
            {props?.ship === "all" ? <ReportBodyMonthly content={props} /> : null}
            {props?.isMonthly && props?.ship !== "all" ? (
              <ReportBodyMontSheet content={props} />
            ) : null}
            {!props?.isMonthly && props?.type === "reportDaily" ? (
              <ReportBody content={props} />
            ) : null}
            {props?.maintenance === "closing" ? <MaintenanceClosing content={props} /> : null}
            {props?.maintenance === "OS" &&
            props?.maintenance !== "closing" &&
            props?.maintenance !== "daily" ? (
              <MaintenanceOS content={props} />
            ) : null}
            {props?.maintenance === "daily" ? <MaintenanceDaily content={props} /> : null}
            {props?.type === "container" ? <Container content={props} /> : null}
            {props?.type === "travel" ? <Travel content={props} /> : null}
            {props?.type === "ship" ? <Ship content={props} /> : null}
            {props?.type === "consig" ? <Consig content={props} /> : null}
          </Box>
        </Grid>
        {/* <Divider variant="inset" /> */}
        <Grid item p={1} xs={12}>
          <Box>
            <ReportFooter />
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          justifyContent: "left",
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Grid item xs={12} sm={20} md={20} lg={20} xl={20} p={1}>
          <FormControl
            sx={{ m: 1, p: 1, width: "100%" }}
            style={{ justifyContent: "center", alignItems: "center", flexWrap: "wrap" }}
          >
            <Stack
              direction="row"
              spacing={2}
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              {/* <MDButton
                sx={{ width: "70%" }}
                variant="gradient"
                color="info"
                style={{ height: "2rem", width: "8rem" }}
                onClick={PrintPDF}
              >
                {t("reports.rep_print")}
              </MDButton> */}
              <LoadingButton
                size="large"
                onClick={PrintPDF}
                endIcon={<PrintIcon />}
                loading={loadingPdf}
                loadingPosition="end"
                variant="outlined"
                style={{ color: "#4C9CE6", borderColor: "#4C9CE6" }}
              >
                <span>{t("reports.rep_print")}</span>
              </LoadingButton>
              {/* <MDButton
                sx={{ width: "70%" }}
                variant="gradient"
                color="info"
                style={{ height: "2rem", width: "12rem" }}
                onClick={PrintXlsx}
              >
                {t("reports.rep_exp_esp")}
              </MDButton> */}
              {!props?.maintenance && (
                <LoadingButton
                  size="large"
                  onClick={PrintXlsx}
                  endIcon={<DescIcon />}
                  loading={loadingXlsx}
                  loadingPosition="end"
                  variant="outlined"
                  style={{ color: "#4C9CE6", borderColor: "#4C9CE6" }}
                >
                  <span>{t("reports.rep_exp_esp")}</span>
                </LoadingButton>
              )}

              {!props?.maintenance && props?.ship !== "all" ? (
                // <MDButton
                //   sx={{ width: "70%" }}
                //   variant="gradient"
                //   color="info"
                //   style={{ height: "2rem", width: "12rem" }}
                //   onClick={PrintXlsxComplete}
                // >
                //   {t("reports.rep_exp_com")}
                // </MDButton>
                <LoadingButton
                  size="large"
                  onClick={PrintXlsxComplete}
                  endIcon={<NoteIcon />}
                  loading={loadingXlsxComplete}
                  loadingPosition="end"
                  variant="outlined"
                  style={{ color: "#4C9CE6", borderColor: "#4C9CE6" }}
                >
                  <span>{t("reports.rep_exp_com")}</span>
                </LoadingButton>
              ) : null}
            </Stack>
          </FormControl>
        </Grid>
      </Grid>
    </Card>
  );
}
