/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
// @mui material components
import * as React from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import "../../index.css";
import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import SysDashLayout from "../../components_reefer/Layouts/SysDashLayout";
import ManualBasicoImage from "../../assets/images/illustrations/ManualBasico.png";
import BasicManualImage from "../../assets/images/illustrations/BasicManual.png";
import BasicManualImageApp from "../../assets/images/illustrations/BasicManualApp.png";
import ManualBasicoImageApp from "../../assets/images/illustrations/ManualBasicoApp.png";

function SystemHelp() {
  const { t } = useTranslation("common");

  // const handlePdf = async () => {
  //   window.open(`/ManualRapidoAdmin.pdf`, "_blank")?.focus();
  // };
  console.log(t);
  return (
    <SysDashLayout>
      <Card p={1}>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-2}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {t("menu.help")}
          </MDTypography>
        </MDBox>
        <Grid p={1} container spacing={2} justifyContent="center" mb={2} mt={2} alignItems="center">
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <Card
              my={1}
              style={{
                backgroundColor: "transparent",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
              // component={Link}
              to="/manuals"
              pt={1}
            >
              <a href="/ManualBasico.pdf" target="_blank" rel="noopener noreferrer">
                <img
                  src={ManualBasicoImage}
                  alt="Descrição da imagem"
                  style={{
                    width: "100%", // Defina a largura da imagem conforme necessário
                    height: "auto", // Isso garante que a imagem mantenha a proporção original
                    marginTop: "0.75rem",
                    marginBottom: "0.75rem",
                  }}
                />
              </a>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <Card
              my={1}
              style={{
                backgroundColor: "transparent",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
              // component={Link}
              to="/manuals"
              pt={1}
            >
              <a href="/BasicManual.pdf" target="_blank" rel="noopener noreferrer">
                <img
                  src={BasicManualImage}
                  alt="Descrição da imagem"
                  style={{
                    width: "100%", // Defina a largura da imagem conforme necessário
                    height: "auto", // Isso garante que a imagem mantenha a proporção original
                    marginTop: "0.75rem",
                    marginBottom: "0.75rem",
                  }}
                />
              </a>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <Card
              my={1}
              style={{
                backgroundColor: "transparent",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
              // component={Link}
              to="/manuals"
              pt={1}
            >
              <a href="/ManualBasicoApp.pdf" target="_blank" rel="noopener noreferrer">
                <img
                  src={ManualBasicoImageApp}
                  alt="Descrição da imagem"
                  style={{
                    width: "100%", // Defina a largura da imagem conforme necessário
                    height: "auto", // Isso garante que a imagem mantenha a proporção original
                    marginTop: "0.75rem",
                    marginBottom: "0.75rem",
                  }}
                />
              </a>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <Card
              my={1}
              style={{
                backgroundColor: "transparent",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
              // component={Link}
              to="/manuals"
              pt={1}
            >
              <a href="/BasicManualApp.pdf" target="_blank" rel="noopener noreferrer">
                <img
                  src={BasicManualImageApp}
                  alt="Descrição da imagem"
                  style={{
                    width: "100%", // Defina a largura da imagem conforme necessário
                    height: "auto", // Isso garante que a imagem mantenha a proporção original
                    marginTop: "0.75rem",
                    marginBottom: "0.75rem",
                  }}
                />
              </a>
            </Card>
          </Grid>
        </Grid>
      </Card>
    </SysDashLayout>
  );
}

export default SystemHelp;
