/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IconButton from "@mui/material/IconButton";
// import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// I18N
import { useTranslation } from "react-i18next";
import { CircularProgress, Grid } from "@mui/material";
import MDBox from "../../../components/MDBox";
import config from "../../../config/config.json";

// Images

import logo from "../../../assets/images/logos/Marca3white.png";
import BasicLayout from "../../../components_reefer/Layouts/BasicLayout";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDButton from "../../../components/MDButton";
import bgImage from "../../../assets/images/fundo001.jpg";
import AuthService from "../../../helpers/auth.service";

function Basic() {
  // const [rememberMe, setRememberMe] = useState(false);
  const [username, setUsername] = useState("");
  const [passwd, setPasswd] = useState("");
  const [loading, setLoading] = useState(false);
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const navigate = useNavigate();
  const { API_URL } = config;
  // mantem sessão ativa
  React.useEffect(() => {
    const last = AuthService.checkLastLogin();
    if (last?.token) {
      navigate("/sysdash");
    }
  }, []);

  React.useEffect(() => {
    const last = AuthService.checkLastLogin();
    if (last?.token) {
      navigate("/sysdash");
    }
  }, []);

  const onAuth = () => {
    navigate("/sysdash");
  };

  const auth = async () => {
    setLoading(true);
    await axios.post(`${API_URL}auth/authenticate`, { name: username, password: passwd }).then(
      (response) => {
        const mssg = response.statusText;
        mssg === "Created" ? setLoading(true) : null;
        console.log(response.statusText);
      },
      (error) => {
        setLoading(false);
        console.log(error.response.data);
        console.log(username, passwd);
        const mssg = error.response.data.message;
        mssg === "Password attempts exceeded"
          ? toast.error("Você errou sua senha muitas vezes!")
          : null;
        mssg === "Missing required parameter USERNAME"
          ? toast.error("Insira um usuário ou email!")
          : null;
        mssg === "Incorrect username or password." && passwd.length === 0
          ? toast.error("Insira uma senha!")
          : null;
        mssg === "Incorrect username or password." && passwd.length > 0
          ? toast.error("Usuário ou senha invalidos!")
          : null;
      }
    );
    AuthService.login(username, passwd, onAuth);
  };

  const changeUser = (e) => {
    setUsername(e.target.value);
  };
  // const changePass = (e) => {
  //   setPasswd(e.target.value);
  // };
  const handlePasswordChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setPasswd(event.target.value);
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      auth();
    }
  };

  const { t } = useTranslation("common");

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <BasicLayout image={bgImage}>
      <Card
        style={{
          alignItems: "center",
          background: "none",
          border: "none",
          width: "100%",
          marginBottom: 40,
          marginLeft: "auto",
          marginRight: "auto",
          boxShadow: "none",
          backgroundColor: "transparent",
        }}
      >
        <MDTypography variant="h4" fontWeight="medium" mt={1}>
          <img src={logo} alt="logo" width={340} />
        </MDTypography>
      </Card>
      <ToastContainer />
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {t("auth.title1")}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                onChange={changeUser}
                label={t("auth.email")}
                fullWidth
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    auth();
                  }
                }}
              />
            </MDBox>
            <Grid container spacing={1} alignItems="flex-end" mb={4}>
              <Grid item xs={9} sm={9} md={9} lg={9} xl={10}>
                <MDInput
                  type={values.showPassword ? "text" : "password"}
                  onChange={handlePasswordChange("password")}
                  label={t("auth.passwd")}
                  fullWidth
                  onKeyPress={handleKeyPress}
                  value={values.password}
                />
              </Grid>
              <Grid item>
                <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </Grid>
            </Grid>
            <MDBox mt={-2} mb={1} textAlign="center">
              <MDTypography variant="h6" component={Link} to="/authentication/reset-password">
                {t("auth.forget_pass")}
              </MDTypography>
            </MDBox>
            {loading ? (
              <Box sx={{ width: "100%" }} ml="44%">
                <CircularProgress color="info" />
              </Box>
            ) : null}
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                // component={Link}
                // to="/sysdash"
                onClick={auth}
                size="small"
              >
                {t("auth.enter")}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
