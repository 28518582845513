import * as React from "react";
// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components

// I18N
import { useTranslation } from "react-i18next";
import axios from "axios";
import MDTypography from "../../../components/MDTypography";
import authHeader from "../../../helpers/auth-headers";
import config from "../../../config/config.json";
import MDButton from "../../../components/MDButton";

export default function data(openModalCallBack, isSysAdm, accountID) {
  const { API_URL } = config;
  // const response = await axios.get(API_URL+"users", { headers: authHeader() })
  // console.log(response.data)
  const { t } = useTranslation("common");
  const [usrList, setUsrList] = React.useState([]);
  const [doneOnce, setDoneOnce] = React.useState(false);

  const colAdmin = [
    { Header: t("common.actions"), accessor: "actions", align: "center" },
    { Header: t("table_users.col_name"), accessor: "name", align: "center" },
    { Header: t("table_users.col_username"), accessor: "username", align: "center" },
    { Header: t("table_users.col_phone"), accessor: "phone", align: "center" },
    { Header: t("table_users.col_mail"), accessor: "mail", align: "center" },

    { Header: t("table_users.col_permission"), accessor: "isPermission", align: "center" },
    { Header: t("table_users.col_is_alert"), accessor: "isAlert", align: "center" },
    { Header: t("table_users.col_is_active"), accessor: "isActive", align: "center" },
    { Header: t("table_users.col_account"), accessor: "account", align: "center" },
    { Header: t("table_users.col_patios"), accessor: "isPatios", align: "center" },
  ];

  const colNotAdmin = [
    { Header: t("common.actions"), accessor: "actions", align: "center" },
    { Header: t("table_users.col_name"), accessor: "name", align: "left" },
    { Header: t("table_users.col_username"), accessor: "username", align: "left" },
    { Header: t("table_users.col_phone"), accessor: "phone", align: "left" },
    { Header: t("table_users.col_mail"), accessor: "mail", align: "center" },
    { Header: t("table_users.col_is_active"), accessor: "isActive", align: "center" },
    { Header: t("table_users.col_account_admin"), accessor: "isPermission", align: "center" },
    { Header: t("table_users.col_sys_admin"), accessor: "isSysAdmin", align: "center" },
    { Header: t("table_users.col_is_alert"), accessor: "isAlert", align: "center" },
    { Header: t("table_users.col_is_financial"), accessor: "isFinancial", align: "center" },
    { Header: t("table_users.col_is_op_admin"), accessor: "isAdmin", align: "center" },
    { Header: t("table_users.col_is_operational"), accessor: "isOp", align: "center" },
    { Header: t("table_users.col_is_maintenance"), accessor: "isMaintenance", align: "center" },
  ];

  const fetchData = async (force) => {
    if (!doneOnce || force === true) {
      setDoneOnce(true);
      const urlAdd = accountID ? `/account/${accountID}` : "";
      const response = await axios.get(`${API_URL}users${urlAdd}`, {
        headers: authHeader(),
      });
      setUsrList(
        response.data.map((p) => ({
          actions: (
            <MDButton fontWeight="medium" fontSize="large" onClick={() => openModalCallBack(p)}>
              <Icon>edit</Icon>
            </MDButton>
          ),
          name: (
            <MDTypography fontWeight="medium" fontSize="small">
              {p.name}
            </MDTypography>
          ),
          username: (
            <MDTypography fontWeight="medium" fontSize="small">
              {p.username}
            </MDTypography>
          ),
          phone: (
            <MDTypography fontWeight="medium" fontSize="small">
              {p.phone}
            </MDTypography>
          ),
          mail: (
            <MDTypography fontWeight="medium" fontSize="small">
              {p.mail}
            </MDTypography>
          ),
          isActive: (
            <MDTypography style={{ alignItems: "center" }}>
              <Icon>{p.isActive ? "check" : "clear"}</Icon>
            </MDTypography>
          ),
          isPermission: (
            <MDTypography fontWeight="medium" fontSize="small">
              {p.isSysAdmin
                ? "Administrador de sistema"
                : p.isAccountAdmin
                ? "Gestão"
                : p.isOPAdmin
                ? "Administrativo"
                : p.isOperational
                ? "Operacional"
                : p.isFinancial
                ? "Financeiro"
                : null}
            </MDTypography>
          ),

          isAlert: (
            <MDTypography>
              <Icon>{p.isAlert ? "check" : "clear"}</Icon>
            </MDTypography>
          ),
          account: (
            <MDTypography fontWeight="medium" fontSize="small">
              {p.accounts.nickname}
            </MDTypography>
          ),
          isPatios: p.isPatios ? (
            <MDTypography fontWeight="medium" fontSize="small">
              {console.log(p)}
              {p.isPatios.map((patio) => patio.name).join(" • ")}
            </MDTypography>
          ) : (
            <span>Nenhum pátio a exibir</span>
          ),
        }))
      );
    }
  };

  fetchData();
  return {
    columns: isSysAdm ? colAdmin : colNotAdmin,
    rows: usrList,
    update: fetchData,
  };
}
