/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */

import * as React from "react";

import Grid from "@mui/material/Grid";
// Data
// Dashboard components
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
// Material Dashboard 2 React components
// Authentication layout components
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import SysDashLayout from "../../components_reefer/Layouts/SysDashLayout";
import config from "../../config/config.json";
// I18N
import MDTypography from "../../components/MDTypography";
import OrdersOverview from "./components/OrdersOverview";
import Projects from "./Projects/index";
import DockCard from "../../components_reefer/Cards/StatisticsCards/DockCard/index";
import MDBox from "../../components/MDBox";
import authHeader from "../../helpers/auth-headers";
import authService from "../../helpers/auth.service";

export default function Dashboard() {
  const { t } = useTranslation("common");
  // const [patio, setPatio] = React.useState("");
  const [patioList, setPatioList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { API_URL } = config;
  const user = authService.getCurrentUser();

  React.useEffect(() => {
    const fetchData = async () => {
      const tmpPatios = [];
      if (patioList.length === 0) {
        await axios
          .get(`${API_URL}dashboard/${user.accountsIdaccounts}`, { headers: authHeader() })
          .then(
            (response) => {
              setLoading(false);
              // console.log("responses-------------", response.data);
              response.data.forEach((dt) => {
                dt.patios.forEach((p) => {
                  tmpPatios.push({
                    name: p.name,
                    cont: p.containersComplete.length,
                    Monit: p.containersMonitored.length,
                    Err: p.monitErr,
                    isActive: p.isActive,
                  });
                });
              });
            },
            (error) => {
              console.log(error);
            }
          );
        setPatioList(tmpPatios);
      }
    };
    fetchData();
  }, []);

  return (
    <SysDashLayout>
      <Card p={1}>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-2}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {t("menu.dash")}
          </MDTypography>
        </MDBox>
        {loading ? (
          <Box sx={{ width: "100%" }} ml="48%" mt={10}>
            <CircularProgress color="info" />
          </Box>
        ) : null}
        <MDBox pt={6} pb={3} px={3}>
          <Grid container spacing={4} px={6} justifyContent="center">
            {patioList.length > 0
              ? patioList.map((p) => (
                  <Grid item xs={12} md={6} lg={4}>
                    {p.isActive === 1 && (
                      <DockCard
                        title={p.name}
                        key={p.name}
                        value={p.cont}
                        count={`${p.cont} ${t("dashboard.dash_conts")}`}
                        Monit={`${p.Monit} ${t("dashboard.dash_monits")}`}
                        Err={`${p.Err} ${t("dashboard.dash_errs")}`}
                      />
                    )}
                  </Grid>
                ))
              : null}
          </Grid>
          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6} mt={6}>
                <Projects />
              </Grid>
              <Grid item xs={12} md={6} lg={6} mt={6}>
                <OrdersOverview />
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </Card>
    </SysDashLayout>
  );
}
