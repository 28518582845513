/* eslint-disable no-unsafe-optional-chaining */
import * as XLSX from "xlsx";

const XlsxMontClosing = (props) => {
  const wb = XLSX.utils.book_new();

  const data = [
    [
      "SHIP",
      "VIAGEM DE ENTRADA",
      "TOTAL DE CONTAINERS",
      "TOTAL DE MONITORAMENTOS",
      "VALOR A PAGAR",
    ],
  ];
  const keys = Object.keys(props?.reportData);
  keys.forEach((key) => {
    const valueMonitoringUser = props?.valuePerMonitoring;
    data.push([
      key,
      props?.reportData[key].travel,
      props?.reportData[key].totalContainers,
      props?.reportData[key].totalMonitoring,
      props?.reportData[key].totalMonitoring * valueMonitoringUser,
    ]);
  });

  console.log(props);
  wb.Props = {
    Title: "Espelho diário",
    Subject: "Relatório diário espelho em formato de excel",
    Author: "Kevlan Costa",
    CreatedDate: new Date(),
  };

  wb.SheetNames.push("Resumo de Fechamento");

  const ws = XLSX.utils.aoa_to_sheet(data);

  wb.Sheets["Resumo de Fechamento"] = ws;

  XLSX.writeFile(wb, "Resumo de Fechamento.xlsx", { bookType: "xlsx", type: "binary" });
};

export default XlsxMontClosing;
