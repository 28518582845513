/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable prefer-const */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Grid, IconButton, ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import ViewIcon from "@mui/icons-material/OpenInNewOutlined";
// import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
import MDTypography from "../../../components/MDTypography";
import DataTable from "../../Tables/DataTable";
import formatDate from "../../../helpers/formatDateMaintenance";

export default function MaintenanceOS(props) {
  const { t } = useTranslation("common");
  const [maintData, setMainData] = React.useState([]);
  const [rows, setRows] = React.useState([]);

  console.log("PROPS OS =======>:", props);

  const columns = [
    { Header: "ITEM*", accessor: "item", align: "left", color: "black" },
    { Header: "DESCRIPTION OF LABOR OR MATERIAL*", accessor: "desc", align: "left" },
    { Header: "QUANTITY*", accessor: "qtd", align: "left" },
    { Header: "VALUE*", accessor: "value", align: "left" },
  ];

  React.useEffect(() => {
    if (maintData.length === 0) {
      const tmpData = [];
      const url = "https://s3.sa-east-1.amazonaws.com/report-assets.reefersys.com/";
      const imag = props?.content.data.images;
      const splitImage = imag.split(",");
      splitImage.map((e) =>
        tmpData.push({
          img: url + e,
        })
      );
      setMainData(tmpData);
    }
  }, []);

  React.useEffect(() => {
    if (rows.length === 0) {
      const tmpRow = [];
      const tmpValue = [];
      const keys = Object.keys(props?.content.data.material_item);
      let matN = 1;
      keys.forEach((key) => {
        tmpRow.push({
          item: matN,
          desc: props?.content.data.material_item[key].item,
          qtd: props?.content.data.material_item[key].quantity,
          value: props?.content.data.material_item[key].value,
        });
        tmpValue.push([props?.content.data.material_item[key].value]);
        matN += 1;
      });
      setRows(tmpRow);
    }
  }, []);

  const somaLaborTotal = [];
  const totalValuess = rows.reduce(getTotal, 0);

  // Adiciona a soma total mais a manutenção ao array
  somaLaborTotal.push(
    totalValuess + props?.content.maintenanceValue * rows.reduce((acc, prod) => acc + prod.qtd, 0)
  );

  function getTotal(total, produto) {
    const somaTotal = total + produto.value * produto.qtd;
    return somaTotal;
  }

  console.log("Soma Labor Total:", somaLaborTotal);

  const valueMaintenance = props?.content.maintenanceValue;

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "black",
    borderRadius: "1px",
    textAlign: "left",
    color: "black",
  }));

  const Material1 = styled(Paper)(({ theme }) => ({
    borderLeft: "1px solid black",
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
    borderRadius: "1px",
    textAlign: "left",
    color: "black",
  }));

  const Material2 = styled(Paper)(({ theme }) => ({
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
    borderRadius: "1px",
    textAlign: "left",
    color: "black",
  }));
  const Material3 = styled(Paper)(({ theme }) => ({
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
    borderRadius: "1px",
    textAlign: "left",
    color: "black",
  }));
  const Material4 = styled(Paper)(({ theme }) => ({
    borderRight: "1px solid #000",
    borderTop: "1px solid black",
    borderBottom: "1px solid black",
    borderRadius: "1px",
    textAlign: "left",
    color: "black",
  }));

  return (
    <Card>
      <Box mt={2} sx={{ flexGrow: 1 }}>
        <Grid container>
          <Grid xs={3}>
            <Item sx={{ height: "40px" }}>
              <MDTypography variant="h4" ml={2}>
                ESTIMATE
              </MDTypography>
            </Item>
          </Grid>
          <Grid xs={3}>
            <Item sx={{ height: "40px" }}>
              <MDTypography variant="h5" ml={2}>
                EST Nº {props?.content.keys}
              </MDTypography>
            </Item>
          </Grid>
          <Grid xs={6}>
            <Item sx={{ height: "40px" }}>
              <MDTypography variant="h5" ml={2}>
                Date: {formatDate(props?.content.data.execution_date)}
              </MDTypography>
            </Item>
          </Grid>
          <Grid xs={6}>
            <Item>
              <MDTypography variant="button" ml={2}>
                Cliente:
              </MDTypography>
              <MDTypography variant="h5" ml={2}>
                Chibatão
              </MDTypography>
            </Item>
          </Grid>
          <Grid xs={6}>
            <Item>
              <MDTypography variant="button" ml={2}>
                Terminal:
              </MDTypography>
              <MDTypography variant="h5" ml={2}>
                {props?.content.patio.name}
              </MDTypography>
            </Item>
          </Grid>
          <Grid xs={3}>
            <Item>
              <MDTypography variant="button" ml={2}>
                Container:
              </MDTypography>
              <MDTypography variant="h5" ml={2}>
                {props?.content.data.container}
              </MDTypography>
            </Item>
          </Grid>
          <Grid xs={3}>
            <Item>
              <MDTypography variant="button" ml={2}>
                Marks of Equipment:
              </MDTypography>
              <MDTypography variant="h5" ml={2}>
                {props?.content.data.manufactory}
              </MDTypography>
            </Item>
          </Grid>
          <Grid xs={3}>
            <Item>
              <MDTypography variant="button" ml={2}>
                Voltage:
              </MDTypography>
              <MDTypography variant="h5" ml={2}>
                {props?.content.data.voltage}V
              </MDTypography>
            </Item>
          </Grid>
          <Grid xs={3}>
            <Item>
              <MDTypography variant="button" ml={2}>
                Size:
              </MDTypography>
              <MDTypography variant="h5" ml={2}>
                {props?.content.data.size}
              </MDTypography>
            </Item>
          </Grid>
          <Grid xs={2}>
            <Item>
              <MDTypography variant="button" ml={2}>
                H/H:
              </MDTypography>
              <MDTypography variant="h5" ml={2}>
                {props?.content.data.hh}
              </MDTypography>
            </Item>
          </Grid>
          <Grid xs={2}>
            <Item>
              <MDTypography variant="button" ml={2}>
                Total Labor:
              </MDTypography>
              <MDTypography variant="h5" ml={2}>
                1
              </MDTypography>
            </Item>
          </Grid>
          <Grid xs={8}>
            <Item>
              <MDTypography variant="button" ml={2}>
                Type of Service:
              </MDTypography>
              <MDTypography variant="h5" ml={2}>
                {props?.content.data.descType}
              </MDTypography>
            </Item>
          </Grid>

          <Grid xs={3}>
            <Material1>
              <MDTypography variant="button" ml={2}>
                ITEM
              </MDTypography>
              <MDTypography variant="h6" ml={2}>
                {rows.map((row, index) => (
                  <tr key={index}>
                    <td>{row.item}</td>
                  </tr>
                ))}
              </MDTypography>
            </Material1>
          </Grid>
          <Grid xs={3}>
            <Material2>
              <MDTypography variant="button" ml={2}>
                DESCRIPTION
              </MDTypography>
              <MDTypography variant="h6" ml={2}>
                {rows.map((row, index) => (
                  <tr key={index}>
                    <td>{row.desc}</td>
                  </tr>
                ))}
              </MDTypography>
            </Material2>
          </Grid>
          <Grid xs={3}>
            <Material3>
              <MDTypography variant="button" ml={2}>
                QUANTITY
              </MDTypography>
              <MDTypography variant="h6" ml={2}>
                {rows.map((row, index) => (
                  <tr key={index}>
                    <td>{row.qtd}</td>
                  </tr>
                ))}
              </MDTypography>
            </Material3>
          </Grid>

          <Grid xs={3}>
            <Material4>
              <MDTypography variant="button" ml={2}>
                VALUE
              </MDTypography>
              <MDTypography variant="h6" ml={2}>
                {rows.map((row, index) => (
                  <tr key={index}>
                    <td>{row.value}</td>
                  </tr>
                ))}
              </MDTypography>
            </Material4>
          </Grid>
          <Grid xs={6}>
            <Item>
              <MDTypography variant="button" ml={2}>
                Total Mat:
              </MDTypography>
              <MDTypography variant="h5" ml={2}>
                R$ {totalValuess}
              </MDTypography>
            </Item>
          </Grid>
          <Grid xs={6}>
            <Item>
              <MDTypography variant="button" ml={2}>
                Total Labor:
              </MDTypography>
              <MDTypography variant="h5" ml={2}>
                R$ {props?.content.maintenanceValue * props?.content.data.hh}
              </MDTypography>
            </Item>
          </Grid>
          <Grid xs={12}>
            <Item>
              <MDTypography variant="subtitle2" ml={2}>
                Parts prices based on standard catalog procurement price lists subject to change
                without notice Procurement and delivery charges may be added for spacialservices or
                itens not available locally.
              </MDTypography>
            </Item>
          </Grid>
          <Grid xs={12}>
            <Item sx={{ height: "auto", verticalAlign: "center" }}>
              <MDTypography variant="button" ml={2}>
                {props?.content.data.description}
              </MDTypography>
            </Item>
          </Grid>
          <Grid xs={8}>
            <Item sx={{ height: "60px" }}>
              <MDTypography variant="h5" ml={2} />
            </Item>
          </Grid>
          <Grid xs={4}>
            <Item sx={{ height: "60px" }}>
              <MDTypography variant="button" ml={2}>
                Total:
              </MDTypography>
              <MDTypography variant="h5" ml={2}>
                R$ {props?.content.maintenanceValue * props?.content.data.hh + totalValuess}
              </MDTypography>
            </Item>
          </Grid>
          <Grid xs={12}>
            <Item>
              <MDTypography variant="button" ml={2}>
                Evidencies:
              </MDTypography>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2} ml={1}>
                <ImageList sx={{ width: 800, height: 400 }} cols={5} rowHeight={164}>
                  {maintData.map((e) => (
                    <ImageListItem key={e.img}>
                      <img src={e.img} alt={e.img} loading="lazy" style={{ borderRadius: "5px" }} />
                      <ImageListItemBar
                        sx={{
                          background:
                            "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                            "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                        }}
                        position="top"
                        actionIcon={
                          <IconButton
                            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                            aria-label={`info about ${e.img}`}
                            onClick={() => {
                              window.open(e.img, "_blank");
                            }}
                          >
                            <ViewIcon />
                          </IconButton>
                        }
                        actionPosition="left"
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </Grid>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}
