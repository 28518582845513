// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components

// @mui material components
import Icon from "@mui/material/Icon";

// @mui material components
import Grid from "@mui/material/Grid";

// react-router-dom components
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Authentication layout components
import SysDashLayout from "../../components_reefer/Layouts/SysDashLayout";
import authService from "../../helpers/auth.service";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDAvatar from "../../components/MDAvatar";

// I18N

function SystemDash() {
  const { t } = useTranslation("common");
  const usr = authService.getCurrentUser();
  if (usr == null) {
    window.location.href = "/authentication/sign-in";
  }
  return (
    <SysDashLayout>
      <Card p={1}>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            {t("common.systemSelect")}
          </MDTypography>
        </MDBox>
        <MDBox p={1} m={2}>
          <Grid container spacing={3} justifyContent="center" alignItems="center">
            {usr.isSysAdmin === 1 || usr.isOperational === 1 ? (
              // MONITORAMENTO
              <Grid item xs={11} sm={11} md={4} lg={4} xl={4} my={2}>
                <Card
                  my={1}
                  style={{
                    backgroundColor: "rgba(128, 128, 128, 0.2)",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  component={Link}
                  to="/monitor"
                  pt={1}
                >
                  <MDAvatar
                    size="xl"
                    shadow="md"
                    style={{ marginTop: "0.75rem", marginBottom: "0.75rem" }}
                    bgColor="info"
                  >
                    <Icon fontSize="large">device_thermostat</Icon>
                  </MDAvatar>
                  <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    width="90%"
                    mx={2}
                    // mt={1}
                    p={0.5}
                    mb={-3}
                    textAlign="center"
                  >
                    <MDTypography color="white" mt={1}>
                      {t("menu.ops")}
                    </MDTypography>
                  </MDBox>
                </Card>
                {/* </Grid> */}
              </Grid>
            ) : null}

            {usr.isSysAdmin === 1 ? (
              // AJUSTES E AUDITORIA
              <Grid item xs={11} sm={11} md={4} lg={4} xl={4} my={2}>
                <Card
                  my={1}
                  style={{
                    backgroundColor: "rgba(128, 128, 128, 0.2)",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  component={Link}
                  to="/adjusts"
                  pt={1}
                >
                  <MDAvatar
                    size="xl"
                    shadow="md"
                    style={{ marginTop: "0.75rem", marginBottom: "0.75rem" }}
                    bgColor="info"
                  >
                    <Icon fontSize="large">content_pasteSearch</Icon>
                  </MDAvatar>
                  <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    width="90%"
                    mx={2}
                    // mt={1}
                    p={0.5}
                    mb={-3}
                    textAlign="center"
                  >
                    <MDTypography color="white" mt={1}>
                      {t("menu.adj")}
                    </MDTypography>
                  </MDBox>
                </Card>
              </Grid>
            ) : null}

            {usr.isSysAdmin === 1 || usr.isAccountAdmin === 1 || usr.isOPAdmin === 1 ? (
              // CONSULTAS
              <Grid item xs={11} sm={11} md={4} lg={4} xl={4} my={2}>
                <Card
                  my={1}
                  style={{
                    backgroundColor: "rgba(128, 128, 128, 0.2)",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  component={Link}
                  to="/queries"
                  pt={1}
                >
                  <MDAvatar
                    size="xl"
                    shadow="md"
                    style={{ marginTop: "0.75rem", marginBottom: "0.75rem" }}
                    bgColor="info"
                  >
                    <Icon fontSize="large">search</Icon>
                  </MDAvatar>
                  <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    width="90%"
                    mx={2}
                    // mt={1}
                    p={0.5}
                    mb={-3}
                    textAlign="center"
                  >
                    <MDTypography color="white" mt={1}>
                      {t("menu.queries")}
                    </MDTypography>
                  </MDBox>
                </Card>
              </Grid>
            ) : null}

            {usr.isSysAdmin === 1 || usr.isAccountAdmin === 1 || usr.isFinancial === 1 ? (
              // RELATORIOS
              <Grid item xs={11} sm={11} md={4} lg={4} xl={4} my={2}>
                <Card
                  my={1}
                  style={{
                    backgroundColor: "rgba(128, 128, 128, 0.2)",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  component={Link}
                  to="/reports"
                  pt={1}
                >
                  <MDAvatar
                    size="xl"
                    shadow="md"
                    style={{ marginTop: "0.75rem", marginBottom: "0.75rem" }}
                    bgColor="info"
                  >
                    <Icon fontSize="large">newspaper</Icon>
                  </MDAvatar>
                  <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    width="90%"
                    mx={2}
                    // mt={1}
                    p={0.5}
                    mb={-3}
                    textAlign="center"
                  >
                    <MDTypography color="white" mt={1}>
                      {t("menu.rep")}
                    </MDTypography>
                  </MDBox>
                </Card>
              </Grid>
            ) : null}

            {usr.isSysAdmin === 1 ? (
              // MOVIMENTAÇÃO
              <Grid item xs={11} sm={11} md={4} lg={4} xl={4} my={2}>
                <Card
                  my={1}
                  style={{
                    backgroundColor: "rgba(128, 128, 128, 0.2)",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  component={Link}
                  to="/moviments"
                  pt={1}
                >
                  <MDAvatar
                    size="xl"
                    shadow="md"
                    style={{ marginTop: "0.75rem", marginBottom: "0.75rem" }}
                    bgColor="info"
                  >
                    <Icon fontSize="large">compare_arrows</Icon>
                  </MDAvatar>
                  <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    width="90%"
                    mx={2}
                    // mt={1}
                    p={0.5}
                    mb={-3}
                    textAlign="center"
                  >
                    <MDTypography color="white" mt={1}>
                      {t("menu.trv")}
                    </MDTypography>
                  </MDBox>
                </Card>
              </Grid>
            ) : null}

            {usr.isSysAdmin === 1 ||
            usr.isAccountAdmin === 1 ||
            usr.isOPAdmin === 1 ||
            usr.isOperational === 1 ||
            usr.isFinancial === 1 ? (
              // PAINEL DE DADOS
              <Grid item xs={11} sm={11} md={4} lg={4} xl={4} my={2}>
                <Card
                  my={1}
                  style={{
                    backgroundColor: "rgba(128, 128, 128, 0.2)",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  component={Link}
                  to="/dashboard"
                  pt={1}
                >
                  <MDAvatar
                    size="xl"
                    shadow="md"
                    style={{ marginTop: "0.75rem", marginBottom: "0.75rem" }}
                    bgColor="info"
                  >
                    <Icon fontSize="large">dashboard</Icon>
                  </MDAvatar>
                  <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    width="90%"
                    mx={2}
                    // mt={1}
                    p={0.5}
                    mb={-3}
                    textAlign="center"
                  >
                    <MDTypography color="white" mt={1}>
                      {t("menu.dash")}
                    </MDTypography>
                  </MDBox>
                </Card>
              </Grid>
            ) : null}

            {usr.isSysAdmin === 1 || usr.isOperational === 1 ? (
              // MANUTENÇÃO
              <Grid item xs={11} sm={11} md={4} lg={4} xl={4} my={2}>
                <Card
                  my={1}
                  style={{
                    backgroundColor: "rgba(128, 128, 128, 0.2)",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  component={Link}
                  to="/maintenance"
                  pt={1}
                >
                  <MDAvatar
                    size="xl"
                    shadow="md"
                    style={{ marginTop: "0.75rem", marginBottom: "0.75rem" }}
                    bgColor="info"
                  >
                    <Icon fontSize="large">handyman</Icon>
                  </MDAvatar>
                  <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    width="90%"
                    mx={2}
                    // mt={1}
                    p={0.5}
                    mb={-3}
                    textAlign="center"
                  >
                    <MDTypography color="white" mt={1}>
                      {t("menu.maintenance")}
                    </MDTypography>
                  </MDBox>
                </Card>
              </Grid>
            ) : null}

            {usr.isSysAdmin === 1 ? (
              // ADMINISTRADOR
              <Grid item xs={11} sm={11} md={4} lg={4} xl={4} my={2}>
                <Card
                  my={1}
                  style={{
                    backgroundColor: "rgba(128, 128, 128, 0.2)",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  component={Link}
                  to="/account"
                  pt={1}
                >
                  <MDAvatar
                    size="xl"
                    shadow="md"
                    style={{ marginTop: "0.75rem", marginBottom: "0.75rem" }}
                    bgColor="info"
                  >
                    <Icon fontSize="large">fingerprint</Icon>
                  </MDAvatar>
                  <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    width="90%"
                    mx={2}
                    // mt={1}
                    p={0.5}
                    mb={-3}
                    textAlign="center"
                  >
                    <MDTypography color="white" mt={1}>
                      {t("menu.admin")}
                    </MDTypography>
                  </MDBox>
                </Card>
              </Grid>
            ) : null}

            {usr.isSysAdmin === 1 || usr.isAccountAdmin === 1 ? (
              // AJUDA
              <Grid item xs={11} sm={11} md={4} lg={4} xl={4} my={2}>
                <Card
                  my={1}
                  style={{
                    backgroundColor: "rgba(128, 128, 128, 0.2)",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  component={Link}
                  to="/help"
                  pt={1}
                >
                  <MDAvatar
                    size="xl"
                    shadow="md"
                    style={{ marginTop: "0.75rem", marginBottom: "0.75rem" }}
                    bgColor="info"
                  >
                    <Icon fontSize="large">help</Icon>
                  </MDAvatar>
                  <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    width="90%"
                    mx={2}
                    // mt={1}
                    p={0.5}
                    mb={-3}
                    textAlign="center"
                  >
                    <MDTypography color="white" mt={1}>
                      {t("menu.help")}
                    </MDTypography>
                  </MDBox>
                </Card>
              </Grid>
            ) : null}
          </Grid>
        </MDBox>
      </Card>
    </SysDashLayout>
  );
}

export default SystemDash;
