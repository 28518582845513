/* eslint-disable react/jsx-no-bind */
import { useState, useEffect } from "react";

// react-router components
import { Link } from "react-router-dom";
// import { Spacer } from "react-spacer";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 React example components
import DefaultNavbarMobile from "components_reefer/Navbars/DefaultNavbar/DefaultNavbarMobile";

// Material Dashboard 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Material Dashboard 2 React context
import { useMaterialUIController } from "context";

// I18N
import { useTranslation } from "react-i18next";

import NotificationItem from "components_reefer/NotificationItem";

// Images
import logo from "assets/images/logos/Marca3.png";
import flagEN from "assets/images/icons/flags/US.png";
import flagENdisable from "assets/images/icons/flags/USdisable.png";
import flagBR from "assets/images/icons/flags/BR.png";
import flagBRdisable from "assets/images/icons/flags/BRdisable.png";
import authService from "helpers/auth.service";

function SystemNavbar({ transparent, light }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [mobileNavbar, setMobileNavbar] = useState(false);

  const [i18nflagEN, setI18nflagEN] = useState(flagENdisable);
  const [i18nflagBR, setI18nflagBR] = useState(flagBR);
  const [mobileView, setMobileView] = useState(false);

  const [openMenu, setOpenMenu] = useState(false);

  const closeMobileNavbar = () => setMobileNavbar(false);
  // const Spacer = require('react-spacer')

  const user = authService.getCurrentUser();
  if (user == null) {
    window.location.href = "/authentication/sign-in";
  }
  // console.log(user);

  const { t, i18n } = useTranslation("common");

  function handleLocateClick(e) {
    if (e === "en") {
      i18n.changeLanguage("en");
      setI18nflagBR(flagBRdisable);
      setI18nflagEN(flagEN);
    } else {
      i18n.changeLanguage("pt");
      setI18nflagEN(flagENdisable);
      setI18nflagBR(flagBR);
    }
  }

  function logout() {
    authService.logout();
    window.location.href = "/authentication/sign-in";
  }

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        component={Link}
        to="/sysdash"
        icon={<Icon>snippet_folder</Icon>}
        title={t("common.systems")}
      />
      <NotificationItem onClick={logout} icon={<Icon>logout</Icon>} title={t("common.signout")} />
    </Menu>
  );

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
      if (i18n.language === "pt") {
        setI18nflagBR(flagBR);
        setI18nflagEN(flagENdisable);
      } else {
        setI18nflagBR(flagBRdisable);
        setI18nflagEN(flagEN);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  return (
    <Container>
      <MDBox
        py={1}
        px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
        my={3}
        mx={3}
        width="calc(100% - 48px)"
        borderRadius="lg"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="absolute"
        left={0}
        zIndex={3}
        sx={({
          palette: { transparent: transparentColor, white, background },
          functions: { rgba },
        }) => ({
          backgroundColor: transparent
            ? transparentColor.main
            : rgba(darkMode ? background.sidenav : white.main, 0.8),
          backdropFilter: transparent ? "none" : "saturate(200%) blur(30px)",
        })}
      >
        <MDBox
          component={Link}
          to="/sysdash"
          py={transparent ? 1.5 : 0.75}
          lineHeight={1}
          pl={{ xs: 0, lg: 1 }}
        >
          <MDTypography variant="button" fontWeight="bold" color={light ? "white" : "dark"}>
            <div>
              <img src={logo} alt="logo" width={100} />
            </div>
          </MDTypography>
        </MDBox>
        <MDBox color="inherit" display={{ xs: "flex", lg: "flex" }} m={0} p={0}>
          <MDBox
            lineHeight={2}
            onClick={handleOpenMenu}
            pl={{ xs: 0, lg: 1 }}
            py={transparent ? 1.5 : 0.75}
          >
            <MDTypography variant="button" fontWeight="regular" color={light ? "white" : "dark"}>
              {/* <Icon sx={iconsStyle} fontSize="medium">account_circle</Icon> */}
              {user.name}
            </MDTypography>
          </MDBox>
          {renderMenu()}
          <MDBox display="inline-block" margin="8px">
            <MDAvatar
              src={i18nflagBR}
              name={i18n.locale}
              size="sm"
              onClick={() => {
                handleLocateClick("br");
              }}
            />
          </MDBox>
          <MDBox display="inline-block" margin="8px">
            <MDAvatar
              src={i18nflagEN}
              name={i18n.locale}
              size="sm"
              onClick={() => {
                handleLocateClick("en");
              }}
            />
          </MDBox>
        </MDBox>
      </MDBox>
      {mobileView && <DefaultNavbarMobile open={mobileNavbar} close={closeMobileNavbar} />}
    </Container>
  );
}

// Setting default values for the props of SystemNavbar
SystemNavbar.defaultProps = {
  transparent: false,
  light: false,
};

// Typechecking props for the SystemNavbar
SystemNavbar.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
};

export default SystemNavbar;
