/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable global-require */
/* eslint-disable react/prop-types */
import * as React from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";

import Icon from "@mui/material/Icon";

import { createTheme, ThemeProvider } from "@mui/material/styles";

// I18N
import { useTranslation } from "react-i18next";
import Logo from "../../assets/images/logos/logo.png";

export default function ReportHeader() {
  const theme = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "2.0rem",
            color: "#fff",
          },
        },
      },
    },
  });

  const themeRed = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "2.0rem",
            color: "red",
          },
        },
      },
    },
  });

  const themeYellow = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "2.0rem",
            color: "yellow",
          },
        },
      },
    },
  });

  const themeOcean = createTheme({
    components: {
      MuiIcon: {
        styleOverrides: {
          root: {
            fontSize: "2.0rem",
            color: "#a6dced",
          },
        },
      },
    },
  });

  function MaintenanceIcon({ size }) {
    return (
      <img
        src={require("../../assets/images/icons/maintenance.png")} // Certifique-se de ajustar o caminho da imagem conforme necessário
        alt="Ícone"
        style={{
          width: size,
          height: size,
          filter: "brightness(0) invert(1)",
        }}
      />
    );
  }

  const { t } = useTranslation("common");

  return (
    <>
      <Grid container>
        <Grid xs={12} item>
          <Box style={{ display: "flex", justifyContent: "right" }} className="text-title">
            <Alert severity="info">
              Por especificação dos fabricantes, a leitura de temperatura possui tolerância de +/-
              3C°
            </Alert>
          </Box>
        </Grid>
      </Grid>
      {/* <Grid container>
        <Grid xs={12} item>
          <Box style={{ display: "flex", justifyContent: "right" }} className="text-title">
            <Alert severity="info">texto sobre a casa decimal</Alert>
          </Box>
        </Grid>
      </Grid> */}
      <Grid container>
        <Grid xs={12} item>
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <img className="report-logo" src={Logo} alt="Imagem" />
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={12} item>
          <Box style={{ display: "flex", justifyContent: "center" }} className="text-cnpj">
            CNPJ 09.380.074.0001/75
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid xs={12} item>
          <Box style={{ display: "flex", justifyContent: "center" }} className="text-title">
            {t("reports.title_monitoring")}
          </Box>
        </Grid>
      </Grid>
      <Container maxWidth="md">
        <Grid container sx={{ flexGrow: 1, p: 2, mt: 1 }}>
          <Grid item xs={12} sm={4} md={2} className="grid-icon">
            <FormControl>
              <Grid container>
                <Grid item xs={12} className="grid-icon">
                  <Box className="box-icon-yellow">
                    <ThemeProvider theme={theme}>
                      <Icon>warning_amber </Icon>
                    </ThemeProvider>
                  </Box>
                </Grid>
                <Grid item xs={12} className="grid-icon">
                  <Box className="bx-icon-text">{t("reports.rep_low")}</Box>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={2} className="grid-icon">
            <FormControl>
              <Grid container>
                <Grid item xs={12} className="grid-icon">
                  <Box className="box-icon-red">
                    <ThemeProvider theme={theme}>
                      <Icon>highlight_off </Icon>
                    </ThemeProvider>
                  </Box>
                </Grid>
                <Grid item xs={12} className="grid-icon">
                  <Box className="bx-icon-text">{t("reports.rep_temperature")}</Box>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={2} className="grid-icon">
            <FormControl>
              <Grid container>
                <Grid item xs={12} className="grid-icon">
                  <Box className="box-icon-black">
                    <ThemeProvider theme={themeRed}>
                      <Icon>notification_important_outlined </Icon>
                    </ThemeProvider>
                  </Box>
                </Grid>
                <Grid item xs={12} className="grid-icon">
                  <Box className="bx-icon-text">{t("reports.rep_problem")}</Box>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={2} className="grid-icon">
            <FormControl>
              <Grid container>
                <Grid item xs={12} className="grid-icon">
                  <Box className="box-icon-black">
                    <ThemeProvider theme={themeYellow}>
                      <Icon>power_off_outlined </Icon>
                    </ThemeProvider>
                  </Box>
                </Grid>
                <Grid item xs={12} className="grid-icon">
                  <Box className="bx-icon-text">{t("reports.rep_feeding")}</Box>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={2} className="grid-icon">
            <FormControl>
              <Grid container>
                <Grid item xs={12} className="grid-icon">
                  <Box className="box-icon-black">
                    <ThemeProvider theme={themeOcean}>
                      <Icon>outlet_outlined </Icon>
                    </ThemeProvider>
                  </Box>
                </Grid>
                <Grid item xs={12} className="grid-icon">
                  <Box className="bx-icon-text">{t("reports.rep_energy")}</Box>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={2} className="grid-icon">
            <FormControl>
              <Grid container>
                <Grid item xs={12} className="grid-icon">
                  <Box className="box-icon-purple">
                    <ThemeProvider theme={theme}>
                      <MaintenanceIcon size={32} />
                    </ThemeProvider>
                  </Box>
                </Grid>
                <Grid item xs={12} className="grid-icon">
                  <Box className="bx-icon-text">{t("reports.rep_preventive")}</Box>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
