import * as XLSX from "xlsx";
import formatDate from "../../../../helpers/formatDate";
import formatHour from "../../../../helpers/formatHour";

const XlsxQueriesComplete = (props) => {
  const wb = XLSX.utils.book_new();

  console.log("entrou por engano no queries");

  const data = [
    [
      "NAVIO",
      "CONTAINER",
      "PERIODO",
      "ENTRADA",
      "SAIDA",
      "SETPOINT",
      "TEMPERATURA",
      "EVIDÊNCIAS DE PROBLEMAS",
      "CLIENTE",
      "POSICAO",
    ],
  ];
  const keys = Object.keys(props?.reportData.containers);
  keys.forEach((key) => {
    const e = [];
    const dateErr = [];
    const columnSpacing = [];
    props.reportData.containers[key].monitoring.forEach((m) => {
      if (m.errType > 0) {
        e.push(m.errType);
        const fDate = formatDate(m.read_timestamp);
        const fHour = parseFloat(formatHour(m.read_timestamp));
        if (fHour <= 12) {
          dateErr.push(`${fDate} - AM        `);
        } else {
          dateErr.push(`${fDate} - PM        `);
        }
      }
      const periodMonitoring = parseFloat(
        formatHour(props.content.reportData.containers[key].monitoring[m].read_timestamp)
      );
      data.push([
        props.ship,
        key,
        periodMonitoring <= 12 ? "AM" : "PM",
        props.reportData.travel,
        props.reportData.containers[key].monitoring[m].temperature,
        props.reportData.containers[key].monitoring[m].am,
        props.reportData.containers[key].monitoring[m].pm,
        props.reportData.containers[key].monitoring[m].err,
        dateErr.join(""),
        columnSpacing,
      ]);
      console.log(m);
    });
  });
  console.log(props);
  wb.Props = {
    Title: "Espelho de Monitoramento",
    Subject: "Relatório espelho em formato de excel",
    Author: "Kevlan Costa",
    CreatedDate: new Date(),
  };

  wb.SheetNames.push("Relatório Completo");

  const ws = XLSX.utils.aoa_to_sheet(data);

  wb.Sheets["Relatório diário Completo"] = ws;

  XLSX.writeFile(wb, "Relatório Completo.xlsx", { bookType: "xlsx", type: "binary" });
};

export default XlsxQueriesComplete;
