/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
import * as React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import { useTranslation } from "react-i18next";
import axios from "axios";
import config from "../../../../config/config.json";
import authHeader from "../../../../helpers/auth-headers";
import authService from "../../../../helpers/auth.service";

export default function data() {
  const { t } = useTranslation("common");
  const [dataList, setDataList] = React.useState([]);
  const [dashData, setDashData] = React.useState([]);
  const { API_URL } = config;
  const user = authService.getCurrentUser();

  const Company = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDTypography variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {name}
      </MDTypography>
    </MDBox>
  );

  React.useEffect(() => {
    const fetchData = async () => {
      const tmpData = [];
      if (dataList.length === 0) {
        const tmpAcct = [];
        await axios
          .get(`${API_URL}accounts/${user.accountsIdaccounts}`, { headers: authHeader() })
          .then(
            (response) => {
              // tmpData.periodMonitAM = response.data.monitoringByAM;
              // tmpData.periodMonitPM = response.data.monitoringByPM;
              tmpAcct.push(response.data);
            },
            (error) => {
              console.log(error);
            }
          );
        await axios
          .get(`${API_URL}dashboard/${user.accountsIdaccounts}`, { headers: authHeader() })
          .then(
            (response) => {
              // console.log("responses------", response.data);
              response.data.forEach((m) => {
                m.patios.forEach((p) => {
                  const tempEl = {};
                  if (p.isActive === 1) {
                    tempEl.MonitAM = p.totalAM;
                    tempEl.MonitPM = p.totalPM;
                    tempEl.isActive = p.isActive;
                    tempEl.periodMonitAM = tmpAcct[0].monitoringByAM;
                    tempEl.periodMonitPM = tmpAcct[0].monitoringByPM;
                    tempEl.somamonit = tempEl.MonitAM + tempEl.MonitPM;
                    tempEl.somatotal = tempEl.periodMonitAM + tempEl.periodMonitPM;
                    tempEl.percent = (tempEl.somamonit * 100) / tempEl.somatotal;
                    tempEl.name = p.name;
                    tmpData.push(tempEl);
                  }
                });
              });
            },
            (error) => {
              console.log(error);
            }
          );
        setDashData(tmpData);
      }
    };
    fetchData();
  }, []);
  React.useEffect(() => {
    if (dashData.length === 0) {
      return;
    }
    setDataList(
      dashData.map((p) => ({
        companies: <Company name={p.name} />,
        monitoringAM: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {`${p.MonitAM}/${p.periodMonitAM}`}
          </MDTypography>
        ),
        monitoringPM: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {`${p.MonitPM}/${p.periodMonitPM}`}
          </MDTypography>
        ),
        completion: (
          <MDBox width="8rem" textAlign="left">
            <MDProgress value={p.percent} color="info" variant="gradient" label={false} />
          </MDBox>
        ),
      }))
    );
  }, [dashData]);

  return {
    columns: [
      { Header: t("dashboard.dash_dock"), accessor: "companies", align: "center" },
      { Header: t("dashboard.dash_monitoringAM"), accessor: "monitoringAM", align: "center" },
      { Header: t("dashboard.dash_monitoringPM"), accessor: "monitoringPM", align: "center" },
      { Header: t("dashboard.dash_progress"), accessor: "completion", align: "center" },
    ],

    rows: dataList,
  };
}
