import * as XLSX from "xlsx";
import formatDate from "../../../../helpers/formatDate";
import formatHour from "../../../../helpers/formatHour";

const XlsxDaily = (props) => {
  const data = [
    ["CONTAINER", "VIAGEM DE ENTRADA", "SETPOINT", "AM", "PM", "FALHAS", "EVIDÊNCIAS DE PROBLEMAS"],
  ];
  const keys = Object.keys(props?.reportData.containers);
  keys.forEach((key) => {
    const e = [];
    const dateErr = [];
    const columnSpacing = [];
    props.reportData.containers[key].monitoring.forEach((m) => {
      if (m.errType > 0) {
        e.push(m.errType);
        const fDate = formatDate(m.read_timestamp);
        const fHour = parseFloat(formatHour(m.read_timestamp));
        if (fHour <= 12) {
          dateErr.push(`${fDate} - AM        `);
        } else {
          dateErr.push(`${fDate} - PM        `);
        }
      }
    });
    data.push([
      key,
      props.reportData.travel,
      props.reportData.containers[key].temperature,
      props.reportData.containers[key].am,
      props.reportData.containers[key].pm,
      props.reportData.containers[key].err,
      dateErr.join(""),
      columnSpacing,
    ]);
  });

  const wb = XLSX.utils.book_new();
  console.log(props);
  wb.Props = {
    Title: "Espelho diário",
    Subject: "Relatório diário espelho em formato de excel",
    Author: "Kevlan Costa",
    CreatedDate: new Date(),
  };

  wb.SheetNames.push("Relatório diário");

  const ws = XLSX.utils.aoa_to_sheet(data);

  wb.Sheets["Relatório diário"] = ws;

  XLSX.writeFile(wb, "Relatório diário.xlsx", { bookType: "xlsx", type: "binary" });
};

export default XlsxDaily;
