/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";
import FormControl from "@mui/material/FormControl";
import formatDate from "../../../helpers/formatDate";
import formatHour from "../../../helpers/formatHour";
import ReportErr from "../Err/ReportErr";
import DataTable from "../../Tables/DataTable";

export default function Travel(props) {
  const { t } = useTranslation("common");
  const [rows, setRows] = React.useState([]);
  const errList = {
    1: { icon: "warning_amber", color: "box-icon-yellow" },
    2: { icon: "highlight_off", color: "box-icon-red" },
    3: { icon: "highlight_off", color: "box-icon-red" },
    4: { icon: "notification_important_outlined", color: "box-icon-black" },
    6: { icon: "power_off_outlined", color: "box-icon-black" },
    8: { icon: "outlet_outlined ", color: "box-icon-black" },
  };

  const columns = [
    { Header: t("table_queries.sheet_container"), accessor: "container", align: "left" },
    { Header: t("table_queries.sheet_date"), accessor: "date", align: "left" },
    { Header: t("table_queries.sheet_period"), accessor: "period", align: "left" },
    { Header: t("table_queries.sheet_in-ship-travel"), accessor: "in", align: "center" },
    { Header: t("table_queries.sheet_out-ship-travel"), accessor: "out", align: "center" },
    { Header: t("table_queries.sheet_setpoint"), accessor: "setPoint", align: "left" },
    { Header: t("table_queries.sheet_temp"), accessor: "temp", align: "left" },
    { Header: t("table_queries.sheet_totalFail"), accessor: "totalFails", align: "left" },
    { Header: t("table_queries.sheet_consig"), accessor: "consig", align: "left" },
    { Header: t("table_queries.sheet_position"), accessor: "position", align: "left" },
  ];
  console.log("TRAVEL:", props);
  React.useEffect(() => {
    if (rows.length > 0) {
      return;
    }
    const tmpRow = [];
    const keys = Object.keys(props.content.reportData.containers);

    keys.forEach((key) => {
      const chave = Object.keys(props.content.reportData.containers[key].monitoring);
      const e = [];
      chave.forEach((m) => {
        if (props.content.reportData.containers[key].monitoring[m].errType > 0) {
          e.push(props.content.reportData.containers[key].monitoring[m]);
        }
        // const hourIn = parseFloat(formatHour(props.content.reportData.containers[key].date_in));
        // const hourOut = parseFloat(formatHour(props.content.reportData.containers[key].date_out));
        const dateIn = formatDate(props.content.reportData.containers[key].date_in);
        const dateOut = formatDate(props.content.reportData.containers[key].date_out);
        const dateMonitoring = formatDate(
          props.content.reportData.containers[key].monitoring[m].read_timestamp
        );
        const periodMonitoring = parseFloat(
          formatHour(props.content.reportData.containers[key].monitoring[m].read_timestamp)
        );
        const { ship } = props.content;
        const { travel } = props.content.reportData;

        tmpRow.push({
          container: key,
          date: dateMonitoring,
          period: periodMonitoring <= 12 ? "AM" : "PM",
          in: `${dateIn} ${ship} - ${travel}`,
          out: `${dateOut} ${ship} - ${travel}`,
          setPoint: props.content.reportData.containers[key].monitoring[m].temperature,
          temp: props.content.reportData.containers[key].temperature,
          consig: props.content.reportData.containers[key].consig,
          position: props.content.reportData.containers[key].position,
          totalFails: (
            <Grid container>
              {e.map((p) => (
                <>
                  <Grid xs={3} className="grid-err-icon">
                    <FormControl>
                      <Grid container>
                        <Box>
                          <Grid container className="icon-err">
                            <ReportErr
                              icon={errList[p.errType].icon}
                              color={errList[p.errType].color}
                            />
                          </Grid>
                          {p.read_timestamp.length > 0 &&
                          parseFloat(formatHour(p.read_timestamp)) <= 12
                            ? `${formatDate(p.read_timestamp)} - AM`
                            : null}
                          {p.read_timestamp.length > 0 &&
                          parseFloat(formatHour(p.read_timestamp)) >= 12
                            ? `${formatDate(p.read_timestamp)} - PM`
                            : null}
                          <Box xs={6} className=".icon-date-text">
                            {p.notes}
                          </Box>
                        </Box>
                      </Grid>
                    </FormControl>
                  </Grid>
                  <Grid className="grid-gallery-icon">
                    <FormControl>
                      <Grid container>
                        <Box>
                          {p.photos.length > 0 ? (
                            <Icon
                              fontSize="large"
                              onClick={() => {
                                const url =
                                  "https://s3.sa-east-1.amazonaws.com/report-assets.reefersys.com/";
                                window.open(url + p.photos, "_blank");
                              }}
                            >
                              image_outlined
                            </Icon>
                          ) : null}
                        </Box>
                      </Grid>
                    </FormControl>
                  </Grid>
                </>
              ))}
            </Grid>
          ),
        });
      });
    });
    setRows(tmpRow);
  }, []);

  return (
    <>
      <div style={{ width: "100%" }}>
        <DataTable
          table={{ columns, rows }}
          isSorted={false}
          entriesPerPage={false}
          showTotalEntries={false}
          noEndBorder
        />
      </div>
      <Grid container>
        <Grid xs={12} mt={4} mb={4} item>
          <Box style={{ display: "flex", justifyContent: "center" }} className="text-title">
            {`${t("reports.title_total_monitoring")} ${props?.content.reportData.totalMonitoring}`}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
