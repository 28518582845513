/* eslint-disable class-methods-use-this */
import axios from "axios";
import config from "../config/config.json";

const { API_URL } = config;
let user;
class AuthService {
  async login(username, password, cb) {
    localStorage.removeItem("user");
    const res = await axios.post(`${API_URL}auth/authenticate`, { name: username, password });
    if (res.data) {
      localStorage.setItem("user", JSON.stringify(res.data));
      user = res.data;
      cb();
    }
  }

  async resetPassword(username, cb) {
    axios
      .post(`${API_URL}auth/resetpassword`, { name: username })
      .then(() => {
        cb();
      })
      .catch((err) => {
        console.log(err);
        console.log("erro de atenticação");
      });
  }

  // confirmPassword can be separately built out as follows...
  async confirmPassword(username, verificationCode, newPassword) {
    await axios.post(`${API_URL}auth/confirmPassword`, {
      name: username,
      verificationCode,
      password: newPassword,
    });
  }

  checkLastLogin() {
    const userStr = localStorage.getItem("user");
    if (userStr) user = JSON.parse(userStr);
    return user;
  }

  async logout() {
    localStorage.removeItem("user");
    user = null;
  }

  register(username, email, password) {
    return axios.post(`${API_URL}signup`, {
      username,
      email,
      password,
    });
  }

  getCurrentUser() {
    if (user == null) {
      const userStr = localStorage.getItem("user");
      if (userStr) user = JSON.parse(userStr);
    }
    // console.log(user)
    return user;
  }
}
export default new AuthService();
