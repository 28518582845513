import Card from "@mui/material/Card";
import { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import validator from "validator";
import { useTranslation } from "react-i18next";
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";
import MDInput from "../../../../components/MDInput";
import MDButton from "../../../../components/MDButton";
import bgImage from "../../../../assets/images/fundo001.jpg";
import BasicLayout from "../../../../components_reefer/Layouts/BasicLayout";
import AuthService from "../../../../helpers/auth.service";

function Cover() {
  const [code, setCode] = useState("");
  const [pass, setPass] = useState("");
  const [passChk, setPassChk] = useState("");
  const [error, setError] = useState(true);
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState("");
  const onAuth = () => {
    navigate("/authentication/sign-in");
  };

  const { t } = useTranslation("common");

  useEffect(() => {
    if (pass === passChk) {
      setError(false);
    } else {
      setError(true);
    }
  }, [pass, passChk]);

  const onClickReset = () => {
    if (error) {
      return;
    }
    const usr = searchparams.get("username");
    AuthService.confirmPassword(usr, code, pass);
    onAuth();
  };

  const validate = (value) => {
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 1,
      })
    ) {
      setErrorMessage(t("auth.strong_pswd"));
      setPass(value);
    } else {
      setErrorMessage(t("auth.notstrong_pswd"));
    }
  };
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            {t("auth.title_reset")}
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            {t("auth.msg_reset")}
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={4}>
              <MDInput
                type="text"
                label={t("auth.code")}
                variant="standard"
                fullWidth
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onClickReset();
                  }
                }}
              />
            </MDBox>
            <MDBox mb={4}>
              <MDInput
                type="password"
                label={t("auth.new_password")}
                variant="standard"
                fullWidth
                onChange={(e) => {
                  validate(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onClickReset();
                  }
                }}
              />
              {errorMessage === t("auth.strong_pswd") ? (
                <MDTypography variant="overline" fontWeight="medium" color="success">
                  {errorMessage}
                </MDTypography>
              ) : (
                <MDTypography variant="overline" fontWeight="medium" color="primary">
                  {errorMessage}
                  <MDBox>
                    <MDTypography variant="subtitle2">{t("auth.characters")}</MDTypography>
                    <MDTypography variant="subtitle2">{t("auth.numbers")}</MDTypography>
                    <MDTypography variant="subtitle2">{t("auth.especial")}</MDTypography>
                    <MDTypography variant="subtitle2">{t("auth.upper")}</MDTypography>
                    <MDTypography variant="subtitle2">{t("auth.lower")}</MDTypography>
                  </MDBox>
                </MDTypography>
              )}
            </MDBox>
            <MDBox mb={4}>
              <MDInput
                type="password"
                label={t("auth.repeat_password")}
                variant="standard"
                fullWidth
                onChange={(e) => {
                  setPassChk(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onClickReset();
                  }
                }}
              />
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" mt={1} color="info" fullWidth onClick={onClickReset}>
                {t("auth.button_reset")}
              </MDButton>
            </MDBox>
            <MDBox mt={1} mb={1}>
              <MDButton
                variant="gradient"
                mt={1}
                color="info"
                fullWidth
                component={Link}
                to="/authentication/sign-in"
              >
                {t("auth.button_cancel")}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
