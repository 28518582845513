/* eslint-disable react/jsx-key */
import * as React from "react";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ComplexStatisticsCard from "../../../../components_reefer/Cards/StatisticsCards/ComplexStatisticsCard";
import MDBox from "../../../../components/MDBox";
import authHeader from "../../../../helpers/auth-headers";
import authService from "../../../../helpers/auth.service";
import config from "../../../../config/config.json";

function OrdersOverview() {
  const { t } = useTranslation("common");
  const [containersList, setContainersList] = React.useState([]);
  const { API_URL } = config;
  const user = authService.getCurrentUser();

  React.useEffect(() => {
    const fetchData = async () => {
      const tmpContainers = [];
      if (containersList.length === 0) {
        await axios
          .get(`${API_URL}dashboard/${user.accountsIdaccounts}`, { headers: authHeader() })
          .then(
            (response) => {
              response.data.forEach((m) => {
                let containersTotal = 0;
                let totalMonitoring = 0;
                let containersErr = 0;
                m.patios.forEach((p) => {
                  if (p.isActive === 1) {
                    containersTotal += p.containersComplete.length;
                    totalMonitoring += p.containersMonitored.length;
                    containersErr += p.monitUniqueErr;
                  }
                });
                tmpContainers.push({
                  totalCont: containersTotal,
                  totalMonit: totalMonitoring,
                  totalErr: containersErr,
                });
              });
            },
            (error) => {
              console.log(error);
            }
          );
        console.log(tmpContainers);
        setContainersList(tmpContainers);
      }
    };
    fetchData();
  }, []);

  return (
    <MDBox pt={1} pb={3}>
      <Grid container spacing={8} px={2}>
        {containersList.length > 0
          ? containersList.map((p) => (
              <Grid item xs={12} md={6} lg={6}>
                <ComplexStatisticsCard
                  icon="fitbit"
                  color="info"
                  Err={`${t("dashboard.dash_contPatios")}`}
                  title={`${p.totalCont}`}
                />
              </Grid>
            ))
          : null}
        {containersList.length > 0
          ? containersList.map((p) => (
              <Grid item xs={12} md={6} lg={6}>
                <ComplexStatisticsCard
                  key={p.totalCont}
                  value={p.contTd}
                  icon="checkbox"
                  color="success"
                  Err={`${t("dashboard.dash_contMonit")}`}
                  title={`${p.totalMonit}`}
                />
              </Grid>
            ))
          : null}
        {containersList.length > 0
          ? containersList.map((p) => (
              <Grid item xs={12} md={6} lg={6}>
                <ComplexStatisticsCard
                  key={p.totalCont}
                  value={p.contTd}
                  icon="error"
                  color="primary"
                  Err={`${t("dashboard.dash_errs")}`}
                  title={`${p.totalErr}`}
                />
              </Grid>
            ))
          : null}
      </Grid>
    </MDBox>
  );
}

export default OrdersOverview;
