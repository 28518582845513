import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import assinatura from "../../assets/images/logos/assinatura.png";
import config from "../../config/config.json";
// I18N

export default function ReportFooter() {
  const { t } = useTranslation("common");
  const { SYS_VERSION } = config;

  return (
    <Grid container>
      <Grid container>
        <Grid xs={12} item>
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <img width="150" className="assinatura" src={assinatura} alt="Imagem" />
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={12} className="rep-name">
        Francisco de Paula Luca
      </Grid>
      <Grid item xs={12} className="rep-info">
        CREA-AM 13.225D
      </Grid>
      <Grid item xs={12} className="rep-info">
        {t("reports.rep_occupation")}
      </Grid>
      <Grid item xs={12} className="rep-end">
        {t("reports.rep_office")}: Rua Professor Guilherme Nery nº 480 - Parque 10 de novembro - CEP
        69.055-350 - Manaus/AM
      </Grid>
      <Grid item xs={12} mt={3} className="rep-date">
        ReeferSys {SYS_VERSION} {t("reports.rep_date_footer")} 17/10/2022 21:08:54
      </Grid>
    </Grid>
  );
}
