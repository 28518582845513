// Material Dashboard 2 React layouts
import Icon from "@mui/material/Icon";
import SignIn from "./layouts/authentication/sign-in";
import ResetPass from "./layouts/authentication/reset-password/cover";
import ResetNewPass from "./layouts/authentication/reset-password/newpass";
import SystemDash from "./layouts/systemSelector";

// @mui icons
import SystemMonitoring from "./layouts/sys_monitoring";
import SystemAdjusts from "./layouts/sys_adjusts";
import SystemReports from "./layouts/sys_reports";
import SystemMovimentation from "./layouts/sys_movimentation";
import SystemDashboard from "./layouts/sys_dashboard";
import SystemAccounts from "./layouts/sys_accounts";
import SystemMaintenance from "./layouts/sys_maintenance";
import SystemHelp from "./layouts/sys_help";
import SystemQueries from "./layouts/sys_queries";
import Pdf from "./components_reefer/Manuals/HelpPdf";

const routes = [
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "System Selector",
    key: "sysdash",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/sysdash",
    component: <SystemDash />,
  },
  {
    type: "collapse",
    name: "Monitor",
    key: "monitor",
    icon: <Icon fontSize="small">device_thermostat</Icon>,
    route: "/monitor",
    component: <SystemMonitoring />,
  },
  {
    type: "collapse",
    name: "Adjust",
    key: "adjusts",
    icon: <Icon fontSize="small">content_pasteSearch</Icon>,
    route: "/adjusts",
    component: <SystemAdjusts />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="small">newspaper</Icon>,
    route: "/reports",
    component: <SystemReports />,
  },
  {
    type: "collapse",
    name: "Moviments",
    key: "moviments",
    icon: <Icon fontSize="small">compare_arrows</Icon>,
    route: "/moviments",
    component: <SystemMovimentation />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <SystemDashboard />,
  },
  {
    type: "collapse",
    name: "Maintenance",
    key: "maintenance",
    icon: <Icon fontSize="small">handyman</Icon>,
    route: "/maintenance",
    component: <SystemMaintenance />,
  },
  {
    type: "collapse",
    name: "Account",
    key: "account",
    icon: <Icon fontSize="small">badge</Icon>,
    route: "/account",
    component: <SystemAccounts />,
  },
  {
    type: "collapse",
    name: "Reset Password",
    key: "reset-password",
    icon: <Icon fontSize="small">fingerprint</Icon>,
    route: "/authentication/reset-password",
    component: <ResetPass />,
  },
  {
    type: "collapse",
    name: "New Password",
    key: "new-password",
    icon: <Icon fontSize="small">fingerprint</Icon>,
    route: "/authentication/new-password",
    component: <ResetNewPass />,
  },
  {
    type: "collapse",
    name: "Help",
    key: "help",
    icon: <Icon fontSize="small">handyman</Icon>,
    route: "/help",
    component: <SystemHelp />,
  },
  {
    type: "collapse",
    name: "Queries",
    key: "queries",
    icon: <Icon fontSize="small">handyman</Icon>,
    route: "/queries",
    component: <SystemQueries />,
  },
  {
    type: "collapse",
    name: "Manuals",
    key: "manuals",
    icon: <Icon fontSize="small">handyman</Icon>,
    route: "/manuals",
    component: <Pdf />,
  },
];

export default routes;
