/* eslint-disable no-undef */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import * as React from "react";

// Material Dashboard 2 React components
import FormControl from "@mui/material/FormControl";

// @mui material components
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// I18N
import { useTranslation } from "react-i18next";
// import authHeader from "helpers/auth-headers";
import Stack from "@mui/material/Stack";
import { Box, FormControlLabel } from "@mui/material";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";

export default function ModalAccounts(props) {
  // const [patio, setPatio] = React.useState("");
  // const [portoList, setPortoList] = React.useState([]);
  const [name, setName] = React.useState("");
  const [nickname, setNickname] = React.useState("");
  const [cnpj, setCnpj] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [isActive, setIsActive] = React.useState(false);
  const [isSysAdmin, setSysAdmin] = React.useState(false);
  const [isReport, setIsReport] = React.useState(false);
  const [isMaintenance, setIsMaintenance] = React.useState(false);
  const [isAlert, setIsAlert] = React.useState(false);
  const [isHarbor, setIsHarbor] = React.useState(false);
  const [monitoringByAM, setMonitoringByAM] = React.useState(2);
  const [monitoringByPM, setMonitoringByPM] = React.useState(2);
  const [monitoringValue, setMonitoringValue] = React.useState(4.5);
  const [maintenanceValue, setMaintenanceValue] = React.useState(75.0);

  const { t } = useTranslation("common");

  React.useEffect(() => {
    if (props.account) {
      setName(props.account.name);
      setNickname(props.account.nickname);
      setCnpj(props.account.cnpj);
      setAddress(props.account.address);
      setIsActive(props.account.isActive === 1);
      setIsHarbor(props.account.isHarbor === 1);
      setMonitoringByAM(props.account.monitoringByAM);
      setMonitoringByPM(props.account.monitoringByPM);
      setSysAdmin(props.account.isSysAdmin === 1);
      setIsReport(props.account.isReport === 1);
      setIsMaintenance(props.account.isMaintenance === 1);
      setIsAlert(props.account.isAlert === 1);
      setMonitoringValue(props.account.monitoringValue);
      setMaintenanceValue(props.account.maintenanceValue);
    }
  }, []);

  const handleSave = () => {
    const account = {
      id: props.account?.idaccounts ? props.account.idaccounts : null,
      data: {
        name,
        nickname,
        cnpj,
        address,
        isActive,
        isSysAdmin,
        isReport,
        isAlert,
        isMaintenance,
        isHarbor,
        monitoringByAM,
        monitoringByPM,
        monitoringValue,
        maintenanceValue,
      },
    };
    props.onClose(account);
  };

  return (
    <Grid container xs={12} className="modal-transp">
      <Grid item xs={12} sm={9} md={8} lg={5} xl={4}>
        <MDBox className="card-modal" sx={{ bgcolor: "#ffff" }}>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="info"
            mx={3}
            mt={-4}
            p={2}
            mb={3}
            textAlign="center"
            sx={{ width: "90%", bgcolor: "#888" }}
          >
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              <Box>{props.account === null ? t("modal.mod_new_wallet") : props.title}</Box>
            </MDTypography>
          </MDBox>
          <Grid container>
            <Grid item xs={6} sx={{ width: "100%" }}>
              <MDBox sx={{ mx: 1, px: 1 }}>
                <FormControlLabel
                  control={
                    <Switch checked={isActive} onChange={(e) => setIsActive(e.target.checked)} />
                  }
                  label={t("modal.mod_switch_active")}
                />
              </MDBox>
            </Grid>
            <Grid item xs={6} sx={{ width: "100%" }}>
              <MDBox sx={{ mx: 1, px: 1 }}>
                <FormControlLabel
                  control={
                    <Switch checked={isHarbor} onChange={(e) => setIsHarbor(e.target.checked)} />
                  }
                  label={t("modal.mod_switch_harbor")}
                />
              </MDBox>
            </Grid>
            <Grid item xs={6} sx={{ width: "100%" }}>
              <MDBox sx={{ mx: 1, px: 1 }}>
                <FormControlLabel
                  control={
                    <Switch checked={isSysAdmin} onChange={(e) => setSysAdmin(e.target.checked)} />
                  }
                  label={t("modal.mod_switch_sysadm")}
                />
              </MDBox>
            </Grid>
            <Grid item xs={6} sx={{ width: "100%" }}>
              <MDBox sx={{ mx: 1, px: 1 }}>
                <FormControlLabel
                  control={
                    <Switch checked={isReport} onChange={(e) => setIsReport(e.target.checked)} />
                  }
                  label={t("modal.mod_switch_report")}
                />
              </MDBox>
            </Grid>
            <Grid item xs={6} sx={{ width: "100%" }}>
              <MDBox sx={{ mx: 1, px: 1 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isMaintenance}
                      onChange={(e) => setIsMaintenance(e.target.checked)}
                    />
                  }
                  label={t("modal.mod_switch_maintenance")}
                />
              </MDBox>
            </Grid>
            <Grid item xs={6} sx={{ width: "100%" }}>
              <MDBox sx={{ mx: 1, px: 1 }}>
                <FormControlLabel
                  control={
                    <Switch checked={isAlert} onChange={(e) => setIsAlert(e.target.checked)} />
                  }
                  label={t("modal.mod_switch_alert")}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <MDInput
                  id="outlined-basic"
                  // onChange={handleChangePatio}
                  label={t("modal.mod_input_name")}
                  variant="standard"
                  fullWidth
                  style={{ height: "2rem" }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <MDInput
                  id="outlined-basic"
                  // onChange={handleChangePatio}
                  label={t("modal.mod_input_nickname")}
                  variant="standard"
                  fullWidth
                  style={{ height: "2rem" }}
                  value={nickname}
                  onChange={(e) => setNickname(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <MDInput
                  id="outlined-basic"
                  // onChange={handleChangePatio}
                  label={t("modal.mod_input_cnpj")}
                  variant="standard"
                  fullWidth
                  style={{ height: "2rem" }}
                  value={cnpj}
                  onChange={(e) => setCnpj(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <MDInput
                  id="outlined-basic"
                  // onChange={handleChangePatio}
                  label={t("modal.monitoring_by_day")}
                  variant="standard"
                  fullWidth
                  type="number"
                  style={{ height: "2rem" }}
                  value={monitoringValue}
                  onChange={(e) => setMonitoringValue(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <MDInput
                  id="outlined-basic"
                  // onChange={handleChangePatio}
                  label={t("modal.monitoring_by_day_AM")}
                  variant="standard"
                  fullWidth
                  type="number"
                  style={{ height: "2rem" }}
                  value={monitoringByAM}
                  onChange={(e) => setMonitoringByAM(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <MDInput
                  id="outlined-basic"
                  // onChange={handleChangePatio}
                  label={t("modal.monitoring_by_day_PM")}
                  variant="standard"
                  fullWidth
                  type="number"
                  style={{ height: "2rem" }}
                  value={monitoringByPM}
                  onChange={(e) => setMonitoringByPM(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <MDInput
                  id="outlined-basic"
                  // onChange={handleChangePatio}
                  label={t("modal.mod_input_maintenance_value")}
                  variant="standard"
                  fullWidth
                  style={{ height: "2rem" }}
                  value={maintenanceValue}
                  onChange={(e) => setMaintenanceValue(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ width: "100%" }}>
              <FormControl variant="standard" sx={{ m: 1, p: 1, width: "95%" }}>
                <MDInput
                  id="outlined-basic"
                  // onChange={handleChangePatio}
                  label={t("modal.mod_input_address")}
                  variant="standard"
                  fullWidth
                  style={{ height: "2rem" }}
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid container style={{ justifyContent: "right", alignItems: "center" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={6} xxl={6} p={1}>
                <Stack direction="row">
                  <FormControl
                    sx={{ p: 1, width: "100%" }}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <MDButton
                      onClick={() => {
                        props.onClose(null);
                      }}
                      sx={{ width: "100%" }}
                      variant="gradient"
                      color="info"
                      style={{ height: "3rem" }}
                    >
                      {t("modal.mod_cancel")}
                    </MDButton>
                  </FormControl>
                  <FormControl
                    sx={{ p: 1, width: "100%" }}
                    style={{ justifyContent: "center", alignItems: "center" }}
                  >
                    <MDButton
                      onClick={handleSave}
                      sx={{ width: "100%" }}
                      variant="gradient"
                      color="info"
                      style={{ height: "3rem" }}
                    >
                      {t("modal.mod_save")}
                    </MDButton>
                  </FormControl>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Grid>
  );
}
