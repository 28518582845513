export default function authHeader() {
  const userStr = localStorage.getItem("user");
  let user = null;
  if (userStr) user = JSON.parse(userStr);
  if (user && user.token) {
    const header = {
      "Content-type": "application/json",
      Authorization: `Bearer ${user.token}`,
    };
    return header;
  }
  return { "x-access-token": null }; // for Node Express back-end
}
